import {
  defineEnterSystem,
  defineSystem,
  defineUpdateSystem,
  getComponentValueStrict,
  Has,
  HasValue,
  runQuery,
  setComponent,
  UpdateType,
} from "@latticexyz/recs";
import { PhaserLayer } from "../../types";
import { HueTintAndOutlineFXPipeline } from "@latticexyz/phaserx";

/**
 * The HueTint system handles setting a "hueTint" pipeline data on game objects having a hue tint
 */
export function createHueTintSystem(layer: PhaserLayer) {
  const {
    world,
    components: { HueTint },
    scenes: {
      Main: { objectPool },
    },
    parentLayers: {
      network: {
        components: { OwnedBy, Player, Name, Capturable },
      },
      local: {
        components: { LocalPosition },
        api: { getOwnerColor },
      },
      headless: {
        components: { InCurrentMatch },
      },
    },
  } = layer;

  defineSystem(world, [Has(Player), Has(Name)], ({ entity, type }) => {
    if (type === UpdateType.Exit) return;

    const ownedEntities = runQuery([HasValue(OwnedBy, { value: entity })]);

    const color = getOwnerColor(entity);
    for (const e of ownedEntities) {
      setComponent(HueTint, e, { value: color });
    }
  });

  defineEnterSystem(world, [Has(OwnedBy), Has(InCurrentMatch)], ({ entity }) => {
    const color = getOwnerColor(entity);
    if (!color) return;

    setComponent(HueTint, entity, { value: color });

    // HUGE HACK TO GET SPAWNS PAINTED COME BACK TO THIS
    setTimeout(() => {
      const color = getOwnerColor(entity);
      if (!color) return;

      setComponent(HueTint, entity, { value: color });
    }, 1_000);
  });

  defineUpdateSystem(world, [Has(OwnedBy), Has(Capturable)], ({ entity }) => {
    const color = getOwnerColor(entity);
    if (!color) return;

    setComponent(HueTint, entity, { value: color });
  });

  defineSystem(world, [Has(HueTint), Has(LocalPosition)], ({ entity, type }) => {
    if (type === UpdateType.Exit) {
      return;
    }

    const embodiedEntity = objectPool.get(entity, "Sprite");

    const hueTint = getComponentValueStrict(HueTint, entity).value;
    embodiedEntity.setComponent({
      id: HueTint.id,
      once: (gameObject) => {
        gameObject.setPipeline(HueTintAndOutlineFXPipeline.KEY);
        gameObject.setPipelineData("hueTint", hueTint);
      },
    });
  });
}
