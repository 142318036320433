/* Autogenerated file. Do not edit manually. */

import {
  defineComponent,
  Type as RecsType,
  type World,
} from "@latticexyz/recs";

export function defineContractComponents(world: World) {
  return {
    Admin: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.Boolean,
        },
        {
          id: "0x0000000000000000000000000000000041646d696e0000000000000000000000",
          metadata: {
            componentName: "Admin",
            tableName: ":Admin",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "bool" },
          },
        } as const
      );
    })(),
    ArmorModifier: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.Number,
        },
        {
          id: "0x0000000000000000000000000000000041726d6f724d6f646966696572000000",
          metadata: {
            componentName: "ArmorModifier",
            tableName: ":ArmorModifier",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "int32" },
          },
        } as const
      );
    })(),
    Recipient: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.String,
        },
        {
          id: "0x00000000000000000000000000000000526563697069656e7400000000000000",
          metadata: {
            componentName: "Recipient",
            tableName: ":Recipient",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "bytes32" },
          },
        } as const
      );
    })(),
    Capturable: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.Boolean,
        },
        {
          id: "0x0000000000000000000000000000000043617074757261626c65000000000000",
          metadata: {
            componentName: "Capturable",
            tableName: ":Capturable",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "bool" },
          },
        } as const
      );
    })(),
    Capturer: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.Boolean,
        },
        {
          id: "0x0000000000000000000000000000000043617074757265720000000000000000",
          metadata: {
            componentName: "Capturer",
            tableName: ":Capturer",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "bool" },
          },
        } as const
      );
    })(),
    ChargedByStart: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.BigInt,
        },
        {
          id: "0x0000000000000000000000000000000043686172676564427953746172740000",
          metadata: {
            componentName: "ChargedByStart",
            tableName: ":ChargedByStart",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "uint256" },
          },
        } as const
      );
    })(),
    Chargee: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.String,
        },
        {
          id: "0x0000000000000000000000000000000043686172676565000000000000000000",
          metadata: {
            componentName: "Chargee",
            tableName: ":Chargee",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "bytes32" },
          },
        } as const
      );
    })(),
    Charger: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.Number,
        },
        {
          id: "0x0000000000000000000000000000000043686172676572000000000000000000",
          metadata: {
            componentName: "Charger",
            tableName: ":Charger",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "int32" },
          },
        } as const
      );
    })(),
    ChargeCap: (() => {
      return defineComponent(
        world,
        {
          cap: RecsType.Number,
          totalCharged: RecsType.Number,
        },
        {
          id: "0x0000000000000000000000000000000043686172676543617000000000000000",
          metadata: {
            componentName: "ChargeCap",
            tableName: ":ChargeCap",
            keySchema: { key: "bytes32" },
            valueSchema: { cap: "int32", totalCharged: "int32" },
          },
        } as const
      );
    })(),
    Combat: (() => {
      return defineComponent(
        world,
        {
          health: RecsType.Number,
          maxHealth: RecsType.Number,
          armor: RecsType.Number,
          strength: RecsType.Number,
          structureStrength: RecsType.Number,
          counterStrength: RecsType.Number,
        },
        {
          id: "0x00000000000000000000000000000000436f6d62617400000000000000000000",
          metadata: {
            componentName: "Combat",
            tableName: ":Combat",
            keySchema: { key: "bytes32" },
            valueSchema: {
              health: "int32",
              maxHealth: "int32",
              armor: "int32",
              strength: "int32",
              structureStrength: "int32",
              counterStrength: "int32",
            },
          },
        } as const
      );
    })(),
    StaminaOnKill: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.Number,
        },
        {
          id: "0x000000000000000000000000000000005374616d696e614f6e4b696c6c000000",
          metadata: {
            componentName: "StaminaOnKill",
            tableName: ":StaminaOnKill",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "int32" },
          },
        } as const
      );
    })(),
    CombatResult: (() => {
      return defineComponent(
        world,
        {
          attacker: RecsType.String,
          defender: RecsType.String,
          attackerDamageReceived: RecsType.Number,
          defenderDamageReceived: RecsType.Number,
          attackerHealth: RecsType.Number,
          defenderHealth: RecsType.Number,
          attackerDamage: RecsType.Number,
          defenderDamage: RecsType.Number,
          ranged: RecsType.Boolean,
          attackerDied: RecsType.Boolean,
          defenderDied: RecsType.Boolean,
          defenderCaptured: RecsType.Boolean,
        },
        {
          id: "0x00000000000000000000000000000000436f6d626174526573756c7400000000",
          metadata: {
            componentName: "CombatResult",
            tableName: ":CombatResult",
            keySchema: { key: "bytes32" },
            valueSchema: {
              attacker: "bytes32",
              defender: "bytes32",
              attackerDamageReceived: "int32",
              defenderDamageReceived: "int32",
              attackerHealth: "int32",
              defenderHealth: "int32",
              attackerDamage: "int32",
              defenderDamage: "int32",
              ranged: "bool",
              attackerDied: "bool",
              defenderDied: "bool",
              defenderCaptured: "bool",
            },
          },
        } as const
      );
    })(),
    CombatCost: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.Number,
        },
        {
          id: "0x00000000000000000000000000000000436f6d626174436f7374000000000000",
          metadata: {
            componentName: "CombatCost",
            tableName: ":CombatCost",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "int32" },
          },
        } as const
      );
    })(),
    CombatStrength: (() => {
      return defineComponent(
        world,
        {
          combatTypeStrengthBonuses: RecsType.NumberArray,
        },
        {
          id: "0x00000000000000000000000000000000436f6d626174537472656e6774680000",
          metadata: {
            componentName: "CombatStrength",
            tableName: ":CombatStrength",
            keySchema: { key: "bytes32" },
            valueSchema: { combatTypeStrengthBonuses: "int32[]" },
          },
        } as const
      );
    })(),
    StrengthMod: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.Number,
        },
        {
          id: "0x00000000000000000000000000000000537472656e6774684d6f640000000000",
          metadata: {
            componentName: "StrengthMod",
            tableName: ":StrengthMod",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "int32" },
          },
        } as const
      );
    })(),
    EscapePortal: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.Boolean,
        },
        {
          id: "0x00000000000000000000000000000000457363617065506f7274616c00000000",
          metadata: {
            componentName: "EscapePortal",
            tableName: ":EscapePortal",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "bool" },
          },
        } as const
      );
    })(),
    Factory: (() => {
      return defineComponent(
        world,
        {
          prototypeIds: RecsType.StringArray,
          staminaCosts: RecsType.NumberArray,
        },
        {
          id: "0x00000000000000000000000000000000466163746f7279000000000000000000",
          metadata: {
            componentName: "Factory",
            tableName: ":Factory",
            keySchema: { key: "bytes32" },
            valueSchema: { prototypeIds: "bytes32[]", staminaCosts: "int32[]" },
          },
        } as const
      );
    })(),
    Inventory: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.Number,
        },
        {
          id: "0x00000000000000000000000000000000496e76656e746f727900000000000000",
          metadata: {
            componentName: "Inventory",
            tableName: ":Inventory",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "uint32" },
          },
        } as const
      );
    })(),
    ItemType: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.Number,
        },
        {
          id: "0x000000000000000000000000000000004974656d547970650000000000000000",
          metadata: {
            componentName: "ItemType",
            tableName: ":ItemType",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "uint8" },
          },
        } as const
      );
    })(),
    KillCount: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.Number,
        },
        {
          id: "0x000000000000000000000000000000004b696c6c436f756e7400000000000000",
          metadata: {
            componentName: "KillCount",
            tableName: ":KillCount",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "uint32" },
          },
        } as const
      );
    })(),
    LastAction: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.BigInt,
        },
        {
          id: "0x000000000000000000000000000000004c617374416374696f6e000000000000",
          metadata: {
            componentName: "LastAction",
            tableName: ":LastAction",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "uint256" },
          },
        } as const
      );
    })(),
    Match: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.Number,
        },
        {
          id: "0x000000000000000000000000000000004d617463680000000000000000000000",
          metadata: {
            componentName: "Match",
            tableName: ":Match",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "uint32" },
          },
        } as const
      );
    })(),
    MatchSpawnPoints: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.StringArray,
        },
        {
          id: "0x000000000000000000000000000000004d61746368537061776e506f696e7473",
          metadata: {
            componentName: "MatchSpawnPoints",
            tableName: ":MatchSpawnPoints",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "bytes32[]" },
          },
        } as const
      );
    })(),
    MatchSky: (() => {
      return defineComponent(
        world,
        {
          blockNumber: RecsType.BigInt,
          reward: RecsType.BigInt,
        },
        {
          id: "0x000000000000000000000000000000004d61746368536b790000000000000000",
          metadata: {
            componentName: "MatchSky",
            tableName: ":MatchSky",
            keySchema: { key: "bytes32" },
            valueSchema: { blockNumber: "uint256", reward: "uint256" },
          },
        } as const
      );
    })(),
    SkyPoolConfig: (() => {
      return defineComponent(
        world,
        {
          cost: RecsType.BigInt,
          window: RecsType.BigInt,
          entity: RecsType.String,
          token: RecsType.String,
        },
        {
          id: "0x00000000000000000000000000000000536b79506f6f6c436f6e666967000000",
          metadata: {
            componentName: "SkyPoolConfig",
            tableName: ":SkyPoolConfig",
            keySchema: {},
            valueSchema: {
              cost: "uint256",
              window: "uint256",
              entity: "bytes32",
              token: "bytes32",
            },
          },
        } as const
      );
    })(),
    MatchConfig: (() => {
      return defineComponent(
        world,
        {
          startTime: RecsType.BigInt,
          turnLength: RecsType.BigInt,
          actionCooldownLength: RecsType.BigInt,
          levelId: RecsType.String,
          createdBy: RecsType.String,
        },
        {
          id: "0x000000000000000000000000000000004d61746368436f6e6669670000000000",
          metadata: {
            componentName: "MatchConfig",
            tableName: ":MatchConfig",
            keySchema: { key: "bytes32" },
            valueSchema: {
              startTime: "uint256",
              turnLength: "uint256",
              actionCooldownLength: "uint256",
              levelId: "bytes32",
              createdBy: "bytes32",
            },
          },
        } as const
      );
    })(),
    MatchFinished: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.Boolean,
        },
        {
          id: "0x000000000000000000000000000000004d6174636846696e6973686564000000",
          metadata: {
            componentName: "MatchFinished",
            tableName: ":MatchFinished",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "bool" },
          },
        } as const
      );
    })(),
    MatchMapCopyProgress: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.BigInt,
        },
        {
          id: "0x000000000000000000000000000000004d617463684d6170436f707950726f67",
          metadata: {
            componentName: "MatchMapCopyProg",
            tableName: ":MatchMapCopyProg",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "uint256" },
          },
        } as const
      );
    })(),
    MatchReady: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.BigInt,
        },
        {
          id: "0x000000000000000000000000000000004d617463685265616479000000000000",
          metadata: {
            componentName: "MatchReady",
            tableName: ":MatchReady",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "uint256" },
          },
        } as const
      );
    })(),
    MatchRanking: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.StringArray,
        },
        {
          id: "0x000000000000000000000000000000004d6174636852616e6b696e6700000000",
          metadata: {
            componentName: "MatchRanking",
            tableName: ":MatchRanking",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "bytes32[]" },
          },
        } as const
      );
    })(),
    MatchReward: (() => {
      return defineComponent(
        world,
        {
          token: RecsType.String,
          value: RecsType.BigInt,
        },
        {
          id: "0x000000000000000000000000000000004d617463685265776172640000000000",
          metadata: {
            componentName: "MatchReward",
            tableName: ":MatchReward",
            keySchema: { entity: "bytes32", rank: "uint256" },
            valueSchema: { token: "bytes32", value: "uint256" },
          },
        } as const
      );
    })(),
    LatestMatch: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.Number,
        },
        {
          id: "0x000000000000000000000000000000004c61746573744d617463680000000000",
          metadata: {
            componentName: "LatestMatch",
            tableName: ":LatestMatch",
            keySchema: {},
            valueSchema: { value: "uint32" },
          },
        } as const
      );
    })(),
    Movable: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.Number,
        },
        {
          id: "0x000000000000000000000000000000004d6f7661626c65000000000000000000",
          metadata: {
            componentName: "Movable",
            tableName: ":Movable",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "int32" },
          },
        } as const
      );
    })(),
    MoveDifficulty: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.Number,
        },
        {
          id: "0x000000000000000000000000000000004d6f7665446966666963756c74790000",
          metadata: {
            componentName: "MoveDifficulty",
            tableName: ":MoveDifficulty",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "int32" },
          },
        } as const
      );
    })(),
    Name: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.String,
        },
        {
          id: "0x000000000000000000000000000000004e616d65000000000000000000000000",
          metadata: {
            componentName: "Name",
            tableName: ":Name",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "string" },
          },
        } as const
      );
    })(),
    OwnedBy: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.String,
        },
        {
          id: "0x000000000000000000000000000000004f776e65644279000000000000000000",
          metadata: {
            componentName: "OwnedBy",
            tableName: ":OwnedBy",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "bytes32" },
          },
        } as const
      );
    })(),
    Player: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.Number,
        },
        {
          id: "0x00000000000000000000000000000000506c6179657200000000000000000000",
          metadata: {
            componentName: "Player",
            tableName: ":Player",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "uint32" },
          },
        } as const
      );
    })(),
    PlayerReady: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.BigInt,
        },
        {
          id: "0x00000000000000000000000000000000506c6179657252656164790000000000",
          metadata: {
            componentName: "PlayerReady",
            tableName: ":PlayerReady",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "uint256" },
          },
        } as const
      );
    })(),
    Position: (() => {
      return defineComponent(
        world,
        {
          x: RecsType.Number,
          y: RecsType.Number,
          z: RecsType.Number,
        },
        {
          id: "0x00000000000000000000000000000000506f736974696f6e0000000000000000",
          metadata: {
            componentName: "Position",
            tableName: ":Position",
            keySchema: { key: "bytes32" },
            valueSchema: { x: "int32", y: "int32", z: "int32" },
          },
        } as const
      );
    })(),
    Prototype: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.StringArray,
        },
        {
          id: "0x0000000000000000000000000000000050726f746f7479706500000000000000",
          metadata: {
            componentName: "Prototype",
            tableName: ":Prototype",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "bytes32[]" },
          },
        } as const
      );
    })(),
    PrototypeContent: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.String,
        },
        {
          id: "0x0000000000000000000000000000000050726f746f74797065436f6e74656e74",
          metadata: {
            componentName: "PrototypeContent",
            tableName: ":PrototypeContent",
            keySchema: { templateId: "bytes32", tableId: "bytes32" },
            valueSchema: { value: "bytes" },
          },
        } as const
      );
    })(),
    MapSize: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.BigInt,
        },
        {
          id: "0x000000000000000000000000000000004d617053697a65000000000000000000",
          metadata: {
            componentName: "MapSize",
            tableName: ":MapSize",
            keySchema: { levelId: "bytes32" },
            valueSchema: { value: "uint256" },
          },
        } as const
      );
    })(),
    MapTemplates: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.String,
        },
        {
          id: "0x000000000000000000000000000000004d617054656d706c6174657300000000",
          metadata: {
            componentName: "MapTemplates",
            tableName: ":MapTemplates",
            keySchema: { levelId: "bytes32", indexId: "bytes32" },
            valueSchema: { value: "bytes32" },
          },
        } as const
      );
    })(),
    Map: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.StringArray,
        },
        {
          id: "0x000000000000000000000000000000004d617000000000000000000000000000",
          metadata: {
            componentName: "Map",
            tableName: ":Map",
            keySchema: { levelId: "bytes32", indexId: "bytes32" },
            valueSchema: { value: "bytes32[]" },
          },
        } as const
      );
    })(),
    MapContent: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.String,
        },
        {
          id: "0x000000000000000000000000000000004d6170436f6e74656e74000000000000",
          metadata: {
            componentName: "MapContent",
            tableName: ":MapContent",
            keySchema: {
              levelId: "bytes32",
              indexId: "bytes32",
              tableId: "bytes32",
            },
            valueSchema: { value: "bytes" },
          },
        } as const
      );
    })(),
    Range: (() => {
      return defineComponent(
        world,
        {
          min: RecsType.Number,
          max: RecsType.Number,
        },
        {
          id: "0x0000000000000000000000000000000052616e67650000000000000000000000",
          metadata: {
            componentName: "Range",
            tableName: ":Range",
            keySchema: { key: "bytes32" },
            valueSchema: { min: "int32", max: "int32" },
          },
        } as const
      );
    })(),
    SpawnReservedBy: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.String,
        },
        {
          id: "0x00000000000000000000000000000000537061776e5265736572766564427900",
          metadata: {
            componentName: "SpawnReservedBy",
            tableName: ":SpawnReservedBy",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "bytes32" },
          },
        } as const
      );
    })(),
    ResourceSpawn: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.String,
        },
        {
          id: "0x000000000000000000000000000000005265736f75726365537061776e000000",
          metadata: {
            componentName: "ResourceSpawn",
            tableName: ":ResourceSpawn",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "bytes32" },
          },
        } as const
      );
    })(),
    Spawned: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.Boolean,
        },
        {
          id: "0x00000000000000000000000000000000537061776e6564000000000000000000",
          metadata: {
            componentName: "Spawned",
            tableName: ":Spawned",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "bool" },
          },
        } as const
      );
    })(),
    SpawnPoint: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.Boolean,
        },
        {
          id: "0x00000000000000000000000000000000537061776e506f696e74000000000000",
          metadata: {
            componentName: "SpawnPoint",
            tableName: ":SpawnPoint",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "bool" },
          },
        } as const
      );
    })(),
    Stamina: (() => {
      return defineComponent(
        world,
        {
          current: RecsType.Number,
          max: RecsType.Number,
          regeneration: RecsType.Number,
        },
        {
          id: "0x000000000000000000000000000000005374616d696e61000000000000000000",
          metadata: {
            componentName: "Stamina",
            tableName: ":Stamina",
            keySchema: { key: "bytes32" },
            valueSchema: {
              current: "int32",
              max: "int32",
              regeneration: "int32",
            },
          },
        } as const
      );
    })(),
    StamRegenCap: (() => {
      return defineComponent(
        world,
        {
          totalRegenerated: RecsType.Number,
          cap: RecsType.Number,
        },
        {
          id: "0x000000000000000000000000000000005374616d526567656e43617000000000",
          metadata: {
            componentName: "StamRegenCap",
            tableName: ":StamRegenCap",
            keySchema: { key: "bytes32" },
            valueSchema: { totalRegenerated: "int32", cap: "int32" },
          },
        } as const
      );
    })(),
    StructureType: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.Number,
        },
        {
          id: "0x0000000000000000000000000000000053747275637475726554797065000000",
          metadata: {
            componentName: "StructureType",
            tableName: ":StructureType",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "uint8" },
          },
        } as const
      );
    })(),
    TerrainType: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.Number,
        },
        {
          id: "0x000000000000000000000000000000005465727261696e547970650000000000",
          metadata: {
            componentName: "TerrainType",
            tableName: ":TerrainType",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "uint8" },
          },
        } as const
      );
    })(),
    UnitType: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.Number,
        },
        {
          id: "0x00000000000000000000000000000000556e6974547970650000000000000000",
          metadata: {
            componentName: "UnitType",
            tableName: ":UnitType",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "uint8" },
          },
        } as const
      );
    })(),
    TokenMetadata: (() => {
      return defineComponent(
        world,
        {
          name: RecsType.String,
          emoji: RecsType.String,
        },
        {
          id: "0x00000000000000000000000000000000546f6b656e4d65746164617461000000",
          metadata: {
            componentName: "TokenMetadata",
            tableName: ":TokenMetadata",
            keySchema: { key: "bytes32" },
            valueSchema: { name: "string", emoji: "string" },
          },
        } as const
      );
    })(),
    TokenBalance: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.BigInt,
        },
        {
          id: "0x00000000000000000000000000000000546f6b656e42616c616e636500000000",
          metadata: {
            componentName: "TokenBalance",
            tableName: ":TokenBalance",
            keySchema: { token: "bytes32", entity: "bytes32" },
            valueSchema: { value: "uint256" },
          },
        } as const
      );
    })(),
    Untraversable: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.Boolean,
        },
        {
          id: "0x00000000000000000000000000000000556e7472617665727361626c65000000",
          metadata: {
            componentName: "Untraversable",
            tableName: ":Untraversable",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "bool" },
          },
        } as const
      );
    })(),
    Tier: (() => {
      return defineComponent(
        world,
        {
          value: RecsType.Number,
        },
        {
          id: "0x0000000000000000000000000000000054696572000000000000000000000000",
          metadata: {
            componentName: "Tier",
            tableName: ":Tier",
            keySchema: { key: "bytes32" },
            valueSchema: { value: "uint32" },
          },
        } as const
      );
    })(),
  };
}
