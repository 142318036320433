import { tileCoordToPixelCoord } from "@latticexyz/phaserx";
import {
  defineSystem,
  Entity,
  getComponentValue,
  getComponentValueStrict,
  Has,
  HasValue,
  isComponentUpdate,
  NotValue,
  runQuery,
  UpdateType,
} from "@latticexyz/recs";
import { Coord } from "@latticexyz/utils";
import { compact, curry, zip } from "lodash";
import { Subscription } from "rxjs";
import { aStar } from "../../../../../utils/pathfinding";
import { PhaserLayer, RenderDepth } from "../../types";

const YELLOW = 0xfff973;

export function createDrawPotentialPathSystem(layer: PhaserLayer) {
  const {
    world,
    api: { drawTileHighlight },
    components: { HoverHighlight },
    parentLayers: {
      network: {
        components: { OwnedBy, Combat },
        utils: { isOwnedByCurrentPlayer, getOwningPlayer },
      },
      local: {
        components: { PotentialPath, LocalPosition },
      },
      headless: {
        api: { getMovementDifficulty, isUntraversable },
        components: { NextPosition },
      },
    },
    scenes: {
      Main: {
        objectPool,
        phaserScene,
        maps: {
          Main: { tileHeight, tileWidth },
        },
      },
    },
  } = layer;

  defineSystem(world, [Has(PotentialPath), Has(LocalPosition)], (update) => {
    if (isComponentUpdate(update, LocalPosition) && update.type === UpdateType.Exit) {
      const paths = getComponentValueStrict(PotentialPath, update.entity);
      for (let i = 0; i < paths.x.length; i++) {
        objectPool.remove(`${update.entity}-path-highlight-${i}`);
      }

      return;
    }

    if (!isComponentUpdate(update, PotentialPath)) return;
    const { value, entity } = update;

    const [potentialPaths, previousPaths] = value;

    if (previousPaths) {
      for (let i = 0; i < previousPaths.x.length; i++) {
        objectPool.remove(`${entity}-path-highlight-${i}`);
      }
    }

    if (potentialPaths) {
      // add current entity position to paths just to look good
      const position = getComponentValue(LocalPosition, entity);
      if (!position) return;
      potentialPaths.x.push(position.x);
      potentialPaths.y.push(position.y);

      for (let i = 0; i < potentialPaths.x.length; i++) {
        const position = { x: potentialPaths.x[i], y: potentialPaths.y[i] };
        drawTileHighlight(`${entity}-path-highlight-${i}`, position, YELLOW);
      }
    }
  });

  const draw = (from: Coord, to: Coord, player: Entity, color: number, lines: Phaser.GameObjects.Group | undefined) => {
    const unitPath = aStar(
      from,
      to,
      100_000,
      curry(getMovementDifficulty)(LocalPosition),
      curry(isUntraversable)(LocalPosition, player)
    );

    unitPath.unshift(from);

    // paint lines between this point and the next
    for (let i = 0; i < unitPath.length - 1; i++) {
      const coord = tileCoordToPixelCoord(unitPath[i], tileWidth, tileHeight);
      const nextCoord = tileCoordToPixelCoord(unitPath[i + 1], tileWidth, tileHeight);

      const line = phaserScene.add.line();
      line.setOrigin(0, 0);
      line.setPosition(coord.x, coord.y);
      line.setTo(
        tileWidth / 2,
        tileHeight / 2,
        nextCoord.x - coord.x + tileWidth / 2,
        nextCoord.y - coord.y + tileHeight / 2
      );
      line.setStrokeStyle(4, color);
      line.setDepth(RenderDepth.Background1);
      lines?.add(line, false);
    }
  };

  const entityToPathObjects: Record<
    Entity,
    {
      lines: Phaser.GameObjects.Group | undefined;
      linesNext: Phaser.GameObjects.Group | undefined;
      linesNextBlinkTween: Phaser.Tweens.Tween | undefined;
      pathLineDrawSub: Subscription | undefined;
      pathLineDrawSubNext: Subscription | undefined;
    }
  > = {};

  function initializePathObjects(entity: Entity) {
    if (!entityToPathObjects[entity]) {
      entityToPathObjects[entity] = {
        lines: phaserScene.add.group(),
        linesNext: phaserScene.add.group(),
        linesNextBlinkTween: undefined,
        pathLineDrawSub: undefined,
        pathLineDrawSubNext: undefined,
      };
    }
  }

  defineSystem(world, [Has(PotentialPath), Has(LocalPosition)], ({ entity, type }) => {
    initializePathObjects(entity);
    const pathObjects = entityToPathObjects[entity];

    pathObjects.pathLineDrawSub?.unsubscribe();
    entityToPathObjects[entity].pathLineDrawSub = undefined;
    pathObjects.lines?.clear(true);

    if (!isOwnedByCurrentPlayer(entity)) return;
    if (type === UpdateType.Exit) return;

    const owningPlayer = getOwningPlayer(entity);
    if (!owningPlayer) return;

    const potentialPath = getComponentValue(PotentialPath, entity);
    if (!potentialPath) return;

    entityToPathObjects[entity].lines = phaserScene.add.group();
    entityToPathObjects[entity].pathLineDrawSub = HoverHighlight.update$.subscribe((update) => {
      entityToPathObjects[entity].lines?.clear(true);

      const unitPosition = getComponentValueStrict(LocalPosition, entity);
      const hoverHighlight = getComponentValue(HoverHighlight, update.entity);

      if (!hoverHighlight || hoverHighlight.x === undefined || hoverHighlight.y === undefined) return;

      const hoverCoord = { x: hoverHighlight.x, y: hoverHighlight.y };

      const potentialPathCoords = compact(
        zip(potentialPath.x, potentialPath.y).map(([x, y]) => {
          if (x === undefined || y === undefined) return null;
          return { x, y };
        })
      );
      const hoveringOverPotentialPath = potentialPathCoords.find(
        ({ x, y }) => x === hoverHighlight.x && y === hoverHighlight.y
      );
      const hoveredAttackableEntities = [
        ...runQuery([HasValue(LocalPosition, hoverCoord), Has(Combat), NotValue(OwnedBy, { value: owningPlayer })]),
      ];

      if (hoveringOverPotentialPath && hoveredAttackableEntities.length === 0) {
        draw(unitPosition, hoverCoord, owningPlayer, YELLOW, pathObjects.lines);
      }
    });
  });

  defineSystem(world, [Has(LocalPosition), Has(NextPosition)], ({ entity, type }) => {
    initializePathObjects(entity);
    const pathObjects = entityToPathObjects[entity];

    pathObjects.pathLineDrawSubNext?.unsubscribe();
    entityToPathObjects[entity].pathLineDrawSubNext = undefined;
    pathObjects.linesNext?.clear(true);
    pathObjects.linesNextBlinkTween?.destroy();

    const owningPlayer = getOwningPlayer(entity);
    if (!owningPlayer) return;

    if (type === UpdateType.Exit) return;

    entityToPathObjects[entity].linesNext = phaserScene.add.group();

    const unitPosition = getComponentValueStrict(LocalPosition, entity);
    const nextPosition = getComponentValueStrict(NextPosition, entity);

    draw(unitPosition, nextPosition, owningPlayer, YELLOW, pathObjects.linesNext);

    entityToPathObjects[entity].linesNextBlinkTween = phaserScene.add.tween({
      targets: pathObjects.linesNext?.getChildren(),
      alpha: 0.2,
      duration: 300,
      yoyo: true,
      repeat: -1,
    });
  });
}
