import {
  defineSystem,
  Entity,
  getComponentValue,
  Has,
  Not,
  removeComponent,
  setComponent,
  UpdateType,
} from "@latticexyz/recs";
import { LocalLayer } from "../../types";

export function createAttackableEntitiesSystem(layer: LocalLayer) {
  const {
    world,
    components: { Selected, AttackableEntities, LocalPosition },
    parentLayers: {
      headless: {
        components: { LocalStamina, OnCooldown, NextPosition },
        actions: { withOptimisticUpdates },
        api: {
          getAttackableEntities,
          getActionStaminaCost,
          combat: { isPassive },
        },
      },
      network: {
        components: { Stamina, Combat, Range },
        utils: { getOwningPlayer },
      },
    },
  } = layer;

  const OptimisticStamina = withOptimisticUpdates(Stamina);

  const create = (type: UpdateType, entity: Entity) => {
    if (type === UpdateType.Exit) {
      removeComponent(AttackableEntities, entity);
      return;
    }

    const localStamina = getComponentValue(LocalStamina, entity);
    if (!localStamina) return;

    const stamina = getComponentValue(OptimisticStamina, entity);
    if (!stamina) return;

    const combat = getComponentValue(Combat, entity);
    if (!combat) return;
    const range = getComponentValue(Range, entity);
    if (!range && isPassive(layer.parentLayers.network, entity)) return;

    const currentStamina = localStamina.current + stamina.current;

    if (currentStamina < getActionStaminaCost(entity)) {
      removeComponent(AttackableEntities, entity);
    } else if ([UpdateType.Enter, UpdateType.Update].includes(type)) {
      const localPosition = getComponentValue(LocalPosition, entity);
      if (!localPosition) return;

      const playerEntity = getOwningPlayer(entity);
      if (playerEntity == null) return;

      const attackableEntities = getAttackableEntities(entity);
      if (!attackableEntities) return;

      setComponent(AttackableEntities, entity, { value: attackableEntities });
    }
  };

  defineSystem(
    world,
    [Has(Selected), Has(LocalPosition), Has(LocalStamina), Has(OptimisticStamina), Has(Combat), Not(OnCooldown)],
    ({ type, entity }) => create(type, entity)
  );

  defineSystem(
    world,
    [Has(Selected), Has(NextPosition), Has(LocalStamina), Has(OptimisticStamina), Has(Combat), Not(OnCooldown)],
    ({ type, entity }) => create(type, entity)
  );
}
