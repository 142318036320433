import { useEntityQuery } from "@latticexyz/react";
import { Has, HasValue } from "@latticexyz/recs";
import { compact } from "lodash";
import { useMUD } from "../../../store";

export const useAllPlayerDetails = () => {
  const {
    networkLayer: {
      components: { Player, Name, Spawned, Match },
      network: { match },
    },
    localLayer: {
      api: { getPlayerInfo },
    },
  } = useMUD();

  const allPlayers = useEntityQuery([Has(Player), Has(Name), Has(Spawned), HasValue(Match, { value: match })]);
  const playerData = compact(allPlayers.map((player) => getPlayerInfo(player)));

  return playerData;
};
