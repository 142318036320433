import { defineComponent } from "@latticexyz/recs";
import { SetupNetworkResult } from "./setupNetwork";
import { defineBoolComponent } from "@latticexyz/std-client";

export type ClientComponents = ReturnType<typeof createClientComponents>;

export function createClientComponents({ components, world }: SetupNetworkResult) {
  return {
    ...components,
    NetworkChargeCap: components.ChargeCap,
    ChargeCap: defineComponent(world, components.ChargeCap.schema, {
      metadata: components.ChargeCap.metadata,
    }),
    Transaction: defineBoolComponent(world, { id: "Transaction" }),
  };
}
