import { tileCoordToPixelCoord } from "@latticexyz/phaserx";
import { defineComponentSystem, Entity, getComponentValue } from "@latticexyz/recs";
import { Animations } from "../../phaserConstants";
import { PhaserLayer, RenderDepth } from "../../types";

const RED = 0xff3c3c;

export function createDrawAttackableEntitiesSystem(layer: PhaserLayer) {
  const {
    world,
    parentLayers: {
      local: {
        components: { AttackableEntities, LocalPosition },
      },
    },
    scenes: {
      Main: {
        objectPool,
        maps: {
          Main: { tileWidth, tileHeight },
        },
      },
    },
    api: { tintObject },
  } = layer;

  defineComponentSystem(world, AttackableEntities, ({ value }) => {
    const [attackableEntities, previousAttackableEntities] = value;

    if (previousAttackableEntities) {
      for (let i = 0; i < previousAttackableEntities.value.length; i++) {
        objectPool.remove(`${previousAttackableEntities.value[i] as Entity}-attackable-highlight`);
      }
    }

    if (attackableEntities) {
      for (let i = 0; i < attackableEntities.value.length; i++) {
        const attackableHighlight = objectPool.get(`${attackableEntities.value[i]}-attackable-highlight`, "Sprite");
        const position = getComponentValue(LocalPosition, attackableEntities.value[i] as Entity);
        if (!position) continue;

        attackableHighlight.setComponent({
          id: `attackable-highlight`,
          once: async (box) => {
            const pixelCoord = tileCoordToPixelCoord(position, tileWidth, tileHeight);
            box.play(Animations.TileHighlight);
            box.setSize(tileWidth, tileHeight);
            box.setPosition(pixelCoord.x, pixelCoord.y);
            box.setDepth(RenderDepth.Background1);

            tintObject(box, RED);
          },
        });
      }
    }
  });
}
