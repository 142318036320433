import {
  defineSceneConfig,
  AssetType,
  defineScaleConfig,
  defineMapConfig,
  defineCameraConfig,
} from "@latticexyz/phaserx";
import { Sprites, Assets, Maps, Scenes, TILE_HEIGHT, TILE_WIDTH, Animations, WALK_ANIMATIONS } from "./phaserConstants";
import { Tileset, TileAnimations } from "./tilesets/overworld";
import overworldTileset from "../../../public/tilesets/overworld.png";
import { UnitTypes } from "../../Network";
import { toLower } from "lodash";

const ANIMATION_INTERVAL = 200;

function createWalkAnimations() {
  const animationConfigs: {
    key: string;
    assetKey: Assets.MainAtlas;
    startFrame: number;
    endFrame: number;
    frameRate: number;
    repeat: number;
    prefix: string;
    suffix: string;
  }[] = [];

  for (const unitTypeString in UnitTypes) {
    const unitType = Number(unitTypeString) as UnitTypes;
    if (!WALK_ANIMATIONS[unitType]) continue;

    const folderName = toLower(UnitTypes[unitType]);
    const walkAnimationKeys = WALK_ANIMATIONS[unitType];
    const directions = ["up", "down", "left", "right"];

    walkAnimationKeys.forEach((key, i) => {
      animationConfigs.push({
        key,
        assetKey: Assets.MainAtlas,
        startFrame: 0,
        endFrame: 3,
        frameRate: 6,
        repeat: -1,
        prefix: `sprites/units/${folderName}/walk/${directions[i]}/`,
        suffix: ".png",
      });
    });
  }

  return animationConfigs;
}

export const gameConfig = {
  sceneConfig: {
    [Scenes.Main]: defineSceneConfig({
      assets: {
        [Assets.OverworldTileset]: { type: AssetType.Image, key: Assets.OverworldTileset, path: overworldTileset },
        [Assets.MainAtlas]: {
          type: AssetType.MultiAtlas,
          key: Assets.MainAtlas,
          // Add a timestamp to the end of the path to prevent caching
          path: `/public/atlases/sprites/atlas.json?timestamp=${Date.now()}`,
          options: {
            imagePath: "/public/atlases/sprites/",
          },
        },
        [Assets.Background]: {
          type: AssetType.Image,
          key: Assets.Background,
          path: "/public/assets/background.png",
        },
        [Assets.CloudBackground]: {
          type: AssetType.Image,
          key: Assets.CloudBackground,
          path: "/public/assets/cloud-background.png",
        },
        [Assets.Cloud1]: {
          type: AssetType.Image,
          key: Assets.Cloud1,
          path: "/public/assets/cloud-1.png",
        },
        [Assets.Cloud2]: {
          type: AssetType.Image,
          key: Assets.Cloud2,
          path: "/public/assets/cloud-2.png",
        },
        [Assets.TiledBackground]: {
          type: AssetType.Image,
          key: Assets.TiledBackground,
          path: "/public/assets/tiled-background-4.png",
        },
        [Assets.MinimapBackground]: {
          type: AssetType.Image,
          key: Assets.MinimapBackground,
          path: "/public/assets/minimap-background.png",
        },
        [Assets.Professor]: {
          type: AssetType.Image,
          key: Assets.Professor,
          path: "/public/assets/professor.png",
        },
        [Assets.Splash]: {
          type: AssetType.Image,
          key: Assets.Splash,
          path: "/public/assets/splash.png",
        },
        [Assets.Gold]: {
          type: AssetType.Image,
          key: Assets.Gold,
          path: "/public/assets/gold.png",
        },
        [Assets.Central]: {
          type: AssetType.Image,
          key: Assets.Central,
          path: "/public/assets/depth/central.png",
        },
        [Assets.LeftMid]: {
          type: AssetType.Image,
          key: Assets.LeftMid,
          path: "/public/assets/depth/left-mid.png",
        },
        [Assets.RightMid]: {
          type: AssetType.Image,
          key: Assets.RightMid,
          path: "/public/assets/depth/right-mid.png",
        },
        [Assets.LeftCornerUpA]: {
          type: AssetType.Image,
          key: Assets.LeftCornerUpA,
          path: "/public/assets/depth/left-corner-up-a.png",
        },
        [Assets.LeftCornerUpB]: {
          type: AssetType.Image,
          key: Assets.LeftCornerUpB,
          path: "/public/assets/depth/left-corner-up-b.png",
        },
        [Assets.RightCornerUpA]: {
          type: AssetType.Image,
          key: Assets.RightCornerUpA,
          path: "/public/assets/depth/right-corner-up-a.png",
        },
        [Assets.RightCornerUpB]: {
          type: AssetType.Image,
          key: Assets.RightCornerUpB,
          path: "/public/assets/depth/right-corner-up-b.png",
        },
        [Assets.LeftEdge]: {
          type: AssetType.Image,
          key: Assets.LeftEdge,
          path: "/public/assets/depth/left-edge.png",
        },
        [Assets.RightEdge]: {
          type: AssetType.Image,
          key: Assets.RightEdge,
          path: "/public/assets/depth/right-edge.png",
        },
        [Assets.UpperEdge]: {
          type: AssetType.Image,
          key: Assets.UpperEdge,
          path: "/public/assets/depth/upper-edge.png",
        },
        [Assets.UpperEdgeLeft]: {
          type: AssetType.Image,
          key: Assets.UpperEdgeLeft,
          path: "/public/assets/depth/upper-corner-left.png",
        },
        [Assets.UpperEdgeRight]: {
          type: AssetType.Image,
          key: Assets.UpperEdgeRight,
          path: "/public/assets/depth/upper-corner-right.png",
        },
      },
      maps: {
        [Maps.Main]: defineMapConfig({
          chunkSize: TILE_WIDTH * 64, // tile size * tile amount
          tileWidth: TILE_WIDTH,
          tileHeight: TILE_HEIGHT,
          backgroundTile: [Tileset.Blank],
          animationInterval: ANIMATION_INTERVAL,
          tileAnimations: TileAnimations,
          layers: {
            layers: {
              Background: { tilesets: ["Default"], hasHueTintShader: true },
              Foreground: { tilesets: ["Default"], hasHueTintShader: true },
            },
            defaultLayer: "Background",
          },
        }),
      },
      sprites: {
        [Sprites.Shadow]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/base/shadow-round.png",
        },
        [Sprites.Settlement]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/structures/big/forward-settlement/0.png",
        },
        [Sprites.Village]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/structures/big/village.png",
        },
        [Sprites.SpawnSettlement]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/structures/big/home-settlement/0.png",
        },
        [Sprites.Gold]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/items/gold.png",
        },
        [Sprites.Ruins]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/structures/big/ruin.png",
        },
        [Sprites.Container]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/items/bag.png",
        },
        [Sprites.GoldShrine]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/structures/small/gold-shrine.png",
        },
        [Sprites.Portal]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/structures/small/portal-grayscale/0.png",
        },
        [Sprites.EmberCrown]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/items/ember-crown.png",
        },
        [Sprites.SummoningAltar]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/structures/big/magic-portal/0.png",
        },
        [Sprites.WoodenWall]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/structures/big/wooden-barricade.png",
        },
        [Sprites.Donkey]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/workers/donkey.png",
        },
        [Sprites.Soldier]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/warriors/hero.png",
        },
        [Sprites.Spear]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/warriors/pikeman.png",
        },
        [Sprites.SelectUI]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/big-select.png",
        },
        [Sprites.Chain]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/chain.png",
        },
        [Sprites.Cog]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/cog.png",
        },
        [Sprites.Longbowman]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/warriors/archer.png",
        },
        [Sprites.Ballista]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/warriors/archer.png",
        },

        [Sprites.Crystal]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/structures/big/power-crystal/0.png",
        },

        [Sprites.BarBackground]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/bars/background.png",
        },
        [Sprites.HealthBar]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/bars/green.png",
        },
        [Sprites.HealthBarRed]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/bars/red.png",
        },
        [Sprites.GoldBar]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/bars/yellow.png",
        },

        [Sprites.StaminaTickBackground]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/stamina/background.png",
        },
        [Sprites.StaminaTick]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/stamina/tick.png",
        },

        [Sprites.Banner]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/banner/0.png",
        },

        [Sprites.ResourceBar]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/bar-1.png",
        },
        [Sprites.YellowTick]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/yellow-tick.png",
        },
        [Sprites.GreenTick]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/green-tick.png",
        },
        [Sprites.OrangeTick]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/orange-tick.png",
        },
        [Sprites.RedTick]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/red-tick.png",
        },

        [Sprites.Swordsman]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/units/swordsman/idle/0.png",
        },
        [Sprites.Pikeman]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/units/pikeman/idle/0.png",
        },
        [Sprites.Golem]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/units/golem/idle/0.png",
        },

        [Sprites.Archer]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/units/archer/idle/0.png",
        },
        [Sprites.Catapult]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/units/catapult/idle/0.png",
        },
        [Sprites.Wizard]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/units/wizard/idle/0.png",
        },

        [Sprites.Rider]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/units/rider/idle/0.png",
        },
        [Sprites.Knight]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/units/knight/idle/0.png",
        },
        [Sprites.Dragon]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/units/dragon/idle/0.png",
        },

        [Sprites.ZeroCircle]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/stamina/0.png",
        },
        [Sprites.OneCircle]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/stamina/1.png",
        },
        [Sprites.TwoCircle]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/stamina/2.png",
        },
        [Sprites.ThreeCircle]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/stamina/3.png",
        },

        [Sprites.Zero]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/digits/0.png",
        },
        [Sprites.One]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/digits/1.png",
        },
        [Sprites.Two]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/digits/2.png",
        },
        [Sprites.Three]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/digits/3.png",
        },
        [Sprites.Four]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/digits/4.png",
        },
        [Sprites.Five]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/digits/5.png",
        },
        [Sprites.Six]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/digits/6.png",
        },
        [Sprites.Seven]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/digits/7.png",
        },
        [Sprites.Eight]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/digits/8.png",
        },
        [Sprites.Nine]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/digits/9.png",
        },

        [Sprites.CombatPreview]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/combat-preview.png",
        },
        [Sprites.BuildFrame]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/frames/build-frame.png",
        },
        [Sprites.UnitFrame]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/frames/unit-frame.png",
        },
        [Sprites.CrossedSwords]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/crossed-swords.png",
        },
        [Sprites.PercentGreen]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/Percentage-green.png",
        },
        [Sprites.PercentRed]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/Percentage-Red.png",
        },

        [Sprites.ZeroRed]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/0-Red.png",
        },
        [Sprites.OneRed]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/1-Red.png",
        },
        [Sprites.TwoRed]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/2-Red.png",
        },
        [Sprites.ThreeRed]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/3-Red.png",
        },
        [Sprites.FourRed]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/4-Red.png",
        },
        [Sprites.FiveRed]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/5-Red.png",
        },
        [Sprites.SixRed]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/6-Red.png",
        },
        [Sprites.SevenRed]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/7-Red.png",
        },
        [Sprites.EightRed]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/8-Red.png",
        },
        [Sprites.NineRed]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/9-Red.png",
        },

        [Sprites.ZeroGreen]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/0-Green.png",
        },
        [Sprites.OneGreen]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/1-Green.png",
        },
        [Sprites.TwoGreen]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/2-Green.png",
        },
        [Sprites.ThreeGreen]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/3-Green.png",
        },
        [Sprites.FourGreen]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/4-Green.png",
        },
        [Sprites.FiveGreen]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/5-Green.png",
        },
        [Sprites.SixGreen]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/6-Green.png",
        },
        [Sprites.SevenGreen]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/7-Green.png",
        },
        [Sprites.EightGreen]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/8-Green.png",
        },
        [Sprites.NineGreen]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/9-Green.png",
        },

        [Sprites.EmberCrownShrineRisingBackground]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/structures/big/ember-crown-shrine/rising-background.png",
        },
        [Sprites.EmberCrownShrineRisingForeground]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/structures/big/ember-crown-shrine/rising-foreground.png",
        },
      },
      animations: [
        //UI
        {
          key: Animations.ChainCarousel,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 7,
          frameRate: 12,
          repeat: -1,
          prefix: "sprites/ui/icons/chain-carousel/",
          suffix: ".png",
        },
        {
          key: Animations.Selection,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 3,
          frameRate: 6,
          repeat: -1,
          prefix: "sprites/ui/selection/",
          suffix: ".png",
        },
        {
          key: Animations.EnemySelection,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 3,
          frameRate: 6,
          repeat: -1,
          prefix: "sprites/ui/enemy-selection/",
          suffix: ".png",
        },
        {
          key: Animations.TileHighlight,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 2,
          frameRate: 3,
          repeat: -1,
          prefix: "sprites/ui/tile-highlight/",
          suffix: ".png",
        },
        {
          key: Animations.TileOutline,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 0,
          frameRate: 3,
          repeat: -1,
          prefix: "sprites/ui/tile-outline/",
          suffix: ".png",
        },
        {
          key: Animations.TileSelect,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 0,
          frameRate: 3,
          repeat: -1,
          prefix: "sprites/ui/tile-select/",
          suffix: ".png",
        },

        {
          key: Animations.Portal,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 16,
          frameRate: 4,
          repeat: -1,
          prefix: "sprites/structures/big/portal-2/",
          suffix: ".png",
        },
        {
          key: Animations.GoldMine,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 17,
          frameRate: 6,
          repeat: -1,
          prefix: "sprites/structures/big/power-crystal/",
          suffix: ".png",
        },
        {
          key: Animations.SummoningAltar,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 4,
          frameRate: 4,
          repeat: -1,
          prefix: "sprites/structures/big/magic-circle/",
          suffix: ".png",
        },
        {
          key: Animations.GoldShrine,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 59,
          frameRate: 15,
          repeat: -1,
          prefix: "sprites/structures/big/gold-shrine/",
          suffix: ".png",
        },
        {
          key: Animations.Sphinx,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 15,
          frameRate: 6,
          repeat: -1,
          prefix: "sprites/structures/big/sphinx/",
          suffix: ".png",
        },
        {
          key: Animations.SpawnSettlement,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 11,
          frameRate: 6,
          repeat: -1,
          prefix: "sprites/structures/big/home-settlement-2/",
          suffix: ".png",
        },
        {
          key: Animations.Settlement,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 4,
          frameRate: 6,
          repeat: -1,
          prefix: "sprites/structures/big/forward-settlement/",
          suffix: ".png",
        },

        // Units
        {
          key: Animations.SwordsmanIdle,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 3,
          frameRate: 6,
          repeat: -1,
          prefix: "sprites/units/swordsman/idle/",
          suffix: ".png",
        },
        {
          key: Animations.SwordsmanAttack,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 5,
          frameRate: 6,
          repeat: 0,
          prefix: "sprites/units/swordsman/attack/",
          suffix: ".png",
        },
        {
          key: Animations.SwordsmanDeath,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 5,
          frameRate: 6,
          repeat: 0,
          prefix: "sprites/units/swordsman/death/",
          suffix: ".png",
        },

        {
          key: Animations.PikemanIdle,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 3,
          frameRate: 4,
          repeat: -1,
          prefix: "sprites/units/pikeman/idle/",
          suffix: ".png",
        },
        {
          key: Animations.PikemanAttack,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 5,
          frameRate: 6,
          repeat: 0,
          prefix: "sprites/units/pikeman/attack/",
          suffix: ".png",
        },
        {
          key: Animations.PikemanDeath,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 11,
          frameRate: 4,
          repeat: 0,
          prefix: "sprites/units/pikeman/death/",
          suffix: ".png",
        },

        {
          key: Animations.GolemIdle,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 3,
          frameRate: 4,
          repeat: -1,
          prefix: "sprites/units/golem/idle/",
          suffix: ".png",
        },
        {
          key: Animations.GolemAttack,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 5,
          frameRate: 6,
          repeat: 0,
          prefix: "sprites/units/golem/attack/",
          suffix: ".png",
        },
        {
          key: Animations.GolemDeath,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 11,
          frameRate: 4,
          repeat: 0,
          prefix: "sprites/units/golem/death/",
          suffix: ".png",
        },

        {
          key: Animations.ArcherIdle,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 3,
          frameRate: 4,
          repeat: -1,
          prefix: "sprites/units/archer/idle/",
          suffix: ".png",
        },
        {
          key: Animations.ArcherAttack,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 5,
          frameRate: 6,
          repeat: 0,
          prefix: "sprites/units/archer/attack/",
          suffix: ".png",
        },
        {
          key: Animations.ArcherDeath,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 5,
          frameRate: 4,
          repeat: 0,
          prefix: "sprites/units/archer/death/",
          suffix: ".png",
        },

        {
          key: Animations.CatapultIdle,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 3,
          frameRate: 4,
          repeat: -1,
          prefix: "sprites/units/catapult/idle/",
          suffix: ".png",
        },
        {
          key: Animations.CatapultAttack,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 5,
          frameRate: 6,
          repeat: 0,
          prefix: "sprites/units/catapult/attack/",
          suffix: ".png",
        },
        {
          key: Animations.CatapultDeath,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 11,
          frameRate: 4,
          repeat: 0,
          prefix: "sprites/units/catapult/death/",
          suffix: ".png",
        },

        {
          key: Animations.WizardIdle,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 3,
          frameRate: 4,
          repeat: -1,
          prefix: "sprites/units/wizard/idle/",
          suffix: ".png",
        },
        {
          key: Animations.WizardAttack,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 5,
          frameRate: 6,
          repeat: 0,
          prefix: "sprites/units/wizard/attack/",
          suffix: ".png",
        },
        {
          key: Animations.WizardDeath,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 11,
          frameRate: 4,
          repeat: 0,
          prefix: "sprites/units/wizard/death/",
          suffix: ".png",
        },

        {
          key: Animations.RiderIdle,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 3,
          frameRate: 4,
          repeat: -1,
          prefix: "sprites/units/rider/idle/",
          suffix: ".png",
        },
        {
          key: Animations.RiderAttack,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 5,
          frameRate: 6,
          repeat: 0,
          prefix: "sprites/units/rider/attack/",
          suffix: ".png",
        },
        {
          key: Animations.RiderDeath,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 7,
          frameRate: 4,
          repeat: 0,
          prefix: "sprites/units/rider/death/",
          suffix: ".png",
        },

        {
          key: Animations.KnightIdle,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 3,
          frameRate: 4,
          repeat: -1,
          prefix: "sprites/units/knight/idle/",
          suffix: ".png",
        },
        {
          key: Animations.KnightAttack,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 5,
          frameRate: 6,
          repeat: 0,
          prefix: "sprites/units/knight/attack/",
          suffix: ".png",
        },
        {
          key: Animations.KnightDeath,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 8,
          frameRate: 4,
          repeat: 0,
          prefix: "sprites/units/knight/death/",
          suffix: ".png",
        },

        {
          key: Animations.DragonIdle,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 3,
          frameRate: 4,
          repeat: -1,
          prefix: "sprites/units/dragon/idle/",
          suffix: ".png",
        },
        {
          key: Animations.DragonAttack,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 5,
          frameRate: 6,
          repeat: 0,
          prefix: "sprites/units/dragon/attack/",
          suffix: ".png",
        },
        {
          key: Animations.DragonDeath,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 11,
          frameRate: 4,
          repeat: 0,
          prefix: "sprites/units/dragon/death/",
          suffix: ".png",
        },

        // Items
        {
          key: Animations.BlazingHeart,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 0,
          frameRate: 6,
          repeat: -1,
          prefix: "sprites/items/materials/crystal-heart/",
          suffix: ".png",
        },
        {
          key: Animations.Torch,
          assetKey: Assets.MainAtlas,
          startFrame: 1,
          endFrame: 16,
          frameRate: 6,
          repeat: -1,
          prefix: "sprites/items/torch/",
          suffix: ".png",
        },
        {
          key: Animations.StaminaBanner,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 7,
          frameRate: 6,
          repeat: -1,
          prefix: "sprites/items/amplifiers/stamina/",
          suffix: ".png",
        },
        {
          key: Animations.MovementBanner,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 7,
          frameRate: 6,
          repeat: -1,
          prefix: "sprites/items/amplifiers/movement/",
          suffix: ".png",
        },
        {
          key: Animations.StrengthBanner,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 7,
          frameRate: 6,
          repeat: -1,
          prefix: "sprites/items/amplifiers/strength/",
          suffix: ".png",
        },

        {
          key: Animations.StrengthAura,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 5,
          frameRate: 6,
          repeat: -1,
          prefix: "sprites/auras/strength/",
          suffix: ".png",
        },
        {
          key: Animations.MovementAura,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 5,
          frameRate: 6,
          repeat: -1,
          prefix: "sprites/auras/movement/",
          suffix: ".png",
        },
        {
          key: Animations.StaminaAura,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 5,
          frameRate: 6,
          repeat: -1,
          prefix: "sprites/auras/stamina/",
          suffix: ".png",
        },

        {
          key: Animations.Crystal,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 0,
          frameRate: 6,
          repeat: -1,
          prefix: "sprites/items/materials/crystal/",
          suffix: ".png",
        },
        {
          key: Animations.Metal,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 0,
          frameRate: 6,
          repeat: -1,
          prefix: "sprites/items/materials/metal/",
          suffix: ".png",
        },
        {
          key: Animations.Fossil,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 0,
          frameRate: 6,
          repeat: -1,
          prefix: "sprites/items/materials/fossil/",
          suffix: ".png",
        },
        {
          key: Animations.Widget,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 0,
          frameRate: 6,
          repeat: -1,
          prefix: "sprites/items/materials/widget/",
          suffix: ".png",
        },
        {
          key: Animations.CrystalGenerator,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 7,
          frameRate: 4,
          repeat: -1,
          prefix: "sprites/structures/big/generators/crystal/",
          suffix: ".png",
        },
        {
          key: Animations.MetalGenerator,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 9,
          frameRate: 4,
          repeat: -1,
          prefix: "sprites/structures/big/generators/metal/",
          suffix: ".png",
        },
        {
          key: Animations.FossilGenerator,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 7,
          frameRate: 4,
          repeat: -1,
          prefix: "sprites/structures/big/generators/fossil/",
          suffix: ".png",
        },
        {
          key: Animations.WidgetGenerator,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 3,
          frameRate: 4,
          repeat: -1,
          prefix: "sprites/structures/big/generators/widget/",
          suffix: ".png",
        },

        {
          key: Animations.EmberCrownShrineIdle,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 13,
          frameRate: 6,
          repeat: -1,
          prefix: "sprites/structures/big/ember-crown-shrine/idle/",
          suffix: ".png",
        },
        {
          key: Animations.EmberCrownShrineStart,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 20,
          frameRate: 6,
          repeat: 0,
          prefix: "sprites/structures/big/ember-crown-shrine/start/",
          suffix: ".png",
        },
        {
          key: Animations.EmberCrownShrineRising,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 11,
          frameRate: 6,
          repeat: -1,
          prefix: "sprites/structures/big/ember-crown-shrine/rising/",
          suffix: ".png",
        },
        {
          key: Animations.EmberCrownShrineEnd,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 9,
          frameRate: 6,
          repeat: 0,
          prefix: "sprites/structures/big/ember-crown-shrine/end/",
          suffix: ".png",
        },

        {
          key: Animations.Teleport,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 5,
          frameRate: 6,
          repeat: -1,
          prefix: "sprites/animations/teleport/",
          suffix: ".png",
        },

        {
          key: Animations.Capture,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 28,
          frameRate: 6,
          repeat: 0,
          prefix: "sprites/animations/capture/",
          suffix: ".png",
        },

        {
          key: Animations.LongGrass,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 7,
          frameRate: 6,
          repeat: -1,
          prefix: "sprites/terrain/grass/",
          suffix: ".png",
        },
        ...createWalkAnimations(),
      ],
      tilesets: {
        Default: { assetKey: Assets.OverworldTileset, tileWidth: TILE_WIDTH, tileHeight: TILE_HEIGHT },
      },
    }),
    [Scenes.UI]: defineSceneConfig({
      assets: {},
      maps: {},
      animations: [],
      tilesets: {},
      sprites: {},
    }),
  },
  scale: defineScaleConfig({
    parent: "phaser-game",
    zoom: 1,
    mode: Phaser.Scale.NONE,
  }),
  cameraConfig: defineCameraConfig({
    pinchSpeed: 1,
    wheelSpeed: 1,
    maxZoom: 3,
    minZoom: 1,
  }),
  cullingChunkSize: TILE_HEIGHT * 16,
};
