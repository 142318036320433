import { defineSystem, Entity, getComponentValue, Has, HasValue } from "@latticexyz/recs";
import { ItemTypes, StructureTypes } from "../../../../Network";
import { PhaserLayer } from "../../types";

export const createMusicSystem = (layer: PhaserLayer) => {
  const {
    sounds,
    parentLayers: {
      network: {
        network: { world },
        components: { ItemType, OwnedBy, StructureType },
      },
    },
  } = layer;

  function startIntenseMusic() {
    if (sounds["intense-battle"].isPlaying) return;

    sounds["field-battle"].stop();
    sounds["intense-battle"].play();
  }

  defineSystem(world, [HasValue(StructureType, { value: StructureTypes.EmberCrownShrine }), Has(OwnedBy)], () => {
    startIntenseMusic();
  });

  defineSystem(world, [HasValue(ItemType, { value: ItemTypes.EmberCrown }), Has(OwnedBy)], ({ entity }) => {
    const ownedBy = getComponentValue(OwnedBy, entity)?.value;
    if (!ownedBy) return;
    const structureType = getComponentValue(StructureType, ownedBy as Entity)?.value;
    if (!structureType) startIntenseMusic();
  });
};
