import React, { useMemo } from "react";
import { getComponentValue } from "@latticexyz/recs";
import { concat, map } from "rxjs";
import { useObservableValue } from "../../useObservableValue";
import { filterNullish } from "@latticexyz/utils";
import { NetworkLayer } from "../../layers/Network";
import { Button } from "../ui/Theme/SkyStrife/Button";
import { LoadingBar } from "../ui/LoadingBar";
import { Body, Caption, Link, OverlineLarge } from "../ui/Theme/SkyStrife/Typography";
import { Card } from "../ui/Theme/SkyStrife/Card";
import { DISCORD_URL, HOW_TO_PLAY_URL, LATTICE_URL, MUD_URL } from "../links";
import { SyncStep } from "@latticexyz/store-sync/recs";

type Props = {
  networkLayer: NetworkLayer | null;
};

export const LoadingScreen = ({ networkLayer }: Props) => {
  const [hide, setHide] = React.useState(false);

  const loadingState = useObservableValue(
    useMemo(() => {
      if (!networkLayer) return;

      const {
        components: { SyncProgress },
        network: { singletonEntity },
      } = networkLayer;

      // use LoadingState.update$ as a trigger rather than a value
      // and concat with an initial value to trigger the first look up
      return concat([1], SyncProgress.update$).pipe(
        map(() => {
          const loadingState = getComponentValue(SyncProgress, singletonEntity);
          return loadingState ?? null;
        }),
        filterNullish()
      );
    }, [networkLayer]),
    { message: "Connecting", percentage: 0, step: SyncStep.INITIALIZE }
  );

  if (hide) {
    return null;
  }

  return (
    <div
      style={{
        zIndex: 1000,
        background: "linear-gradient(rgba(24, 23, 16, 0.4), rgba(24, 23, 16, 0.4)), url(assets/ss-splash-1.png)",
        backgroundPosition: "right",
        backgroundSize: "cover",
      }}
      className="fixed items-center justify-center w-screen h-screen bg-black p-12 flex flex-col"
    >
      <Card primary className="flex flex-col w-[540px] p-8 justify-items">
        <OverlineLarge
          style={{
            fontSize: "80px",
            lineHeight: "110%",
            letterSpacing: "-2%",
          }}
          className="normal-case text-center"
        >
          Sky Strife
        </OverlineLarge>
        <div className="h-3" />
        <Body className="text-ss-text-light text-center">
          Sky Strife is a fully onchain RTS. Compete for control of islands, earn resources, and craft items.
        </Body>

        {loadingState.step === SyncStep.LIVE ? (
          <div className="flex flex-col mt-8 grow">
            <Body className="text-center text-ss-text-default">Connected!</Body>
            <Button
              buttonType="primary"
              size="lg"
              onClick={() => {
                setHide(true);
              }}
              className="mt-4 w-full"
            >
              Play
            </Button>
            <div className="h-4"></div>
            <a
              className="w-full"
              href="https://latticexyz.notion.site/How-to-play-Sky-Strife-8c9f951c605e487cad9e8158bc641835?pvs=4}"
              target="_blank"
            >
              <Button buttonType="tertiary" size="lg" className="w-full">
                How To Play
              </Button>
            </a>
          </div>
        ) : (
          <div className="flex flex-col w-full mt-8">
            <Body className="text-center text-ss-text-default">{loadingState.message}…</Body>
            <Body className="text-center text-ss-text-default">{Math.floor(loadingState.percentage)}%</Body>
            <LoadingBar className="mt-2" percentage={loadingState.percentage} />
          </div>
        )}
      </Card>

      <div className="h-4"></div>

      <img className="ml-2 w-[546px]" src="/public/assets/unit-stats.png"></img>

      <div className="stretch"></div>

      <div className="flex justify-between stretch">
        <div className="absolute bottom-6 left-6 flex justify-between">
          <Link className="text-ss-gold" href={LATTICE_URL}>
            lattice.xyz
          </Link>

          <div className="w-6 text-center text-ss-divider-stroke">|</div>

          <Link className="text-ss-gold" href={DISCORD_URL}>
            join discord
          </Link>

          <div className="w-6 text-center text-ss-divider-stroke">|</div>

          <Link className="text-ss-gold" href={HOW_TO_PLAY_URL}>
            getting started
          </Link>
        </div>

        <Caption className="absolute bottom-6 right-6 ml-4 text-neutral-300">
          powered by{" "}
          <Link className="text-ss-gold" href={MUD_URL}>
            MUD
          </Link>
        </Caption>
      </div>
    </div>
  );
};
