import { Has, getComponentValue, getComponentValueStrict, runQuery } from "@latticexyz/recs";
import { NetworkLayer } from "../../../layers/Network";
import { SingletonID } from "@latticexyz/network";

export function getOldestMatchInWindow(layer: NetworkLayer, _blockNumber: bigint, window: bigint) {
  const {
    components: { Match, MatchSky, LatestMatch },
  } = layer;

  const blockNumber = _blockNumber > window ? _blockNumber - window : 0;
  const allMatches = runQuery([Has(MatchSky)]);

  let maxIndex = (getComponentValue(LatestMatch, SingletonID)?.value ?? 0) + 1;
  let maxBlockNumber = BigInt(2) ** BigInt(256) - BigInt(1);

  for (const match of allMatches) {
    const matchBlockNumber = getComponentValueStrict(MatchSky, match).blockNumber;
    if (matchBlockNumber > blockNumber && matchBlockNumber < maxBlockNumber) {
      maxIndex = getComponentValueStrict(Match, match).value;
      maxBlockNumber = matchBlockNumber;
    }
  }

  return maxIndex;
}

export function getMatchRewardAtBlock(layer: NetworkLayer, blockNumber: bigint) {
  const {
    components: { SkyPoolConfig, LatestMatch },
  } = layer;

  const skypoolEntity = [...runQuery([Has(SkyPoolConfig)])][0];
  const skypoolConfig = getComponentValue(SkyPoolConfig, skypoolEntity);
  if (!skypoolConfig) return 0;

  const { window } = skypoolConfig;

  const oldestMatchIndex = getOldestMatchInWindow(layer, blockNumber, window);
  const latestMatchEntity = [...runQuery([Has(LatestMatch)])][0];
  const nextMatchIndex = (getComponentValue(LatestMatch, latestMatchEntity)?.value ?? 0) + 1;

  const numberOfMatches = nextMatchIndex - oldestMatchIndex;
  const reward = Math.floor(2400000 / (numberOfMatches + 3000)) - 500;

  return reward;
}
