import { Caption, OverlineSmall } from "../ui/Theme/SkyStrife/Typography";
import { LiveMatch } from "./LiveMatch";
import { useAmalgema } from "../../store";
import { useEntityQuery } from "@latticexyz/react";
import { Has, HasValue, Not, getComponentValue, getComponentValueStrict, runQuery } from "@latticexyz/recs";

export function LiveMatches() {
  const {
    components: { MatchConfig, MatchReady, Match, Player, SpawnPoint, MatchFinished },
  } = useAmalgema();

  const allLobbies = useEntityQuery([Has(Match), Has(MatchConfig), Has(MatchReady), Not(MatchFinished)]);
  const allPlayers = useEntityQuery([Has(Match), Has(Player)]);

  const liveLobbies = allLobbies.filter((i) => {
    const matchId = getComponentValue(Match, i)?.value;
    if (!matchId) return false;

    const playersInMatch = allPlayers.filter((i) => {
      const match = getComponentValue(Match, i)?.value;
      return match === matchId;
    });
    const spawns = runQuery([HasValue(Match, { value: matchId }), Has(SpawnPoint)]);

    return playersInMatch.length === spawns.size;
  });

  return (
    <div>
      <OverlineSmall className="mb-4 uppercase">Live Islands ({liveLobbies.length})</OverlineSmall>
      <div className="flex flex-row flex-wrap">
        {liveLobbies.length === 0 && (
          <div className="w-full h-[200px] border border-ss-stroke rounded flex flex-col justify-around bg-ss-bg-0">
            <Caption className="text-center">No live islands</Caption>
          </div>
        )}
        {liveLobbies.map((i) => {
          const matchId = getComponentValueStrict(Match, i).value;

          return (
            <div className="mr-6 mb-6" key={i}>
              <LiveMatch matchId={matchId} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
