import { useEntityQuery } from "@latticexyz/react";
import { Button } from "../ui/Theme/SkyStrife/Button";
import { Card } from "../ui/Theme/SkyStrife/Card";
import { Body, Heading } from "../ui/Theme/SkyStrife/Typography";
import { useAmalgema } from "../../store";
import { Has, HasValue, hasComponent } from "@latticexyz/recs";
import { twMerge } from "tailwind-merge";
import { MatchRewardsFooter } from "./MatchRewardsFooter";

export function OpenMatch({ matchId }: { matchId: number }) {
  const {
    components: { Match, SpawnPoint, SpawnReservedBy },
  } = useAmalgema();

  const spawns = useEntityQuery([HasValue(Match, { value: matchId }), Has(SpawnPoint)]);

  return (
    <Card primary>
      <div className="flex flex-row justify-between items-center">
        <Heading className="normal-case">Joinable island</Heading>
        <Body className="text-ss-text-x-light">#{matchId}</Body>
      </div>

      <div className="h-3"></div>

      <div className="flex flex-row w-full justify-between">
        {spawns.map((spawn, i) => {
          const spawnReserved = hasComponent(SpawnReservedBy, spawn);

          return (
            <div
              key={`spawn-${i}`}
              className={twMerge(
                "h-[24px] w-[52px] rounded-md border border-ss-stroke",
                spawnReserved ? "bg-ss-blue" : "bg-ss-bg-0"
              )}
            >
              {spawnReserved && (
                <Body className="w-full h-full flex flex-col items-center justify-around text-ss-text-default">
                  P{i + 1}
                </Body>
              )}
            </div>
          );
        })}
      </div>

      <div className="h-6"></div>

      <a href={`/match?match=${matchId}`} target="_blank">
        <Button buttonType={"secondary"} className="w-full">
          Join
        </Button>
      </a>

      <div className="mb-3"></div>

      <a href={`/match?match=${matchId}&spectate=true`} target="_blank">
        <Button buttonType={"tertiary"} className="w-full">
          Spectate
        </Button>
      </a>

      <div className="mb-4"></div>

      <MatchRewardsFooter matchId={matchId} />
    </Card>
  );
}
