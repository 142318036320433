import { Has, defineEnterSystem, getComponentValueStrict, setComponent } from "@latticexyz/recs";
import { HeadlessLayer } from "../..";

export function createScopeClientToMatchSystem(layer: HeadlessLayer) {
  const {
    world,
    parentLayers: {
      network: {
        components: { Position },
        network: { match },
      },
    },
    components: { InCurrentMatch },
  } = layer;

  defineEnterSystem(world, [Has(Position)], ({ entity }) => {
    const position = getComponentValueStrict(Position, entity);
    if (position.z !== match) return;

    setComponent(InCurrentMatch, entity, { value: true });
  });
}
