import { useState } from "react";
import { useMUD } from "../../store";
import { Entity, Has, HasValue, getComponentValue, getComponentValueStrict } from "@latticexyz/recs";
import { useComponentValue, useEntityQuery } from "@latticexyz/react";
import { ClickWrapper } from "./Theme/ClickWrapper";
import { BrutalistCard } from "./Theme/BrutalistCard";
import { CreateMatch } from "./CreateMatch";
import { Balances } from "./Balances";
import { Button } from "./Theme/SkyStrife/Button";
import { Hex, hexToString } from "viem";
import { useMatchRewards } from "../amalgema-ui/hooks/useMatchRewards";

const CopyButton = ({ match }: { match: Entity }) => {
  const {
    networkLayer: {
      network: { components: { MapSize, Match, MatchConfig, MatchMapCopyProgress }, worldContract },
    },
  } = useMUD();

  const matchId = getComponentValueStrict(Match, match).value;
  const progress = useComponentValue(MatchMapCopyProgress, match);
  const { levelId } = getComponentValueStrict(MatchConfig, match);
  const size = useComponentValue(MapSize, levelId as Entity);

  const fraction = progress && size ? Math.round(Number(progress.value * 100n) / Number(size.value)) : 0;

  return (
    <Button
      buttonType={"primary"}
      onClick={() => {
        worldContract.write.copyMap([matchId]);
      }}
    >
      Copy ({fraction}%)
    </Button>
  );
};

const Row = ({ match }: { match: Entity }) => {
  const {
    networkLayer: {
      components: { Match, MatchConfig, MatchReady, SpawnPoint, SpawnReservedBy },
      network: { worldContract },
    },
  } = useMUD();

  const matchId = getComponentValueStrict(Match, match).value;
  const readyTime = useComponentValue(MatchReady, match);
  const spawns = useEntityQuery([Has(SpawnPoint), HasValue(Match, { value: matchId })]);
  const reserved = useEntityQuery([Has(SpawnReservedBy), HasValue(Match, { value: matchId })]);
  const matchConfig = getComponentValue(MatchConfig, match);
  const levelName = matchConfig?.levelId ? hexToString((matchConfig.levelId) as Hex, { size: 32 }) : "N/A";

  const rewards = useMatchRewards(matchId);

  return (
    <tr key={matchId}>
      <td>Match #{matchId}</td>
      <td>{levelName}</td>
      <td>{readyTime ? `${reserved.length} / ${spawns.length}` : "TBD"}</td>
      <td>
        {rewards.length > 0
          ? rewards.map((reward) => (
            <div key={reward.rank.toString()}>
              {(reward.rank + 1n).toString()}nd: {reward.emoji} {reward.value.toString()}
            </div>
          ))
          : "None"}
      </td>
      <td>
        {readyTime ? (
          // append existing URL params
          <a href={`/match${window.location.search}${window.location.search.length === 0 ? "?" : "&"}match=${matchId}`}>
            <Button buttonType={"primary"}>Join</Button>
          </a>
        ) : (
          <CopyButton match={match} />
        )}
        <Button
          buttonType="secondary"
          onClick={() => {
            worldContract.write.destroyMatch([matchId]);
          }}
        >
          Destroy Match
        </Button>
      </td>
    </tr>
  );
};

export const Browser = () => {
  const {
    networkLayer: {
      components: { MatchConfig },
      network: { playerEntity },
    },
  } = useMUD();

  const [visible, setVisible] = useState(false);
  const allMatches = useEntityQuery([Has(MatchConfig)]);

  return (
    <div className="fixed h-screen w-screen">
      <div className="relative mx-auto top-1/2 -translate-y-1/2 flex flex-col items-center">
        <ClickWrapper className="h-fit w-[800px]">
          <BrutalistCard>
            {playerEntity && <Balances player={playerEntity} />}
            <div className="p-4 max-h-[300px] overflow-scroll">
              <div className="flex flex-row">
                <div className="w-full text-3xl text-left p-1">Lobbies</div>
                <Button buttonType={"secondary"} onClick={() => setVisible(true)}>
                  Create match
                </Button>
              </div>
              <table className="w-full text-lg text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xl text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th>Lobby</th>
                    <th>Map</th>
                    <th>Players</th>
                    <th>Rewards</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {allMatches.map((match) => (
                    <Row key={match} match={match} />
                  ))}
                </tbody>
              </table>
            </div>
          </BrutalistCard>
        </ClickWrapper>
      </div>
      {visible && <CreateMatch visible={visible} setVisible={setVisible} />}
    </div>
  );
};
