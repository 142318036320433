import { useMUD } from "../../../store";
import { useMatchRewards } from "../../amalgema-ui/hooks/useMatchRewards";
import { Card } from "../Theme/SkyStrife/Card";
import { Body, OverlineSmall } from "../Theme/SkyStrife/Typography";
import { Mana } from "../Theme/SkyStrife/Mana";

export function MatchRewards() {
  const {
    networkLayer: {
      network: { match },
    },
  } = useMUD();
  const matchRewards = useMatchRewards(match);
  if (matchRewards.length === 0) return <></>;

  return (
    <div>
      <Card primary className="w-[500px] p-8 pt-4">
        <OverlineSmall>reward pool</OverlineSmall>

        <div className="h-3"></div>

        {matchRewards.map((reward, i) => {
          const place = i + 1;
          let rankName = "th";
          if (place === 1) {
            rankName = "st";
          } else if (place === 2) {
            rankName = "nd";
          } else if (place === 3) {
            rankName = "rd";
          }

          return (
            <div key={i} className="flex flex-row justify-between items-center">
              <Body className="text-ss-default underline">
                {i + 1}
                {rankName} Place
              </Body>
              <Mana amount={Number(reward.value) || 0} />
            </div>
          );
        })}
      </Card>
    </div>
  );
}
