import { useEffect, useState } from "react";
import { useAmalgema } from "../../../store";
import { getMatchRewardAtBlock } from "../utils/skypool";

export function useCurrentMatchReward() {
  const networkLayer = useAmalgema();
  const {
    network: { publicClient },
  } = networkLayer;

  const [reward, setReward] = useState(0);

  useEffect(() => {
    return publicClient.watchBlockNumber({
      onBlockNumber: (blockNumber) => {
        const reward = getMatchRewardAtBlock(networkLayer, blockNumber);
        setReward(reward);
      },
    });
  }, []);

  return reward;
}
