import { Entity, Has, HasValue, Type, defineComponent, getComponentValue, getComponentValueStrict, namespaceWorld, runQuery, setComponent } from "@latticexyz/recs";
import { NetworkLayer } from "../Network";
import { createUnitBuySystem } from "./systems/createUnitBuySystem";
import { createUnitKillSystem } from "./systems/createUnitKillSystem";
import { createPreviousOwnerSystem } from "./systems/createPreviousOwnerSystem";
import { createMatchEventsSystem } from "./systems/createMatchEventsSystem";
import { uuid } from "@latticexyz/utils";
import { createTokenBalanceSystem } from "./systems/createTokenBalanceSystem";

export async function createAnalyticsLayer(network: NetworkLayer) {
  const world = namespaceWorld(network.network.world, "analytics");
  const components = {
    // Meta Components used for populating Events
    PreviousOwner: defineComponent(
      world,
      {
        value: Type.Entity,
      },
      { id: "PreviousOwner" }
    ),

    // Match Events
    CreateMatch: defineComponent(
      world,
      {
        createdAtBlock: Type.BigInt,
        mainWalletAddress: Type.Entity,
        map: Type.Entity,
        match: Type.Number,
      },
      { id: "CreateMatch" }
    ),
    CopyMap: defineComponent(
      world,
      {
        createdAtBlock: Type.BigInt,
        player: Type.Entity,
        match: Type.Number,
      },
      { id: "CopyMap" }
    ),
    JoinMatch: defineComponent(
      world,
      {
        createdAtBlock: Type.BigInt,
        mainWalletAddress: Type.String,
        player: Type.Entity,
        match: Type.Number,
      },
      { id: "JoinMatch" }
    ),
    EndMatch: defineComponent(
      world,
      {
        createdAtBlock: Type.BigInt,
        player: Type.Entity,
        match: Type.Number,
        playerRanking: Type.EntityArray,
      },
      { id: "EndMatch" }
    ),

    // In-match Events
    UnitKill: defineComponent(world, {
      turn: Type.Number,
      createdAtBlock: Type.BigInt,
      match: Type.Number,
      killerPlayer: Type.Entity,
      victimPlayer: Type.Entity,
      killerUnitType: Type.Number,
      victimUnitType: Type.Number,
      x: Type.Number,
      y: Type.Number,
    }, { id: "UnitKill" }),
    UnitDeath: defineComponent(world, {
      turn: Type.Number,
      createdAtBlock: Type.BigInt,
      match: Type.Number,
      killerPlayer: Type.Entity,
      victimPlayer: Type.Entity,
      killerUnitType: Type.Number,
      victimUnitType: Type.Number,
      x: Type.Number,
      y: Type.Number,
    }, { id: "UnitDeath" }),
    UnitBuy: defineComponent(world, {
      turn: Type.Number,
      createdAtBlock: Type.BigInt,
      match: Type.Number,
      player: Type.Entity,
      unitType: Type.Number,
      x: Type.Number,
      y: Type.Number,
    }, { id: "UnitBuy" }),
    StructureCapture: defineComponent(world, {
      turn: Type.Number,
      createdAtBlock: Type.BigInt,
      match: Type.Number,
      player: Type.Entity,
      previousOwnerPlayer: Type.Entity,
      capturerUnitType: Type.Number,
      structureType: Type.Number,
      x: Type.Number,
      y: Type.Number,
    }, { id: "StructureCapture" }),
    StructureKill: defineComponent(world, {
      turn: Type.Number,
      createdAtBlock: Type.BigInt,
      match: Type.Number,
      killerPlayer: Type.Entity,
      victimPlayer: Type.Entity,
      killerUnitType: Type.Number,
      victimStructureType: Type.Number,
      x: Type.Number,
      y: Type.Number,
    }, { id: "StructureKill" }),

    // Updated on Match End
    TokenBalanceSnapshot: defineComponent(
      world,
      {
        createdAtBlock: Type.BigInt,
        mainWalletAddress: Type.Entity,
        token: Type.Entity,
        balance: Type.Number,
      },
      { id: "TokenBalanceSnapshot" }
    ),

    // Updated on unit spawn and building capture
    GoldSnapshot: defineComponent(
      world,
      {
        createdAtBlock: Type.BigInt,
        match: Type.Number,
        player: Type.Entity,
        balance: Type.Number,
      },
      { id: "GoldSnapshot" }
    ),

    // Updated on unit spawn and unit kill
    UnitSnapshot: defineComponent(world, {
      turn: Type.Number,
      createdAtBlock: Type.BigInt,
      match: Type.Number,
      player: Type.Entity,
      unitType: Type.Number,
      count: Type.Number,
    }, { id: "UnitSnapshot" }),

    // Updated on building capture
    StructureSnapshot: defineComponent(world, {
      turn: Type.Number,
      createdAtBlock: Type.BigInt,
      match: Type.Number,
      player: Type.Entity,
      structureType: Type.Number,
      count: Type.Number,
    }, { id: "StructureSnapshot" }),
  }

  const {
    utils: {
      getTurnAtTime,
    },
    network: {
      publicClient,
      components: {
        UnitType, OwnedBy, Match, StructureType,
      },
      clock,
    },
  } = network;

  let currentBlockNumber: bigint;
  publicClient.watchBlockNumber({
    onBlockNumber: (blockNumber) => {
      currentBlockNumber = blockNumber;
    },
  });
  const getCurrentBlockNumber = () => currentBlockNumber;

  const storePlayerTotalUnitSnapshot = (playerEntity: Entity) => {
    const match = getComponentValueStrict(Match, playerEntity).value;
    const turn = getTurnAtTime(match, clock.currentTime / 1000);
    const playerUnits = [...runQuery([Has(UnitType), HasValue(OwnedBy, { value: playerEntity })])];
    const unitsGroupedByUnitType = playerUnits.reduce((acc, entity) => {
      const unitType = getComponentValue(UnitType, entity)?.value;
      if (unitType === undefined) return acc;
      if (acc[unitType] === undefined) {
        acc[unitType] = 0;
      }
      acc[unitType]++;
      return acc;
    }, {} as Record<number, number>);

    Object.entries(unitsGroupedByUnitType).forEach(([unitType, count]) => {
      setComponent(components.UnitSnapshot, uuid() as Entity, {
        turn,
        createdAtBlock: getCurrentBlockNumber(),
        match,
        player: playerEntity,
        unitType: Number(unitType),
        count,
      });
    });
  }

  const storePlayerTotalStructureSnapshot = (playerEntity: Entity) => {
    const match = getComponentValueStrict(Match, playerEntity).value;
    const turn = getTurnAtTime(match, clock.currentTime / 1000);
    const playerStructures = [...runQuery([Has(StructureType), HasValue(OwnedBy, { value: playerEntity })])];
    const structuresGroupedByStructureType = playerStructures.reduce((acc, entity) => {
      const structureType = getComponentValue(StructureType, entity)?.value;
      if (structureType === undefined) return acc;
      if (acc[structureType] === undefined) {
        acc[structureType] = 0;
      }
      acc[structureType]++;
      return acc;
    }, {} as Record<number, number>);

    Object.entries(structuresGroupedByStructureType).forEach(([structureType, count]) => {
      setComponent(components.StructureSnapshot, uuid() as Entity, {
        turn,
        createdAtBlock: getCurrentBlockNumber(),
        match,
        player: playerEntity,
        structureType: Number(structureType),
        count,
      });
    });
  }

  const layer = {
    world,
    components,
    networkLayer: network,
    utils: {
      getCurrentBlockNumber,
      storePlayerTotalUnitSnapshot,
      storePlayerTotalStructureSnapshot,
    }
  };

  createPreviousOwnerSystem(layer);

  createUnitBuySystem(layer);
  createUnitKillSystem(layer);
  createMatchEventsSystem(layer);
  createTokenBalanceSystem(layer);

  return layer;
}
