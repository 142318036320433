import { useEffect, useState } from "react";
import { Button } from "../ui/Theme/SkyStrife/Button";
import { Caption, OverlineLarge, OverlineSmall } from "../ui/Theme/SkyStrife/Typography";
import { Card } from "../ui/Theme/SkyStrife/Card";
import { useAmalgema } from "../../store";
import { Plus } from "../ui/Theme/SkyStrife/Icons/Plus";
import { useCurrentMatchReward } from "./hooks/useCurrentMatchReward";
import { useTokenBalances } from "./hooks/useTokenBalances";
import { Entity } from "@latticexyz/recs";
import { useComponentValue } from "@latticexyz/react";
import { Mana } from "../ui/Theme/SkyStrife/Mana";
import { stringToHex } from "viem";

export function SummonIslandModal({ close }: { close: () => void }) {
  const {
    network: {
      components: { SkyPoolConfig },
      worldContract,
      playerEntity,
      singletonEntity,
      waitForTransaction,
    },
  } = useAmalgema();

  const matchReward = useCurrentMatchReward();
  const tokenBalances = useTokenBalances(playerEntity ?? ("0" as Entity));
  const skyPoolConfig = useComponentValue(SkyPoolConfig, singletonEntity);
  const skyPoolToken = skyPoolConfig?.token;

  const userSkyPoolTokenBalance = Number(
    tokenBalances?.find((tokenBalance) => tokenBalance.token === skyPoolToken)?.value ?? 0
  );
  const insufficientBalance = userSkyPoolTokenBalance < 150;

  const [summonPending, setSummonPending] = useState(false);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        close();
      }
    };

    document.addEventListener("keydown", listener);

    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  const summonIsland = async () => {
    if (summonPending) return;

    const tx = await worldContract.write.createMatch([stringToHex('FourPlayer', { size: 32 })]);

    setSummonPending(true);

    await waitForTransaction(tx);
    close();
    setSummonPending(false);
  };

  let summonButtonText = "Start Summon";
  if (summonPending) summonButtonText = "Loading...";
  if (insufficientBalance) summonButtonText = "Insufficient Balance";

  return (
    <div
      style={{
        background: "rgba(24, 23, 16, 0.65)",
        zIndex: 100,
      }}
      className="fixed top-0 left-0 w-screen h-screen flex flex-col justify-around"
    >
      <Card primary className="bg-ss-bg-1 flex flex-col items-center justify-center mx-auto p-8 w-[624px]">
        <div className="flex flex-col w-full items-center justify-center">
          <div className="flex w-full justify-between">
            <div>
              <OverlineLarge className="">Summon Island</OverlineLarge>

              <Caption className="text-center">Summon an island for a four player deathmatch.</Caption>
            </div>

            <div className="w-10" />

            <Button buttonType={"tertiary"} onClick={close} className="h-fit">
              Cancel
            </Button>
          </div>

          <div className="h-4" />

          <div className="flex flex-row w-full justify-between">
            <div className="w-1/2">
              <OverlineSmall>Map</OverlineSmall>
              <div
                style={{
                  backgroundImage: "url(/assets/quad-medium-map-preview.png)",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="rounded border border-ss-stroke w-full h-[284px]"
              />
            </div>

            <div className="w-8" />

            <div className="flex flex-col items-center justify-center w-1/2">
              <div className="grow w-full mb-3">
                <OverlineSmall className="w-full text-left">Creation Cost</OverlineSmall>
                <Mana amount={150} />
              </div>

              <div className="grow w-full mb-3">
                <OverlineSmall className="w-full text-left">Your Balance</OverlineSmall>
                <Mana amount={userSkyPoolTokenBalance} />
              </div>

              <div className="grow w-full mb-3">
                <OverlineSmall className="w-full text-left">Reward Pool</OverlineSmall>
                <div className="flex justify-between w-full">
                  <Caption className="underline mb-1">1st place</Caption>
                  <Mana amount={matchReward} />
                </div>

                <div className="flex justify-between w-full">
                  <Caption className="underline mb-1">2nd place</Caption>
                  <Mana amount={matchReward / 2} />
                </div>
              </div>

              <Button
                disabled={insufficientBalance}
                buttonType={"secondary"}
                onClick={() => {
                  summonIsland();
                }}
                className="h-fit w-full"
              >
                {summonButtonText}
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}

export function useSummonIslandModal() {
  const [modalOpen, setModalOpen] = useState(false);

  return {
    modalOpen,
    setModalOpen,
    modal: modalOpen ? <SummonIslandModal close={() => setModalOpen(false)} /> : <></>,
  };
}

export function SummonIsland() {
  const { setModalOpen, modal } = useSummonIslandModal();

  return (
    <>
      {modal}

      <div className="flex flex-row items-center justify-between">
        <div>
          <OverlineLarge>Play</OverlineLarge>
          <Caption>Create and join matches of Sky Strife</Caption>
        </div>

        <Button buttonType={"primary"} size={"lg"} onClick={() => setModalOpen(true)}>
          <div className="flex flex-row items-center justify-center h-fit">
            <Plus /> <div className="w-4" /> <span>Summon Island</span>
          </div>
        </Button>
      </div>
    </>
  );
}
