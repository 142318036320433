import { LocalLayer } from "../../Local";
import {
  createMapSystem,
  createLocalPositionSystem,
  createSetVisualsSystem,
  createAppearanceSystem,
  createSpriteAnimationSystem,
  createOutlineSystem,
  createHueTintSystem,
  createSelectionSystem,
  createDrawDevHighlightSystem,
  createInputSystem,
  createDrawHighlightCoordSystem,
  createDrawPotentialPathSystem,
  createDrawNextPositionSystem,
  createPlayerSpawnSystem,
  createDrawEntityHeader,
  createDrawActingEntitySystem,
  createDrawAttackableEntitiesSystem,
} from "./systems";
import { createPhaserEngine, HueTintAndOutlineFXPipeline, tileCoordToPixelCoord } from "@latticexyz/phaserx";
import { createPhaserComponents } from "./components";
import { gameConfig } from "./gameConfig";
import {
  ComponentUpdate,
  defineSystem,
  Entity,
  getComponentValue,
  getComponentValueStrict,
  namespaceWorld,
  QueryFragment,
  UpdateType,
} from "@latticexyz/recs";
import { highlightCoord } from "./api";
import { curry } from "lodash";
import { Coord } from "@latticexyz/utils";
import { createConstrainCameraSystem } from "./systems/ConstrainCameraSystem";
import { createCycleUsableUnitsSystem } from "./systems/CycleUsableUnitsSystem";
import { Animations, Scenes } from "./phaserConstants";
import { createCombatSystem } from "./systems/CombatSystem";
import { createAnimations } from "./animations";
import { createScreenFlashes } from "./systems/ScreenFlashes";
import { observable } from "mobx";
import { createSmoothCameraControls } from "./camera";
import { RenderDepth } from "./types";
import { createMusicSystem } from "./systems/MusicSystem";
import { createPreferencesSystem } from "./systems/PreferencesSystem";
import { createEmberCrownShrineSystem } from "./systems/EmberCrownShrineSystem";
import { createDrawShadowSystem } from "./systems/DrawShadowSystem";
import { createSounds } from "./createSounds";
import { createTintNoStaminaSystem } from "./systems/TintNoStaminaSystem";
import { createHideBlackBoxSystem } from "./systems/HideBlackBoxSystem";
import { createCaptureAnimationSystem } from "./systems/CaptureAnimationSystem";
import { EmbodiedEntity, GameObjectTypes } from "@latticexyz/phaserx/src/types";
import { createCalculateCombatResultSystem } from "./systems/CalculateCombatResultSystem";

type PhaserEngineConfig = Parameters<typeof createPhaserEngine>[0];

/**
 * The Phaser layer extends the Local layer.
 * Its purpose is to render the state of parent layers to a Phaser world.
 */
export async function createPhaserLayer(local: LocalLayer, phaserConfig: PhaserEngineConfig = gameConfig) {
  const {
    api: { selectEntity, getOwnerColor },
    components: { LocalPosition },
  } = local;

  // World
  const world = namespaceWorld(local.parentLayers.network.network.world, "phaser");

  const components = createPhaserComponents(world);

  // Create phaser engine
  const { game, scenes, dispose: disposePhaser } = await createPhaserEngine(phaserConfig);
  game.canvas.hidden = true;
  world.registerDisposer(disposePhaser);

  const sounds = await createSounds(scenes.Main.phaserScene);
  sounds["field-battle"].play();
  game.canvas.hidden = false;

  scenes.Main.camera.setZoom(2);

  game.scene.start(Scenes.UI);
  // Disable zoom on UI
  scenes.UI.camera.zoom$.subscribe(() => {
    scenes.UI.camera.phaserCamera.setZoom(1);
  });

  function selectAndView(entity: Entity) {
    const position = getComponentValue(LocalPosition, entity);
    if (!position) return;

    const {
      Main: {
        camera,
        maps: {
          Main: { tileHeight, tileWidth },
        },
      },
    } = scenes;

    const pixelPosition = tileCoordToPixelCoord(position, tileWidth, tileHeight);
    camera.phaserCamera.pan(pixelPosition.x, pixelPosition.y, 350, Phaser.Math.Easing.Cubic.InOut);
    // Refresh the camera view right before we pan over so there is no visual stutter
    setTimeout(() => {
      camera.setScroll(camera.phaserCamera.scrollX, camera.phaserCamera.scrollY);
      camera.setZoom(camera.phaserCamera.zoom);
    }, 250);

    selectEntity(entity);
  }

  function drawTileHighlight(id: string, position: Coord, color: number) {
    const {
      objectPool,
      maps: {
        Main: { tileHeight, tileWidth },
      },
    } = scenes.Main;

    const object = objectPool.get(id, "Sprite");
    object.setComponent({
      id: `tile-highlight`,
      once: async (box) => {
        const pixelCoord = tileCoordToPixelCoord(position, tileWidth, tileHeight);
        box.play(Animations.TileOutline);
        box.setOrigin(0, 0);
        box.setPosition(pixelCoord.x, pixelCoord.y);
        box.setDepth(RenderDepth.Background1);

        tintObject(box, color);
      },
    });
  }

  function createMapInteractionApi() {
    let enabled = true;

    return {
      disableMapInteraction: () => (enabled = false),
      enableMapInteraction: () => (enabled = true),
      mapInteractionEnabled: () => {
        return enabled;
      },
    };
  }

  function tintObject(sprite: Phaser.GameObjects.Sprite, color: number) {
    sprite.setPipeline(HueTintAndOutlineFXPipeline.KEY);
    sprite.setPipelineData("hueTint", color);
  }

  const LARGE_SCREEN_CUTOFF = 1200;
  function isLargeScreen() {
    return window.innerWidth > LARGE_SCREEN_CUTOFF;
  }

  const uiState = observable({
    hideLoading: false,
    map: {
      fullscreen: false,
    },
  });

  let idSequence = 0;
  const uniqueId = (id: string) => {
    return `${id}-${idSequence++}`;
  };

  const defineGameObjectSystem = <Type extends keyof GameObjectTypes>(
    numGameObjects: number,
    gameObjectType: Type,
    query: QueryFragment[],
    system: (update: ComponentUpdate & { type: UpdateType }, gameObjects: EmbodiedEntity<Type>[]) => void,
    idPrefix?: string
  ) => {
    const _idPrefix = idPrefix ?? uniqueId("game-object");

    defineSystem(world, query, (update) => {
      const { type, entity } = update;

      if (type === UpdateType.Exit) {
        for (let i = 0; i < numGameObjects; i++) {
          scenes.Main.objectPool.remove(`${_idPrefix}-${entity}-${i}`);
        }
        return;
      }

      const gameObjects = [] as EmbodiedEntity<Type>[];
      for (let i = 0; i < numGameObjects; i++) {
        const gameObject = scenes.Main.objectPool.get<Type>(`${_idPrefix}-${entity}-${i}`, gameObjectType);
        gameObjects.push(gameObject as unknown as EmbodiedEntity<Type>);
      }

      system(update, gameObjects);
    });
  };

  const setOriginCenter = (gameObject: Phaser.GameObjects.Sprite) => {
    const animationSize = gameObject.width * gameObject.scaleX;
    const newOrigin = (animationSize - scenes.Main.maps.Main.tileWidth) / (2 * animationSize);
    gameObject.setOrigin(newOrigin);
  };

  function getEntityPixelCoord(entity: Entity) {
    const { tileWidth, tileHeight } = scenes.Main.maps.Main;

    const position = getComponentValueStrict(LocalPosition, entity);
    return tileCoordToPixelCoord(position, tileWidth, tileHeight);
  }

  function tintWithOwnerColor(entity: Entity, gameObject: Phaser.GameObjects.Sprite) {
    tintObject(gameObject, getOwnerColor(entity));
  }

  // Layer
  const layer = {
    world,
    components,
    sounds,
    parentLayers: {
      ...local.parentLayers,
      local,
    },
    game,
    scenes,
    api: {
      selectAndView,
      drawTileHighlight,
      mapInteraction: createMapInteractionApi(),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      highlightCoord: (_coord: Coord, _color: number) => {
        "no-op for types";
      },
      tintObject,
      tintWithOwnerColor,
      setOriginCenter,
      getEntityPixelCoord,
    },
    animations: createAnimations(scenes, local),
    ui: {
      isLargeScreen,
    },
    uiState,
    defineGameObjectSystem,
  };
  layer.api.highlightCoord = curry(highlightCoord)(layer);

  // Systems
  createSetVisualsSystem(layer);
  createMapSystem(layer);
  createLocalPositionSystem(layer);
  createAppearanceSystem(layer);
  createSpriteAnimationSystem(layer);
  createOutlineSystem(layer);
  createHueTintSystem(layer);
  createSelectionSystem(layer);
  createDrawDevHighlightSystem(layer);
  createInputSystem(layer);
  createDrawHighlightCoordSystem(layer);
  createDrawPotentialPathSystem(layer);
  createDrawNextPositionSystem(layer);
  createPlayerSpawnSystem(layer);
  createDrawEntityHeader(layer);
  createDrawActingEntitySystem(layer);
  createDrawAttackableEntitiesSystem(layer);
  createConstrainCameraSystem(layer);
  createCycleUsableUnitsSystem(layer);
  createCalculateCombatResultSystem(layer);
  createCombatSystem(layer);
  createScreenFlashes(layer);
  createMusicSystem(layer);
  createSmoothCameraControls(layer, gameConfig);
  createPreferencesSystem(layer);
  createEmberCrownShrineSystem(layer);
  createDrawShadowSystem(layer);
  createTintNoStaminaSystem(layer);
  createCaptureAnimationSystem(layer);

  createHideBlackBoxSystem(layer);

  return layer;
}
