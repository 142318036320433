import { Entity, Has, defineSystem, getComponentValueStrict, setComponent } from "@latticexyz/recs";
import { AnalyticsLayer } from "../types";

export function createTokenBalanceSystem(layer: AnalyticsLayer) {
  const {
    world,
    networkLayer: {
      components: {
        TokenBalance,
      },
    },
    components: {
      TokenBalanceSnapshot,
    },
    utils: {
      getCurrentBlockNumber,
    },
  } = layer;

  defineSystem(world, [Has(TokenBalance)], ({ entity }) => {
    const tokenBalance = getComponentValueStrict(TokenBalance, entity).value;
    const [tokenEntity, address] = entity.split(":");

    setComponent(TokenBalanceSnapshot, `${tokenEntity}:${address}:${getCurrentBlockNumber()}` as Entity, {
      balance: Number(tokenBalance),
      mainWalletAddress: address as Entity,
      token: tokenEntity as Entity,
      createdAtBlock: getCurrentBlockNumber(),
    });
  });
}