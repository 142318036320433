import { chunk } from "lodash";
import { NetworkLayer } from "../../../layers/Network";
import { callWithRetry } from "@latticexyz/utils";
import { stringToHex } from "viem";

export type Level = Array<{
  templateId: string;
  values: object;
}>;

const STATE_UPDATES_PER_TX = 25;

export async function bulkUploadMap(layer: NetworkLayer, map: Level, name: string) {
  const chunkedState = chunk(map, STATE_UPDATES_PER_TX);
  for (const stateChunk of chunkedState) {
    const levelId = stringToHex(name, { size: 32 });
    const templateIds = stateChunk.map((s) => stringToHex(s.templateId, { size: 32 }));
    const positions = stateChunk.map((s) => Object.values(s.values)[0]);

    await callWithRetry(layer.network.worldContract.write.uploadMap, [[levelId, templateIds, positions]], 5, 1000);
  }
}
