import { useAnalyticsStore } from "../../analyticsStore";
import { Heading } from "../ui/Theme/SkyStrife/Typography";
import { MatchEventsDownloader } from "./MatchEventsDownloader";
import { Matches } from "./Matches";
import { GlobalEventsDownloader } from "./GlobalEventsDownloader";

export const AnalyticsUIRoot = () => {
  const layers = useAnalyticsStore((state) => {
    return {
      networkLayer: state.networkLayer,
      analyticsLayer: state.analyticsLayer,
    };
  });

  if (!layers.networkLayer || !layers.analyticsLayer) return <></>;

  return (
    <div className="bg-ss-bg-1 p-8">
      <Heading>Analytics Dashboard</Heading>

      <div className="h-8" />

      <div className="flex flex-row">
        <MatchEventsDownloader />
        <GlobalEventsDownloader />
      </div>

      <div className="h-8" />

      <Matches />
    </div>
  );
};
