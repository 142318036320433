import CsvDownloader from "react-csv-downloader";
import { Card } from "../ui/Theme/SkyStrife/Card";
import { useState } from "react";
import { useEntityQuery } from "@latticexyz/react";
import { Component, Has, getComponentValueStrict, runQuery } from "@latticexyz/recs";
import { useAnalytics } from "../../analyticsStore";
import { filter, uniq } from "lodash";
import { Heading } from "../ui/Theme/SkyStrife/Typography";
import { Button } from "../ui/Theme/SkyStrife/Button";
import { Type } from "@latticexyz/recs";

export function MatchEventsDownloader() {
  const {
    networkLayer: {
      components: { Match },
    },
    analyticsLayer: {
      components: { UnitKill, UnitDeath, UnitBuy, StructureCapture, StructureKill, UnitSnapshot, StructureSnapshot },
    },
  } = useAnalytics();

  const [matchId, setMatchId] = useState<string>("-1");

  const allMatchIds = uniq(useEntityQuery([Has(Match)]).map((e) => getComponentValueStrict(Match, e).value));

  const constructMatchScopedData = async (eventComponent: Component<{ match: Type.Number }>) => {
    const data = filter(
      [...runQuery([Has(eventComponent)])].map((e) => getComponentValueStrict(eventComponent, e)),
      (event) => Number(matchId) === -1 || event.match === Number(matchId)
    );
    return data as any[];
  };

  return (
    <Card>
      <Heading>Download Match Stats</Heading>

      <div className="h-4" />

      <select value={matchId} onChange={(e) => setMatchId(e.target.value)}>
        <option value="-1">All matches</option>
        {allMatchIds.map((id) => (
          <option value={id}>{id}</option>
        ))}
      </select>

      <div className="h-4" />

      <div className="flex flex-col items-center">
        <CsvDownloader
          datas={() => constructMatchScopedData(UnitKill)}
          filename={`${new Date().toISOString()}-${matchId === "-1" ? 'all-matches' : `match-${matchId}`}-unit-kills`}
        >
          <Button buttonType="primary">
            Download Unit Kills
          </Button>
        </CsvDownloader>

        <div className="h-2" />

        <CsvDownloader
          datas={() => constructMatchScopedData(UnitDeath)}
          filename={`${new Date().toISOString()}-${matchId === "-1" ? 'all-matches' : `match-${matchId}`}-unit-deaths`}
        >
          <Button buttonType="primary">
            Download Unit Deaths
          </Button>
        </CsvDownloader>

        <div className="h-2" />

        <CsvDownloader
          datas={() => constructMatchScopedData(UnitBuy)}
          filename={`${new Date().toISOString()}-${matchId === "-1" ? 'all-matches' : `match-${matchId}`}-unit-buys`}
        >
          <Button buttonType="primary">
            Download Unit Buys
          </Button>
        </CsvDownloader>

        <div className="h-2" />

        <CsvDownloader
          datas={() => constructMatchScopedData(StructureCapture)}
          filename={`${new Date().toISOString()}-${matchId === "-1" ? 'all-matches' : `match-${matchId}`}-structure-captures`}
        >
          <Button buttonType="primary">
            Download Structure Captures
          </Button>
        </CsvDownloader>

        <div className="h-2" />

        <CsvDownloader
          datas={() => constructMatchScopedData(StructureKill)}
          filename={`${new Date().toISOString()}-${matchId === "-1" ? 'all-matches' : `match-${matchId}`}-structure-kills`}
        >
          <Button buttonType="primary">
            Download Structure Kills
          </Button>
        </CsvDownloader>

        <div className="h-2" />

        <CsvDownloader
          datas={() => constructMatchScopedData(UnitSnapshot)}
          filename={`${new Date().toISOString()}-${matchId === "-1" ? 'all-matches' : `match-${matchId}`}-unit-snapshots`}
        >
          <Button buttonType="primary">
            Download Unit Snapshots
          </Button>
        </CsvDownloader>

        <div className="h-2" />

        <CsvDownloader
          datas={() => constructMatchScopedData(StructureSnapshot)}
          filename={`${new Date().toISOString()}-${matchId === "-1" ? 'all-matches' : `match-${matchId}`}-structure-snapshots`}
        >
          <Button buttonType="primary">
            Download Structure Snapshots
          </Button>
        </CsvDownloader>
      </div>
    </Card>
  );
}
