import { useEffect, useState } from "react";
import { useMUD } from "../../../store";

export function useSyncStatus() {
  const {
    networkLayer: {
      network: {
        publicClient,
        clock,
      },
    },
  } = useMUD();

  const [latestBlockNumber, setLatestBlockNumber] = useState(0);
  const [lastBlockReceivedAt, setLastBlockReceivedAt] = useState(0);

  useEffect(() => {
    const dispose = publicClient.watchBlockNumber({
      onBlockNumber: (blockNumber) => {
        setLatestBlockNumber(Number(blockNumber));
        setLastBlockReceivedAt(clock.currentTime);
      },
    });

    return () => {
      dispose();
    }
  }, []);

  return {
    latestBlockNumber,
    lastBlockReceivedAt,
  }
}