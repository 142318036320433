import { Mana } from "../ui/Theme/SkyStrife/Mana";
import { Caption } from "../ui/Theme/SkyStrife/Typography";
import { useMatchRewards } from "./hooks/useMatchRewards";

/**
 * Designed to be rendered at the bottom of a Card component.
 */
export function MatchRewardsFooter({ matchId }: { matchId: number }) {
  const [firstPlaceReward, secondPlaceReward] = useMatchRewards(matchId);

  return (
    <div
      style={{
        marginLeft: "-24px",
        marginBottom: "-16px",
        width: "calc(100% + 48px)",
        borderRadius: "0 0 8px 8px",
      }}
      className="bg-ss-bg-0 border-t border-ss-stroke px-6 py-4"
    >
      <div className="flex flex-row justify-between items-center">
        <Caption>1st place</Caption>
        <Mana amount={Number(firstPlaceReward.value) || 0} />
      </div>

      <div className="flex flex-row justify-between items-center">
        <Caption>2nd place</Caption>
        <Mana amount={Number(secondPlaceReward.value) || 0} />
      </div>
    </div>
  );
}
