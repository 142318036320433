import { Entity, Has, defineEnterSystem, getComponentValue, getComponentValueStrict, setComponent } from "@latticexyz/recs";
import { AnalyticsLayer } from "..";
import { uuid } from "@latticexyz/utils";
import { parseBytes32String } from "ethers/lib/utils";

export function createMatchEventsSystem(layer: AnalyticsLayer) {
  const {
    world,
    networkLayer: {
      network: {
        components: { MatchConfig, Match, Player, MatchFinished, MatchRanking, Recipient },
      }
    },
    components: { CreateMatch, JoinMatch, EndMatch },
    utils: { getCurrentBlockNumber },
  } = layer;

  defineEnterSystem(world, [Has(MatchConfig), Has(Match)], ({ entity }) => {
    const matchConfig = getComponentValueStrict(MatchConfig, entity);
    const matchId = getComponentValueStrict(Match, entity).value;

    const createMatchEventEntity = uuid() as Entity;
    setComponent(CreateMatch, createMatchEventEntity, {
      createdAtBlock: getCurrentBlockNumber(),
      mainWalletAddress: matchConfig.createdBy as Entity,
      map: parseBytes32String(matchConfig.levelId) as Entity,
      match: matchId,
    });
  });

  defineEnterSystem(world, [Has(Player), Has(Recipient), Has(Match)], ({ entity }) => {
    const matchId = getComponentValueStrict(Match, entity).value;
    const mainWallet = getComponentValue(Recipient, entity as Entity)?.value ?? "";

    const joinMatchEventEntity = uuid() as Entity;
    setComponent(JoinMatch, joinMatchEventEntity, {
      mainWalletAddress: mainWallet,
      createdAtBlock: getCurrentBlockNumber(),
      player: entity,
      match: matchId,
    });
  });

  defineEnterSystem(world, [Has(MatchFinished), Has(Match), Has(MatchRanking)], ({ entity }) => {
    const matchId = getComponentValueStrict(Match, entity).value;
    const matchRanking = getComponentValueStrict(MatchRanking, entity).value;
    const winner = matchRanking[0];

    const endMatchEventEntity = uuid() as Entity;
    setComponent(EndMatch, endMatchEventEntity, {
      createdAtBlock: getCurrentBlockNumber(),
      player: winner as Entity,
      match: matchId,
      playerRanking: matchRanking.map((player) => player as Entity),
    });
  });
}
