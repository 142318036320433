import { useComponentValue, useEntityQuery } from "@latticexyz/react";
import { Card } from "../ui/Theme/SkyStrife/Card";
import { Heading } from "../ui/Theme/SkyStrife/Typography";
import { useAmalgema } from "../../store";
import { Entity, Has, HasValue, getComponentValueStrict } from "@latticexyz/recs";
import { Button } from "../ui/Theme/SkyStrife/Button";
import { MatchRewardsFooter } from "./MatchRewardsFooter";
import { useState } from "react";

export function PendingMatch({ matchId }: { matchId: number }) {
  const {
    components: { MapSize, MatchMapCopyProgress, MatchConfig, Match },
    network: { worldContract, waitForTransaction },
  } = useAmalgema();

  const [copyPending, setCopyPending] = useState(false);

  const match = useEntityQuery([HasValue(Match, { value: matchId }), Has(MatchConfig)])[0];
  const progress = useComponentValue(MatchMapCopyProgress, match);
  const { levelId } = getComponentValueStrict(MatchConfig, match);
  const size = useComponentValue(MapSize, levelId as Entity);

  const fraction = progress && size ? Math.round(Number(progress.value * 100n) / Number(size.value)) : 0;

  return (
    <Card primary>
      <div className="flex justify-between">
        <Heading>Summoning... </Heading>
        <span className="text-ss-text-x-light">#{matchId}</span>
      </div>

      <div className="h-3"></div>

      <div className="relative w-full rounded border border-ss-stroke text-center flex flex-col justify-around py-2 overflow-hidden h-[24px]">
        <div
          style={{
            width: `${fraction}%`,
            height: "100%",
            margin: "-8px 0",
            zIndex: 0,
          }}
          className="bg-ss-blue absolute"
        />

        <div className=" h-[24px] absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center z-100">
          <span className="text-ss-text-x-light">{fraction}%</span>
        </div>
      </div>

      <div className="h-6"></div>

      <Button
        buttonType={"secondary"}
        onClick={async () => {
          setCopyPending(true);
          const tx = await worldContract.write.copyMap([matchId]);
          await waitForTransaction(tx);
          setCopyPending(false);
        }}
        className="w-full"
      >
        {copyPending ? "Summoning..." : "assist the summon"}
      </Button>

      <div className="h-[68px]"></div>

      <MatchRewardsFooter matchId={matchId} />
    </Card>
  );
}
