import { createPhaserEngine, tileCoordToPixelCoord } from "@latticexyz/phaserx";
import { Entity, getComponentValue } from "@latticexyz/recs";
import { Coord } from "@latticexyz/utils";
import { LocalLayer } from "../../../Local";
import { Animations, Sprites, UnitTypeDeathAnimations } from "../phaserConstants";
import { RenderDepth } from "../types";

type Scenes = Awaited<ReturnType<typeof createPhaserEngine>>["scenes"];

function createTriggerBloodSplatter(scenes: Scenes) {
  const {
    Main: {
      config,
      phaserScene,
      maps: {
        Main: { tileHeight, tileWidth },
      },
    },
  } = scenes;

  const bloodSplatterSprite = config.sprites[Sprites.OrangeTick];
  const particleManager = phaserScene.add.particles(bloodSplatterSprite.assetKey, bloodSplatterSprite.frame);
  particleManager.setDepth(RenderDepth.Foreground1);

  return (coord: Coord) => {
    const emitter = particleManager.createEmitter({
      scale: {
        start: 1.5,
        end: 0.3,
      },
      speed: {
        min: -200,
        max: 200,
      },
      gravityY: 400,
      lifespan: 200,
      blendMode: Phaser.BlendModes.SCREEN,
      deathCallback: () => particleManager.removeEmitter(emitter),
    });

    const pos = tileCoordToPixelCoord(coord, tileWidth, tileHeight);
    emitter.setBounds(pos.x - 6, pos.y, tileWidth + 12, tileHeight);
    emitter.explode(8, pos.x + tileWidth / 2, pos.y + tileHeight / 2);
  };
}

function createFlashRed(scenes: Scenes) {
  const {
    Main: { objectPool },
  } = scenes;

  return (entity: Entity) => {
    const embodiedObject = objectPool.get(entity, "Sprite");

    embodiedObject.setComponent({
      id: "flash-red",
      now: (sprite) => {
        const previousTint = sprite.tint;
        sprite.setTint(0xb00b1e);

        setTimeout(() => sprite.setTint(previousTint), 125);
      },
    });
  };
}

function createPlayDeathAnimation(scenes: Scenes, localLayer: LocalLayer) {
  const { UnitType } = localLayer.parentLayers.network.components;

  return function (entity: Entity, onDeath: () => void) {
    const unitType = getComponentValue(UnitType, entity)?.value;
    if (!unitType) {
      onDeath();
      return;
    }

    const deathAnimation = UnitTypeDeathAnimations[unitType];

    const embodiedObject = scenes.Main.objectPool.get(entity, "Sprite");
    embodiedObject.setComponent({
      id: "death-animation",
      now: (sprite) => {
        sprite.play(deathAnimation);
        sprite.on(`animationcomplete-${deathAnimation}`, () => {
          onDeath();
        });
      },
    });
  };
}

function createStartTeleportAnimation(scenes: Scenes, localLayer: LocalLayer) {
  return function startTeleportAnimation(unit: Entity, onEnd?: () => void) {
    const {
      Main: { config, phaserScene: scene, objectPool },
    } = scenes;

    const {
      api: { getOwnerColor },
    } = localLayer;

    const ownerColor = getOwnerColor(unit);

    const spriteObject = objectPool.get(unit, "Sprite");
    spriteObject.setComponent({
      id: "teleport",
      once: (sprite) => {
        const box = scenes.Main.phaserScene.add
          .rectangle(
            sprite.x + sprite.width / 2,
            sprite.y + sprite.height / 2,
            sprite.width,
            sprite.height,
            ownerColor,
            1
          )
          .setDepth(RenderDepth.Foreground1)
          .setVisible(false);
        sprite.setMask(box.createGeometryMask());
        sprite.stop();
        sprite.setTint(ownerColor);

        const particles = scene.add.sprite(
          sprite.x + sprite.width / 2,
          sprite.y + sprite.height / 2,
          config.sprites[0].assetKey
        );
        particles.play(Animations.Teleport);
        particles.setTint(ownerColor);

        scene.tweens.add({
          targets: box,
          height: 0,
          y: box.y + box.height,
          duration: 1000,
          ease: "Linear",
          repeat: 0,
          onComplete: () => {
            box.destroy();
            particles.destroy();
            sprite.clearMask();

            if (onEnd) onEnd();
          },
        });
      },
    });
  };
}

export function createAnimations(scenes: Scenes, localLayer: LocalLayer) {
  return {
    flashRed: createFlashRed(scenes),
    playDeathAnimation: createPlayDeathAnimation(scenes, localLayer),
    triggerBloodSplatter: createTriggerBloodSplatter(scenes),
    startTeleportAnimation: createStartTeleportAnimation(scenes, localLayer),
  };
}
