import { useEntityQuery } from "@latticexyz/react";
import { Entity, Has, getComponentValueStrict } from "@latticexyz/recs";
import { useState } from "react";
import { useMUD } from "../../../store";
import { Button } from "../Theme/SkyStrife/Button";
import { Hex, hexToString } from "viem";

const STORES_PER_TX = 25n;

export const MapSpawner = () => {
  const {
    networkLayer: {
      network: { worldContract },
      components: { MapSize },
    },
  } = useMUD();

  const [spawning, setSpawning] = useState<Entity | undefined>();

  const allMaps = useEntityQuery([Has(MapSize)]);
  const names = [...new Set(allMaps)].map((id) => {
    return { id, name: hexToString(id as Hex, { size: 32 }) };
  });

  return (
    <div className="flex flex-wrap">
      <div className="flex flex-col">
        <select value={spawning} onChange={(e) => setSpawning(e.target.value as Entity)} className="mb-4">
          <option value={undefined}>Select a map</option>
          {names.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </select>

        <Button
          buttonType={"primary"}
          onClick={async () => {
            if (!spawning) return;

            const size = getComponentValueStrict(MapSize, spawning).value;
            const chunkCount = size / STORES_PER_TX;

            for (let i = 0; i < chunkCount; i++) {
              await worldContract.write.spawnLevelDev([spawning as Hex, STORES_PER_TX, STORES_PER_TX * BigInt(i)]);
            }
          }}
        >
          Spawn a map
        </Button>
      </div>
    </div>
  );
};
