import { defineRxSystem } from "@latticexyz/recs";
import { PhaserLayer } from "../../types";

export function createScreenFlashes(layer: PhaserLayer) {
  const {
    world,
    scenes: {
      Main: { camera },
    },
    parentLayers: {
      headless: { turn$ },
      local: {
        api: { onEmberCrownOwnerChanged, onEmberCrownShrineOwnerChanged },
      },
    },
  } = layer;

  defineRxSystem(world, turn$, () => {
    camera.phaserCamera.flashEffect.alpha = 0.3;
    camera.phaserCamera.flash(500);
  });

  onEmberCrownOwnerChanged(() => {
    camera.phaserCamera.flash(500, 255, 0, 0);
  });

  onEmberCrownShrineOwnerChanged(() => {
    camera.phaserCamera.flash(500, 255, 0, 0);
  });
}
