import {
  defineComponent,
  Type,
  namespaceWorld,
  getComponentValue,
  hasComponent,
  runQuery,
  Has,
  HasValue,
  Not,
  getComponentValueStrict,
  Component,
  Metadata,
  SchemaOf,
  Entity,
} from "@latticexyz/recs";
import { attack, buildAt } from "./api";
import { calculateCombatResult, isPassive, isNeutralStructure, canRetaliate } from "./utils";

import { NetworkLayer, StructureTypes } from "../Network";
import { createCurrentStaminaSystem, createScopeClientToMatchSystem } from "./systems";
import { curry } from "lodash";
import { createTurnStream } from "./setup";
import { getClosestTraversablePositionToTarget, manhattan } from "../../utils/distance";
import { createActionSystem, defineBoolComponent } from "@latticexyz/std-client";
import { WorldCoord } from "../../types";
import { createCooldownSystem } from "./systems/CooldownSystem";
import { aStar } from "../../utils/pathfinding";
import { Coord } from "@latticexyz/phaserx";
import { BigNumber } from "ethers";
import { from, mergeMap } from "rxjs";

/**
 * The Headless layer is the second layer in the client architecture and extends the Network layer.
 * Its purpose is to provide an API that allows the game to be played programatically.
 */

export async function createHeadlessLayer(network: NetworkLayer) {
  const world = namespaceWorld(network.network.world, "headless");
  const {
    utils: { getOwningPlayer, isOwnedBy },
    api: { getCurrentMatchConfig },
    network: { match, clock, blockStorageOperations$, components: {
      Combat,
      Movable,
      MoveDifficulty,
      OwnedBy,
      Position,
      Range,
      Stamina,
      StructureType,
      TerrainType,
      UnitType,
      Untraversable,
    }, },
  } = network;

  const LocalStamina = defineComponent(world, { current: Type.Number }, { id: "LocalStamina" });
  const NextPosition = defineComponent(world, { x: Type.Number, y: Type.Number }, { id: "NextPosition" });
  const OnCooldown = defineComponent(world, { value: Type.Boolean }, { id: "OnCooldown" });
  const Depleted = defineBoolComponent(world, { id: "Depleted" });
  const InCurrentMatch = defineBoolComponent(world, { id: "InCurrentMatch" });
  const components = { LocalStamina, OnCooldown, NextPosition, Depleted, InCurrentMatch };

  const transactionHash$ = blockStorageOperations$.pipe(
    mergeMap(({ operations }) => from(new Set(operations.map((op) => op.log.transactionHash))))
  );

  const actions = createActionSystem(world, transactionHash$);

  const turn$ = createTurnStream(() => {
    const matchConfig = getCurrentMatchConfig();
    if (!matchConfig) return undefined;

    return {
      startTime: BigNumber.from(matchConfig.startTime),
      turnLength: BigNumber.from(matchConfig.turnLength),
    };
  }, clock);

  const getCurrentStamina = (entity: Entity) => {
    const OptimisticStamina = actions.withOptimisticUpdates(Stamina);

    const contractStamina = getComponentValue(OptimisticStamina, entity)?.current;
    if (contractStamina == undefined) return 0;

    const localStamina = getComponentValue(LocalStamina, entity)?.current;
    if (localStamina == undefined) return 0;

    return contractStamina + localStamina;
  };

  const getActionStaminaCost = (_entity: Entity) => {
    return 1_000;
  };

  const isUntraversable = (
    positionComponent: Component<{ x: Type.Number; y: Type.Number }>,
    playerEntity: Entity,
    isFinalPosition: boolean,
    position: WorldCoord
  ) => {
    const blockingEntities = runQuery([HasValue(positionComponent, position), Has(Untraversable), Has(InCurrentMatch)]);

    const foundBlockingEntity = blockingEntities.size > 0;
    if (!foundBlockingEntity) return false;
    if (isFinalPosition) return true;

    const blockingEntity = [...blockingEntities][0];

    if (hasComponent(StructureType, blockingEntity)) {
      return getComponentValueStrict(StructureType, blockingEntity).value !== StructureTypes.Container;
    }

    if (!isOwnedBy(blockingEntity, playerEntity)) return true;

    return false;
  };

  const getMovementDifficulty = (
    positionComponent: Component<{ x: Type.Number; y: Type.Number }>,
    targetPosition: WorldCoord
  ) => {
    const entity = [...runQuery([HasValue(positionComponent, targetPosition), Has(MoveDifficulty)])][0];
    if (entity == null) return Infinity;

    return getComponentValueStrict(MoveDifficulty, entity).value;
  };

  function unitSort(a: Entity, b: Entity) {
    const aOutOfStamina = getCurrentStamina(a) < 1000;
    const bOutOfStamina = getCurrentStamina(b) < 1000;

    if (aOutOfStamina && !bOutOfStamina) return 1;
    if (bOutOfStamina && !aOutOfStamina) return -1;

    const aUnitType = getComponentValue(UnitType, a)?.value;
    const bUnitType = getComponentValue(UnitType, b)?.value;

    if (aUnitType && bUnitType && aUnitType !== bUnitType) {
      return bUnitType - aUnitType;
    }

    const aStructureType = getComponentValue(StructureType, a)?.value;
    const bStructureType = getComponentValue(StructureType, b)?.value;

    if (aStructureType && bStructureType && aStructureType !== bStructureType) {
      return bStructureType - aStructureType;
    }

    if (aUnitType && bStructureType) {
      return -1;
    }

    if (aStructureType && bUnitType) {
      return 1;
    }

    return 0;
  }

  const canAttack = (attacker: Entity, defender: Entity) => {
    const stamina = getCurrentStamina(attacker);
    if (stamina < getActionStaminaCost(attacker)) return false;

    const OptimisticPosition = actions.withOptimisticUpdates(Position);

    const attackerOwner = getComponentValue(OwnedBy, attacker);
    const defenderOwner = getComponentValue(OwnedBy, defender);

    if (!attackerOwner) return false;
    if (attackerOwner.value === defenderOwner?.value) return false;

    const combat = getComponentValue(Combat, defender);
    if (!combat) return false;

    const attackerPosition = getComponentValue(NextPosition, attacker)
      ? getComponentValue(NextPosition, attacker)
      : getComponentValue(OptimisticPosition, attacker);
    if (!attackerPosition) return;

    const defenderPosition = getComponentValue(OptimisticPosition, defender);
    if (!defenderPosition) return;

    const distanceToTarget = manhattan(attackerPosition, defenderPosition);

    const attackerRange = getComponentValue(Range, attacker);
    if (attackerRange && (distanceToTarget > attackerRange.max || distanceToTarget < attackerRange.min)) return false;

    return true;
  };

  function getEntitiesInRange(from: WorldCoord, minRange: number, maxRange: number) {
    const entities = [];
    for (let y = from.y - maxRange; y <= from.y + maxRange; y++) {
      for (let x = from.x - maxRange; x <= from.x + maxRange; x++) {
        const distanceTo = manhattan(from, { x, y });
        if (distanceTo >= minRange && distanceTo <= maxRange) {
          const entity = [...runQuery([HasValue(Position, { x: x, y: y, z: match }), Not(TerrainType)])][0];
          if (entity) entities.push(entity);
        }
      }
    }
    return entities;
  }

  const getAttackableEntities = (attacker: Entity) => {
    const attackerOwner = getComponentValue(OwnedBy, attacker);
    if (!attackerOwner) return false;

    const OptimisticPosition = actions.withOptimisticUpdates(Position);

    const attackerPosition = getComponentValue(NextPosition, attacker)
      ? getComponentValue(NextPosition, attacker)
      : getComponentValue(OptimisticPosition, attacker);
    if (!attackerPosition) return;

    const attackerRange = getComponentValue(Range, attacker);
    let entities;
    if (attackerRange) {
      entities = getEntitiesInRange(attackerPosition, attackerRange.min, attackerRange.max);
    } else {
      entities = getEntitiesInRange(attackerPosition, 1, 1);
    }

    const attackableEntities: Entity[] = [];
    for (const defender of entities) {
      const combat = getComponentValue(Combat, defender);
      if (!combat) continue;

      const defenderOwner = getComponentValue(OwnedBy, defender);
      if (attackerOwner.value === defenderOwner?.value) continue;

      attackableEntities.push(defender);
    }
    return attackableEntities;
  };

  const getMoveSpeed = (entity: Entity) => {
    let moveSpeed = getComponentValue(Movable, entity)?.value;
    if (!moveSpeed) return;

    return moveSpeed;
  };

  const calculateMovementPath = (
    positionComponent: Component<{ x: Type.Number; y: Type.Number }>,
    entity: Entity,
    pos1: Coord,
    pos2: Coord
  ) => {
    const player = getOwningPlayer(entity);
    const moveSpeed = getMoveSpeed(entity);

    if (!player || !moveSpeed) return [];

    return aStar(
      pos1,
      pos2,
      moveSpeed / 1_000,
      (targetPosition: WorldCoord) => {
        return getMovementDifficulty(positionComponent, targetPosition) / 1_000;
      },
      curry(isUntraversable)(positionComponent, player)
    );
  };

  const getMoveAndAttackPath = (
    positionComponent: Component<{ x: Type.Number; y: Type.Number }>,
    attacker: Entity,
    defender: Entity
  ) => {
    const range = getComponentValue(Range, attacker);
    if (range && range.max > 1) return [];
    if (hasComponent(OnCooldown, attacker)) return [];
    if (getCurrentStamina(attacker) < 1_000) return [];

    const OptimisticPosition = actions.withOptimisticUpdates<SchemaOf<typeof Position>, Metadata, undefined>(Position);
    const attackerPosition = getComponentValue(OptimisticPosition, attacker);
    if (!attackerPosition) return [];

    const isTraversable = (entity: Entity, pos: Coord) => {
      return calculateMovementPath(positionComponent, entity, attackerPosition, pos).length > 0;
    };

    const closestUnblockedPosition = getClosestTraversablePositionToTarget(
      OptimisticPosition,
      isTraversable,
      attacker,
      defender
    );
    if (!closestUnblockedPosition) return [];

    return calculateMovementPath(positionComponent, attacker, attackerPosition, closestUnblockedPosition);
  };

  const toggleReady = async () => {
    network.network.worldContract.write.toggleReady([network.network.match]);
  };

  const layer = {
    world,
    actions,
    parentLayers: { network },
    components,
    turn$,
    api: {
      attack: curry(attack)({ network, actions }),
      buildAt: curry(buildAt)({ network, actions, world }),

      toggleReady,

      canAttack,
      isUntraversable,
      getMovementDifficulty,
      getAttackableEntities,

      unitSort,
      getCurrentStamina,

      calculateMovementPath,
      getMoveAndAttackPath,
      getMoveSpeed,
      getActionStaminaCost,

      combat: { calculateCombatResult, isPassive, isNeutralStructure, canRetaliate },
    },
  };

  createCurrentStaminaSystem(layer);
  createCooldownSystem(layer);
  createScopeClientToMatchSystem(layer);

  return layer;
}
