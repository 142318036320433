import { tileCoordToPixelCoord } from "@latticexyz/phaserx";
import { defineRxSystem, defineSystem, Entity, getComponentValue, Has, UpdateType } from "@latticexyz/recs";
import { ActionState } from "@latticexyz/std-client";
import { Animations } from "../../phaserConstants";
import { PhaserLayer, RenderDepth } from "../../types";

export function createDrawActingEntitySystem(layer: PhaserLayer) {
  const {
    world,
    parentLayers: {
      network: {
        components: { Transaction},
      },
      local: {
        components: { LocalPosition },
      },
    },
    scenes: {
      Main: {
        objectPool,
        maps: {
          Main: { tileWidth, tileHeight },
        },
      },
    },
  } = layer;

  defineSystem(world, [Has(Transaction), Has(LocalPosition)], ({ entity, type }) => {
    const spriteId = `${entity}-action`;

    if (type === UpdateType.Exit) {
      return objectPool.remove(spriteId);
    }

    const position = getComponentValue(LocalPosition, entity);
    if (!position) return;

    const object = objectPool.get(spriteId, "Sprite");

    object.setComponent({
      id: "acting-entity",
      once: async (sprite) => {
        const pixelCoord = tileCoordToPixelCoord(position, tileWidth, tileHeight);

        sprite.play(Animations.ChainCarousel);
        sprite.setPosition(pixelCoord.x, pixelCoord.y + tileHeight / 2);
        sprite.setDepth(RenderDepth.UI5);
        sprite.setScale(0.5);
      },
    });
  });
}
