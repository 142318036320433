import CsvDownloader from "react-csv-downloader";
import { Card } from "../ui/Theme/SkyStrife/Card";
import { Component, Has, getComponentValueStrict, runQuery } from "@latticexyz/recs";
import { useAnalytics } from "../../analyticsStore";
import { Body, Heading, OverlineLarge } from "../ui/Theme/SkyStrife/Typography";
import { Button } from "../ui/Theme/SkyStrife/Button";
import { useEntityQuery } from "@latticexyz/react";

export function GlobalEventsDownloader() {
  const {
    analyticsLayer: {
      components: { CreateMatch, JoinMatch, EndMatch, TokenBalanceSnapshot },
    },
  } = useAnalytics();

  const allCreateMatchEvents = useEntityQuery([Has(CreateMatch)]);
  const allJoinMatchEvents = useEntityQuery([Has(JoinMatch)]);
  const allEndMatchEvents = useEntityQuery([Has(EndMatch)]);
  const allTokenBalanceSnapshots = useEntityQuery([Has(TokenBalanceSnapshot)]);

  const constructCsvData = async (eventComponent: Component) => {
    const data = [...runQuery([Has(eventComponent)])].map((e) => getComponentValueStrict(eventComponent, e));
    return data as any[];
  };

  return (
    <Card>
      <Heading>Download Global Stats</Heading>

      <div className="h-4" />

      <OverlineLarge>Create Match Events</OverlineLarge>
      <Body>Count: {allCreateMatchEvents.length}</Body>
      <CsvDownloader
        datas={() => constructCsvData(CreateMatch)}
        filename={`${new Date().toISOString()}-create-match-events`}
      >
        <Button buttonType="primary">Download Create Match Events</Button>
      </CsvDownloader>

      <div className="h-2" />

      <OverlineLarge>Join Match Events</OverlineLarge>
      <Body>Count: {allJoinMatchEvents.length}</Body>
      <CsvDownloader
        datas={() => constructCsvData(JoinMatch)}
        filename={`${new Date().toISOString()}-join-match-events`}
      >
        <Button buttonType="primary">Download Join Match Events</Button>
      </CsvDownloader>

      <div className="h-2" />

      <OverlineLarge>End Match Events</OverlineLarge>
      <Body>Count: {allEndMatchEvents.length}</Body>
      <CsvDownloader datas={() => constructCsvData(EndMatch)} filename={`${new Date().toISOString()}-end-match-events`}>
        <Button buttonType="primary">Download End Match Events</Button>
      </CsvDownloader>

      <div className="h-2" />

      <OverlineLarge>Token Balance Snapshots</OverlineLarge>
      <Body>Count: {allTokenBalanceSnapshots.length}</Body>
      <CsvDownloader
        datas={() => constructCsvData(TokenBalanceSnapshot)}
        filename={`${new Date().toISOString()}-token-balance-snapshots`}
      >
        <Button buttonType="primary">Download Token Balance Snapshots</Button>
      </CsvDownloader>
    </Card>
  );
}
