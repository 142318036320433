import { Entity, getComponentValue, getComponentValueStrict, removeComponent, setComponent } from "@latticexyz/recs";
import { NetworkLayer } from "../../Network";
import { ActionSystem } from "@latticexyz/std-client";
import { manhattan } from "../../../utils/distance";
import { Hex } from "viem";

export async function attack(context: { network: NetworkLayer; actions: ActionSystem }, attacker: Entity, defender: Entity) {
  const { network, actions } = context;
  const {
    utils: { isOwnedByCurrentPlayer },
    network: { worldContract, waitForTransaction,
      components: { Position, Range }
    },
    components: {
      Transaction,
    },
  } = network;

  const OptimisticPosition = actions.withOptimisticUpdates(Position);

  if (!isOwnedByCurrentPlayer(attacker)) return;

  const attackerEntityID = attacker;
  const defenderEntityID = defender;

  const attackerPosition = getComponentValue(OptimisticPosition, attacker);
  if (!attackerPosition) return;

  const defenderPosition = getComponentValue(OptimisticPosition, defender);
  if (!defenderPosition) return;

  const distanceToTarget = manhattan(attackerPosition, defenderPosition);
  const attackerRange = getComponentValueStrict(Range, attacker);

  if (distanceToTarget <= attackerRange.max || distanceToTarget >= attackerRange.min) {
    setComponent(Transaction, attacker, { value: true });

    try {
      const tx = await worldContract.write.fight([attackerEntityID as Hex, defenderEntityID as Hex], { gas: 5_000_000n });
      await waitForTransaction(tx);
    } catch (e) {
      console.error(e);
    }

    removeComponent(Transaction, attacker);
  }
}
