import {
  Component,
  Entity,
  Has,
  HasValue,
  Type,
  getComponentValue,
  getComponentValueStrict,
  hasComponent,
  runQuery,
} from "@latticexyz/recs";
import { setup } from "../../mud/setup";
import { BigNumber } from "ethers";
import { WorldCoord } from "@latticexyz/phaserx/src/types";
import { SingletonID } from "@latticexyz/network";
import { manhattan } from "../../utils/distance";
import { Hex } from "viem";

const BYTES32_ZERO = "0x0000000000000000000000000000000000000000000000000000000000000000";

/**
 * The Network layer is the lowest layer in the client architecture.
 * Its purpose is to synchronize the client components with the contract components.
 */
export async function createNetworkLayer() {
  const { network, components } = await setup();
  const { worldContract, match: matchId, playerEntity } = network;

  function scopePathToMatch(path: WorldCoord[]) {
    return path.map((coord) => ({ x: coord.x, y: coord.y, z: matchId }));
  }

  async function move(entity: Entity, path: WorldCoord[]) {
    console.log(`Moving entity ${entity} to position (${path[path.length - 1].x}, ${path[path.length - 1].y})}`);
    return await worldContract.write.move([entity as Hex, scopePathToMatch(path)], { gas: 2_500_000n });
  }

  async function moveAndAttack(attacker: Entity, path: WorldCoord[], defender: Entity) {
    return await worldContract.write.moveAndAttack([attacker as Hex, scopePathToMatch(path), defender as Hex], { gas: 3_500_000n });
  }

  function getGodEntity() {
    return SingletonID;
  }

  async function spawnPrototypeAt(prototypeId: Entity, position: WorldCoord, owner?: Entity) {
    console.log(`Spawning prototype ${prototypeId} at ${JSON.stringify(position)}`);
    return await worldContract.write.spawnPrototypeDev([
      prototypeId as Hex,
      owner ? (owner as Hex) : BYTES32_ZERO,
      { x: position.x, y: position.y, z: matchId },
    ]);
  }

  function findEntityWithComponentInRelationshipChain(
    relationshipComponent: Component<{ value: Type.String }>,
    entity: Entity,
    searchComponent: Component
  ): Entity | undefined {
    if (hasComponent(searchComponent, entity)) return entity;

    while (hasComponent(relationshipComponent, entity)) {
      const entityValue = getComponentValueStrict(relationshipComponent, entity).value as Entity;
      if (entityValue == null) return;
      entity = entityValue;

      if (hasComponent(searchComponent, entity)) return entity;
    }

    return;
  }

  function getOwningPlayer(entity: Entity): Entity | undefined {
    return findEntityWithComponentInRelationshipChain(components.OwnedBy, entity, components.Player);
  }

  function isOwnedBy(entity: Entity, player: Entity) {
    const owningPlayer = getOwningPlayer(entity);
    return owningPlayer && owningPlayer === player;
  }

  function getPlayerEntity(address: string | undefined): Entity | undefined {
    if (!address) return;

    const addressEntity = address as Entity;
    const playerEntity = [
      ...runQuery([
        HasValue(components.OwnedBy, { value: addressEntity }),
        Has(components.Player),
        HasValue(components.Match, { value: matchId }),
      ]),
    ][0];

    return playerEntity;
  }

  function getCurrentPlayerEntity() {
    return getPlayerEntity(playerEntity);
  }

  function isOwnedByCurrentPlayer(entity: Entity) {
    const player = getPlayerEntity(playerEntity);
    return player && isOwnedBy(entity, player);
  }

  const findClosest = (entity: Entity, searchEntities: Entity[]) => {
    const closestEntity: {
      distance: number;
      Entity: Entity | null;
    } = {
      distance: Infinity,
      Entity: null,
    };

    const entityPosition = getComponentValue(components.Position, entity);
    if (!entityPosition) return closestEntity;

    for (const searchEntity of searchEntities) {
      const searchPosition = getComponentValue(components.Position, searchEntity);
      if (!searchPosition) continue;

      const distance = manhattan(entityPosition, searchPosition);
      if (distance < closestEntity.distance) {
        closestEntity.distance = distance;
        closestEntity.Entity = searchEntity;
      }
    }

    return closestEntity;
  };

  const getMatchEntity = (_matchId: number) => {
    if (!matchId) return;

    const matchEntity = [
      ...runQuery([Has(components.MatchConfig), HasValue(components.Match, { value: _matchId })]),
    ][0];
    if (matchEntity == null) return;

    return matchEntity;
  };

  const getMatchConfig = (_matchId: number) => {
    const matchEntity = getMatchEntity(_matchId);
    if (matchEntity == null) return;

    return getComponentValue(components.MatchConfig, matchEntity);
  };

  const getCurrentMatchConfig = () => {
    return getMatchConfig(matchId);
  };

  const getTurnAtTime = (_matchId: number, time: number) => {
    const matchConfig = getMatchConfig(_matchId);
    if (!matchConfig) return -1;

    const startTime = BigNumber.from(matchConfig.startTime);
    const turnLength = BigNumber.from(matchConfig.turnLength);

    let atTime = BigNumber.from(time);
    if (atTime < startTime) atTime = startTime;

    return atTime.sub(startTime).div(turnLength).toNumber();
  };

  const getTurnAtTimeForCurrentMatch = (time: number) => {
    return getTurnAtTime(matchId, time);
  };

  return {
    network,
    components: {
      ...components,
      ...network.components,
    },
    api: {
      getMatchEntity,
      getMatchConfig,
      getCurrentMatchConfig,
      getGodEntity,

      move,
      moveAndAttack,

      dev: {
        spawnPrototypeAt,
      },
    },
    utils: {
      findClosest,

      getTurnAtTime,
      getTurnAtTimeForCurrentMatch,

      getOwningPlayer,
      isOwnedBy,
      isOwnedByCurrentPlayer,
      getPlayerEntity,
      getCurrentPlayerEntity,
      
      manhattan,
    },
  };
}
