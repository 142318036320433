import { useEffect } from "react";
import { useNetworkLayer } from "./useNetworkLayer";
import { AnalyticsUIRoot } from "./analytics-ui/AnalyticsUIRoot";
import { LoadingScreen } from "./amalgema-ui/LoadingScreen";
import { useAnalyticsStore } from "../analyticsStore";
import { AnalyticsLayer } from "./AnalyticsLayer";

export const Analytics = () => {
  const networkLayer = useNetworkLayer();

  useEffect(() => {
    if (networkLayer) {
      useAnalyticsStore.setState({ networkLayer });
      document.title = `Analytics`;
    }
  }, [networkLayer]);

  return (
    <div>
      <LoadingScreen networkLayer={networkLayer} />

      <AnalyticsUIRoot />

      <AnalyticsLayer networkLayer={networkLayer} />
    </div>
  );
};
