import { useEffect, useMemo } from "react";
import { createNetworkLayer } from "../layers/Network/createNetworkLayer";
import { usePromiseValue } from "../usePromiseValue";

export const useNetworkLayer = () => {
  const networkLayerPromise = useMemo(() => {
    return createNetworkLayer();
  }, []);

  useEffect(() => {
    return () => {
      networkLayerPromise.then((networkLayer) => networkLayer.network.world.dispose());
    };
  }, [networkLayerPromise]);

  return usePromiseValue(networkLayerPromise);
};
