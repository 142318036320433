import "tailwindcss/tailwind.css";
import ReactDOM from "react-dom/client";
import { router } from "./router";
import { RouterProvider } from "react-router-dom";
import { mount as mountDevTools } from "@latticexyz/dev-tools";

const rootElement = document.getElementById("react-root");
if (!rootElement) throw new Error("React root not found");

const root = ReactDOM.createRoot(rootElement);
root.render(<RouterProvider router={router} />);
import.meta.env.DEV && mountDevTools();
