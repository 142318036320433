import React, { useEffect } from "react";
import Color from "color";
import { useCurrentPlayer } from "../hooks/useCurrentPlayer";
import { useMatchInfo } from "../hooks/useMatchInfo";
import { useComponentValue, useEntityQuery } from "@latticexyz/react";
import { getComponentValue, Has, HasValue } from "@latticexyz/recs";
import { useMUD } from "../../../store";
import { Checkbox } from "../Theme/Checkbox";
import { Button, ButtonType } from "../Theme/Button";
import { Card } from "../Theme/SkyStrife/Card";
import { Caption, OverlineSmall } from "../Theme/SkyStrife/Typography";
import { useSendTxButton } from "../hooks/useSendTxButton";
import { uuid } from "@latticexyz/utils";

function interleave(arr: any[], seperator: () => React.ReactNode) {
  return arr
    .reduce((acc, i) => {
      return [...acc, i, seperator()];
    }, [])
    .slice(0, -1);
}

export const MatchLobby = () => {
  const {
    networkLayer: {
      components: { Player, PlayerReady, Name, SpawnPoint, Position, Match },
      network: { worldContract, match },
    },
    localLayer: {
      api: { getOwnerColor },
      singletonEntity,
      components: { Spectator },
    },
  } = useMUD();

  const currentPlayer = useCurrentPlayer();
  const matchInfo = useMatchInfo();

  const playerReadys = useEntityQuery([Has(PlayerReady), HasValue(Match, { value: match })]);
  const currentPlayerReady = Boolean(playerReadys.find((i) => i === currentPlayer?.player));

  const allPlayers = useEntityQuery([Has(Player), Has(Name), HasValue(Match, { value: match })]).sort((a, b) => {
    if (a === currentPlayer?.player) return -10000;
    if (b === currentPlayer?.player) return 10000;

    const aReady = Boolean(playerReadys.find((i) => i === a));
    const bReady = Boolean(playerReadys.find((i) => i === b));

    if (aReady && !bReady) return -1;
    if (!aReady && bReady) return 1;

    return 0;
  });
  const allSpawns = useEntityQuery([Has(SpawnPoint), Has(Position), HasValue(Match, { value: match })]);
  const gameFull = allPlayers.length >= allSpawns.length;

  const readyButton = useSendTxButton({
    buttonType: "secondary",
    children: "ready",
    className: "w-full",
    sendTx: () => worldContract.write.toggleReady([match]),
  });

  const unReadyButton = useSendTxButton({
    buttonType: "secondary",
    children: "unready",
    className: "w-full",
    sendTx: () => worldContract.write.toggleReady([match]),
  });

  const [countdown, setCountdown] = React.useState(5);
  const [timeUntilSpawn, setTimeUntilSpawn] = React.useState(0);
  const [spawning, setSpawning] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>();
  const [tick, setTick] = React.useState(0);

  useEffect(() => {
    const spawnTime = matchInfo.matchConfig?.startTime ?? BigInt(0);
    if (spawnTime === BigInt(0)) return;

    const now = BigInt(Math.floor(Date.now() / 1000));
    const timeUntilSpawn = Number(spawnTime - now);

    setTimeUntilSpawn(timeUntilSpawn);

    if (timeUntilSpawn <= 0 || timeUntilSpawn > 10) {
      return;
    }

    setSpawning(true);
    setCountdown(timeUntilSpawn);
  }, [matchInfo, tick]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTick((t) => t + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const spectator = useComponentValue(Spectator, singletonEntity);
  if (!matchInfo) return <></>;

  return (
    <div className="flex-col items-center justify-center">
      <div>
        <Card primary className="h-fit w-[500px] px-8 py-4">
          <div className="flex flex-col items-center">
            <div className="flex flex-row justify-between w-full text-lg text-black/60 uppercase">
              <OverlineSmall>lobby</OverlineSmall>

              <OverlineSmall>
                {playerReadys.length}/{allSpawns.length} players ready
              </OverlineSmall>
            </div>

            <div className="h-3" />

            <div className="w-full">
              {interleave(
                allSpawns.map((_, i) => {
                  const player = allPlayers[i];
                  const name = getComponentValue(Name, player)?.value;
                  const playerReady = Boolean(playerReadys.find((i) => player === i));
                  const color = getOwnerColor(player);

                  return (
                    <div
                      className="flex flex-row items-center rounded p-2 px-3 w-full"
                      style={{
                        backgroundColor: Color(color).toString(),
                      }}
                      key={`player-${i}`}
                    >
                      <Checkbox checked={playerReady} style={!player ? { backgroundColor: "grey" } : {}} />
                      {player && <div className="ml-2 overflow-hidden text-ellipsis text-xl text-black/80">{name}</div>}
                      {!player && <div className="ml-2 text-xl text-black/80">Empty Slot</div>}
                    </div>
                  );
                }),
                () => (
                  <div key={uuid()} className="h-2" />
                )
              )}
            </div>

            {error && currentPlayerReady && (
              <div className="flex w-full flex-col items-center">
                <div className="mb-4 rounded bg-red-800 p-3 text-center text-2xl text-red-200 w-full">
                  Error: {error.toString()}
                </div>
                <Button
                  buttonType={ButtonType.Confirm}
                  className="mx-auto w-full p-3 text-2xl uppercase"
                  onClick={() => {
                    setError(undefined);
                    setSpawning(false);
                  }}
                >
                  Try Again
                </Button>
              </div>
            )}
            {spawning &&
              !error &&
              (countdown > 0 ? (
                <div className="text-center">
                  <div className="h-8" />
                  <span className="text-black/60 uppercase">Game starting in...</span>
                  <div className="text-9xl text-black">{countdown}</div>
                </div>
              ) : (
                <div className="text-center">
                  <div className="h-8" />
                  <div className="text-6xl text-black">Starting...</div>
                </div>
              ))}

            {!spawning && !error && !spectator && (
              <div className="w-full">
                <div className="h-8" />
                {!currentPlayerReady && <div className="full">{readyButton}</div>}

                {currentPlayerReady && <div className="full">{unReadyButton}</div>}

                {gameFull && (
                  <div className="flex flex-col items-center">
                    <div className="h-2" />
                    <Caption>Game starts in {timeUntilSpawn} seconds if players are not ready.</Caption>
                  </div>
                )}
              </div>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};
