import { EnergySurge } from "./EnergySurge";
import { GoldAmount } from "./GoldAmount";
import { Card } from "../ui/Theme/SkyStrife/Card";
import { useMatchInfo } from "./hooks/useMatchInfo";
import { useSpectator } from "./hooks/useSpectator";

export function TurnInfo() {
  const isSpectator = useSpectator();

  const { matchStarted } = useMatchInfo();
  if (!matchStarted) return <></>;

  return (
    <div className="absolute left-1/2 -translate-x-1/2">
      <Card primary className="h-fit w-[500px] pr-8">
        <div className="h-fit w-full flex flex-row items-baseline">
          <EnergySurge />
          {!isSpectator && (
            <div className="ml-4 w-1/3">
              <GoldAmount />
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}
