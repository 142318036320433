import { Has, getComponentValueStrict } from "@latticexyz/recs";
import { useAnalytics } from "../../analyticsStore";
import { useEntityQuery } from "@latticexyz/react";
import { MatchSummary } from "./MatchSummary";

export function Matches() {
  const {
    networkLayer: {
      components: { Match, MatchConfig },
    },
  } = useAnalytics();

  const allMatches = useEntityQuery([Has(Match), Has(MatchConfig)]).map((entity) => {
    return {
      id: entity,
      matchId: getComponentValueStrict(Match, entity).value,
    };
  });

  return (
    <div className="flex">
      {allMatches.map((match) => (
        <MatchSummary key={`match-${match.matchId}`} matchId={match.matchId} />
      ))}
    </div>
  );
}
