import createStore from "zustand/vanilla";
import createReactStore from "zustand";
import { NetworkLayer } from "./layers/Network";
import { AnalyticsLayer } from "./layers/Analytics";

export type AnalyticsStore = {
  networkLayer: NetworkLayer | null;
  analyticsLayer: AnalyticsLayer | null;
};

export type AnalyticsUIStore = {
  networkLayer: NetworkLayer;
  analyticsLayer: AnalyticsLayer;
};

export const analyticsStore = createStore<AnalyticsStore>(() => ({
  networkLayer: null,
  analyticsLayer: null,
}));

export const useAnalyticsStore = createReactStore(analyticsStore);

export const useAnalytics = () => {
  const { networkLayer, analyticsLayer } = useAnalyticsStore();

  if (networkLayer === null || analyticsLayer === null) {
    throw new Error("Store not initialized");
  }

  return {
    networkLayer,
    analyticsLayer,
  } as AnalyticsUIStore;
};
