import { Coord } from "@latticexyz/phaserx";
import { Component, Entity, getComponentValue, Type } from "@latticexyz/recs";
import { WorldCoord } from "../types";

/**
 * @param a Coordinate A
 * @param b Coordinate B
 * @returns Manhattan distance from A to B (https://xlinux.nist.gov/dads/HTML/manhattanDistance.html)
 */
export function manhattan(a: WorldCoord, b: WorldCoord) {
  return Math.abs(a.x - b.x) + Math.abs(a.y - b.y);
}

export const getNeighboringPositions = (coord: Coord) => {
  return [
    { ...coord, x: coord.x + 1 },
    { ...coord, x: coord.x - 1 },
    { ...coord, y: coord.y + 1 },
    { ...coord, y: coord.y - 1 },
  ];
};

export const getClosestTraversablePositionToTarget = (
  positionComponent: Component<{ x: Type.Number; y: Type.Number }>,
  canMoveTo: (entity: Entity, pos: Coord) => boolean,
  entity: Entity,
  target: Entity
) => {
  const attackerPosition = getComponentValue(positionComponent, entity);
  if (!attackerPosition) return;

  const defenderPosition = getComponentValue(positionComponent, target);
  if (!defenderPosition) return;

  const neighboringPositions = getNeighboringPositions(defenderPosition);

  let closestUnblockedPosition: Coord | undefined;
  for (const pos of neighboringPositions) {
    if (!canMoveTo(entity, pos)) continue;

    const previousDistance = closestUnblockedPosition
      ? manhattan(attackerPosition, closestUnblockedPosition)
      : Infinity;
    const distance = manhattan(attackerPosition, pos);

    if (distance < previousDistance) closestUnblockedPosition = pos;
  }

  return closestUnblockedPosition;
};
