import { Entity } from "@latticexyz/recs";
import { useAmalgema } from "../../store";
import { IconButton } from "../ui/Theme/SkyStrife/IconButton";
import { Discord } from "../ui/Theme/SkyStrife/Icons/Discord";
import { Body, Caption, OverlineLarge, OverlineSmall } from "../ui/Theme/SkyStrife/Typography";
import { useTokenBalances } from "./hooks/useTokenBalances";
import { Button } from "../ui/Theme/SkyStrife/Button";
import { Twitter } from "../ui/Theme/SkyStrife/Icons/Twitter";
import { DISCORD_URL, FEEDBACK_URL, TWITTER_URL } from "../links";
import { Profile } from "./Profile";

export function InventorySidebar() {
  const {
    network: { playerEntity },
  } = useAmalgema();

  const tokenBalances = useTokenBalances(playerEntity ?? ("0" as Entity));

  return (
    <div className="bg-ss-bg-1 border-l border-ss-stroke flex flex-col p-8 pt-4">
      <Profile />

      <div className="h-8" />

      <OverlineLarge>Inventory</OverlineLarge>
      <Caption>Earn resources by winning islands.</Caption>

      <div className="h-6" />

      <OverlineSmall>Resources</OverlineSmall>
      {tokenBalances.map(({ emoji, value }, i) => (
        <Body key={`${i}-balance`} className="text-ss-text-highlight flex items-center">
          {emoji} <div className="w-2" /> {value.toString(10)}
        </Body>
      ))}

      {tokenBalances.length === 0 && <Body className="text-ss-text-highlight flex items-center">🔮 0</Body>}

      <div className="h-6" />

      <OverlineSmall className="w-full text-left">Items</OverlineSmall>

      <div className="h-3" />

      <div className="bg-ss-bg-0 rounded border border-ss-stroke w-full flex flex-col items-center justify-around grow">
        <Caption className="text-center text-ss-text-x-light">Coming soon</Caption>
      </div>

      <div className="h-6" />

      <OverlineSmall className="w-full text-left">Crafting</OverlineSmall>

      <div className="h-3" />

      <div className="bg-ss-bg-0 rounded border border-ss-stroke w-full flex flex-col items-center justify-around grow">
        <Caption className="text-center text-ss-text-x-light">Coming soon</Caption>
      </div>

      <div className="h-6" />

      <div className="flex">
        <a href={FEEDBACK_URL} className="grow" target="_blank">
          <Button className="w-full" buttonType="tertiary">
            Submit Feedback
          </Button>
        </a>

        <div className="w-8" />

        <a href={DISCORD_URL} target="_blank">
          <IconButton>
            <Discord />
          </IconButton>
        </a>

        <div className="w-2" />

        <a href={TWITTER_URL} target="_blank">
          <IconButton>
            <Twitter />
          </IconButton>
        </a>
      </div>
    </div>
  );
}
