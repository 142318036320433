import { HasValue, getComponentValue, hasComponent, runQuery, setComponent } from "@latticexyz/recs";
import { filter, map } from "rxjs";
import { WorldCoord } from "../../../../../types";
import { worldCoordEq } from "../../../../../utils/coords";
import { PhaserLayer } from "../../types";
import { pixelToWorldCoord } from "../../utils";
import { InputUtils } from "./createInputSystem";

export function registerClicks(layer: PhaserLayer, { getSelectedEntity, getHighlightedEntity }: InputUtils) {
  const {
    parentLayers: {
      network: {
        components: { OwnedBy },
        utils: { isOwnedByCurrentPlayer },
      },
      headless: {
        components: { NextPosition },
        api: { canAttack, attack, calculateMovementPath },
      },
      local: {
        api: { selectArea, resetSelection, move },
        components: { PotentialPath, LocalPosition },
      },
    },
    api: {
      mapInteraction: { mapInteractionEnabled },
    },
    scenes: {
      Main: { input, maps },
    },
  } = layer;

  const onClick = function (clickedPosition: WorldCoord) {
    const selectedEntity = getSelectedEntity();

    // If the player owns the select unit...
    if (selectedEntity && isOwnedByCurrentPlayer(selectedEntity)) {
      const highlightedEntity = getHighlightedEntity();

      // If the player is hovering over an empty tile
      if (highlightedEntity == null) {
        const currentPosition = getComponentValue(LocalPosition, selectedEntity);
        if (!currentPosition) return;

        const nextPosition = getComponentValue(NextPosition, selectedEntity);
        const nextPositionAtClickedPosition = [...runQuery([HasValue(NextPosition, clickedPosition)])][0];

        if (nextPosition && worldCoordEq(clickedPosition, nextPosition)) {
          move(selectedEntity, clickedPosition);
          resetSelection(false);
        } else if (nextPositionAtClickedPosition) {
        } else if (
          hasComponent(PotentialPath, selectedEntity) &&
          calculateMovementPath(LocalPosition, selectedEntity, currentPosition, clickedPosition).length > 0
        ) {
          setComponent(NextPosition, selectedEntity, clickedPosition);
        } else {
          resetSelection();
          selectArea({ ...clickedPosition, width: 1, height: 1 });
        }
      } else {
        if (canAttack(selectedEntity, highlightedEntity)) {
          const attackerOwner = getComponentValue(OwnedBy, selectedEntity);
          const defenderOwner = getComponentValue(OwnedBy, highlightedEntity);

          if (!attackerOwner || attackerOwner.value !== defenderOwner?.value) {
            const nextPosition = getComponentValue(NextPosition, selectedEntity);
            if (nextPosition) {
              move(selectedEntity, nextPosition, highlightedEntity);
              resetSelection(false);
            } else {
              attack(selectedEntity, highlightedEntity);
              resetSelection();
            }
          }
        } else {
          resetSelection();
          selectArea({ ...clickedPosition, width: 1, height: 1 });
        }
      }
    } else {
      resetSelection();
      selectArea({ ...clickedPosition, width: 1, height: 1 });
    }
  };

  input.click$
    .pipe(
      filter((pointer) => pointer.event.target instanceof HTMLCanvasElement && mapInteractionEnabled()),
      filter((pointer) => pointer.button === 0), // left click only
      map((pointer) => ({ x: pointer.worldX, y: pointer.worldY })),
      map((pixel) => pixelToWorldCoord(maps.Main, pixel))
    )
    .subscribe((coord) => {
      onClick(coord);
    });
}
