import { SettingsButton } from "./SettingsButton";
import { TutorialButton } from "./TutorialButton";

export function OptionsBar() {
  return (
    <div className="absolute right-0 top-0 flex">
      <SettingsButton />
      <div className="w-4" />
      <TutorialButton />
    </div>
  );
}
