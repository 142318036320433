import { ClickWrapper } from "../Theme/ClickWrapper";
import { useMatchInfo } from "../hooks/useMatchInfo";
import { JoinGame } from "./JoinGame";
import { MatchLobby } from "./MatchLobby";
import { MatchRewards } from "./MatchRewards";

export function PreGame() {
  const matchInfo = useMatchInfo();
  if (matchInfo.matchStarted) return <></>;

  return (
    <ClickWrapper className="fixed h-screen w-screen">
      <div style={{ zIndex: -1 }} className="absolute top-0 left-0 w-screen h-screen bg-[#181710]/60 -ml-6 -mt-6" />

      <div className="relative mx-auto top-1/2 -translate-y-1/2 flex flex-col items-center">
        <JoinGame />

        <div className="h-4"></div>

        <MatchLobby />

        <div className="h-4"></div>

        <MatchRewards />
      </div>
    </ClickWrapper>
  );
}
