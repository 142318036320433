import { defineEnterSystem, Has, HasValue, runQuery } from "@latticexyz/recs";
import { PhaserLayer } from "../../types";

export function createPlayerSpawnSystem(layer: PhaserLayer) {
  const {
    world,
    parentLayers: {
      network: {
        components: {
          SpawnPoint, OwnedBy
        },
        utils: {
          getPlayerEntity,
        },
        network: {
          playerEntity: playerAddress
        }
      },
      local: {
        components: {
          MatchStarted
        },
      },
    },
    api: {
      selectAndView
    }
  } = layer;

  defineEnterSystem(world, [Has(MatchStarted)], () => {
    const spawnSettlement = [...runQuery([Has(SpawnPoint), HasValue(OwnedBy, { value: getPlayerEntity(playerAddress) })])][0];
    if (!spawnSettlement) return;

    selectAndView(spawnSettlement);
  });
}
