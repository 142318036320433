import { useComponentValue } from "@latticexyz/react";
import { Entity } from "@latticexyz/recs";
import { useAmalgema } from "../../store";
import { OpenMatch } from "./OpenMatch";
import { PendingMatch } from "./PendingMatch";

export function OpenOrPendingMatch({ matchEntity }: { matchEntity: Entity }) {
  const {
    components: { MatchReady, Match },
  } = useAmalgema();

  const matchReady = useComponentValue(MatchReady, matchEntity)?.value;
  const matchId = useComponentValue(Match, matchEntity)?.value;

  if (!matchId) return <></>;

  return <>{matchReady ? <OpenMatch matchId={matchId} /> : <PendingMatch matchId={matchId} />}</>;
}
