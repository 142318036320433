import { Clock } from "@latticexyz/network";
import { distinctUntilChanged, filter, map } from "rxjs";
import { getCurrentTurn } from "../../Network/utils";
import { BigNumber } from "ethers";

export function createTurnStream(
  getMatchConfig: () =>
    | {
        startTime: BigNumber;
        turnLength: BigNumber;
      }
    | undefined,
  clock: Clock
) {
  return clock.time$.pipe(
    map(() => {
      const matchConfig = getMatchConfig();
      if (!matchConfig) {
        return -1;
      }
      return getCurrentTurn(matchConfig.startTime, matchConfig.turnLength, clock);
    }),
    filter((turn) => turn !== -1),
    distinctUntilChanged()
  );
}
