import { useEffect } from "react";
import { useStore } from "../store";
import { HeadlessLayer } from "./HeadlessLayer";
import { LoadingScreen } from "./ui/LoadingScreen";
import { UIRoot } from "./ui/UIRoot";
import { useNetworkLayer } from "./useNetworkLayer";

export const SkyStrife = () => {
  const networkLayer = useNetworkLayer();

  useEffect(() => {
    if (networkLayer) {
      useStore.setState({ networkLayer });
      document.title = `Match ${networkLayer.network.match}`;
    }
  }, [networkLayer]);

  return (
    <>
      <div style={{ width: "100vw", height: "100vh", position: "relative" }}>
        <LoadingScreen networkLayer={networkLayer} />

        <UIRoot />

        <HeadlessLayer networkLayer={networkLayer} />
      </div>
    </>
  );
};
