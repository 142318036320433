import { useEntityQuery } from "@latticexyz/react";
import { Entity, Has, getComponentValueStrict } from "@latticexyz/recs";
import { useMUD } from "../../store";
import { range } from "@latticexyz/utils";
import { decodeEntity } from "@latticexyz/store-sync/recs";
import { isDefined } from "@latticexyz/common/utils";

// TODO: replace with MUD decoding once available

const INT32_RANGE = 4294967296;

const decodeInt32 = (data: string) => {
  const n = parseInt(data, 16);

  if (n < INT32_RANGE / 2) {
    return n;
  }

  return n - INT32_RANGE;
};

const TransparentImage = () => (
  <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" width="20" />
);

export const DisplayLevel = ({ level }: { level: Entity }) => {
  const {
    networkLayer: {
      components: { MapContent },
    },
  } = useMUD();

  const contents = useEntityQuery([Has(MapContent)]);

  const positions = contents
    .map((key) => {
      const { levelId } = decodeEntity(MapContent.metadata.keySchema, key);
      if (levelId !== level) return;

      const content = getComponentValueStrict(MapContent, key).value;

      const x = decodeInt32(content.slice(2, 10));
      const y = decodeInt32(content.slice(10, 18));

      return { x, y };
    })
    .filter(isDefined);

  return (
    <div className="bg-blue-300">
      <table>
        <tbody>
          {Array.from(range(30, 1, -15)).map((j) => (
            <tr key={j}>
              {Array.from(range(30, 1, -15)).map((i) => (
                <td key={`${i},${j}`} className="p-0">
                  {positions.some(({ x, y }) => x === i && y === j) ? (
                    <img src="./assets/grass.png" width="20" />
                  ) : (
                    <TransparentImage />
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
