import { useEffect } from "react";
import { useStore } from "../store";
import { useNetworkLayer } from "./useNetworkLayer";
import { AmalgemaUIRoot } from "./amalgema-ui/AmalgemaUIRoot";
import { LoadingScreen } from "./amalgema-ui/LoadingScreen";

export const Amalgema = () => {
  const networkLayer = useNetworkLayer();

  useEffect(() => {
    if (networkLayer) {
      useStore.setState({ networkLayer });
      document.title = `Sky Strife - Main Menu`;
    }
  }, [networkLayer]);

  return (
    <div>
      <LoadingScreen networkLayer={networkLayer} />

      <AmalgemaUIRoot />
    </div>
  );
};
