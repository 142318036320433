import { PhaserLayer } from "../../types";
import { pixelToWorldCoord } from "../../utils";
import { filter, map, merge } from "rxjs";
import { defineQuery, getComponentValueStrict, Has, HasValue, Not, runQuery } from "@latticexyz/recs";
import { WorldCoord } from "../../../../../types";
import { InputUtils } from "./createInputSystem";
import { filterNullish } from "@latticexyz/utils";

export function registerHoverIcon(layer: PhaserLayer, { getSelectedEntity }: InputUtils) {
  const {
    scenes: {
      Main: { input, maps, phaserScene },
    },
    components: { HoverHighlight },
    api: {
      highlightCoord,
      mapInteraction: { mapInteractionEnabled },
    },
    parentLayers: {
      network: {
        components: { TerrainType, Position },
        utils: { isOwnedByCurrentPlayer },
      },
      headless: {
        api: { canAttack },
      },
      local: {
        singletonEntity,
        components: { Selected, LocalPosition },
        api: { getOwnerColor, hasPotentialPath },
      },
    },
  } = layer;

  const setHoverIcon = function (hoveredPosition: WorldCoord) {
    const hoverHighlight = getComponentValueStrict(HoverHighlight, singletonEntity);
    const highlightedEntity = [
      ...runQuery([HasValue(LocalPosition, { x: hoverHighlight.x, y: hoverHighlight.y }), Not(TerrainType)]),
    ][0];

    let highlightColor = 0xffff00;
    if (highlightedEntity != null) {
      highlightColor = getOwnerColor(highlightedEntity);
    }

    highlightCoord(hoveredPosition, highlightColor);

    const selectedEntity = getSelectedEntity();
    if (!selectedEntity) {
      input.setCursor("url(public/assets/default-cursor-3.png), auto");
      return;
    }

    if (!isOwnedByCurrentPlayer(selectedEntity)) {
      input.setCursor("url(public/assets/default-cursor-3.png), auto");
      return;
    }

    if (selectedEntity == highlightedEntity) {
      input.setCursor("url(public/assets/default-cursor-3.png), auto");
      return;
    }

    if (highlightedEntity != null) {
      if (canAttack(selectedEntity, highlightedEntity)) {
        input.setCursor("url(public/assets/attack.png), pointer");
        return;
      }
    }

    if (hasPotentialPath(selectedEntity, hoveredPosition)) {
      input.setCursor("url(public/assets/move.png), pointer");
      return;
    }

    input.setCursor("url(public/assets/default-cursor-3.png), auto");
  };

  let lastPointerWorldCoord: WorldCoord | undefined;
  const selectedEntityPositionQuery = defineQuery([Has(Selected), Has(Position)]);
  merge(selectedEntityPositionQuery.update$, input.pointermove$)
    .pipe(
      filter(() => mapInteractionEnabled()),
      map(() => {
        if (!phaserScene.input) return { x: 0, y: 0 };

        const pointer = phaserScene.input.activePointer;
        return { x: pointer.worldX, y: pointer.worldY };
      }), // Map pointer to pointer pixel cood
      map((pixel) => {
        const newWorldCoord = pixelToWorldCoord(maps.Main, pixel);
        if (newWorldCoord === lastPointerWorldCoord) return null;

        lastPointerWorldCoord = newWorldCoord;

        return newWorldCoord;
      }), // Map pixel coord to tile coord
      filterNullish()
    )
    .subscribe((coord) => {
      setHoverIcon(coord);
    });
}
