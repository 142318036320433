import { Coord, tileCoordToPixelCoord } from "@latticexyz/phaserx";
import {
  defineComponent,
  defineEnterSystem,
  defineQuery,
  defineRxSystem,
  defineSyncSystem,
  defineSystem,
  Entity,
  getComponentValueStrict,
  Has,
  HasValue,
  removeComponent,
  setComponent,
  Type,
  UpdateType,
} from "@latticexyz/recs";
import { BigNumber } from "ethers";
import { merge } from "rxjs";
import { StructureTypes } from "../../../../Network";
import { Animations, Sprites } from "../../phaserConstants";
import { PhaserLayer, RenderDepth } from "../../types";

export function createEmberCrownShrineSystem(layer: PhaserLayer) {
  const {
    world,
    parentLayers: {
      network: {
        components: { StructureType, Stamina, StamRegenCap, OwnedBy, Combat },
        api: { getCurrentMatchConfig },
      },
      local: {
        components: { LocalPosition },
      },
      headless: {
        components: { LocalStamina },
        turn$,
        api: { getCurrentStamina },
      },
    },
    api: { setOriginCenter, getEntityPixelCoord },
    scenes: {
      Main: {
        phaserScene,
        objectPool,
        config,
        maps: {
          Main: { tileWidth, tileHeight },
        },
      },
    },
  } = layer;

  enum ShrineStates {
    Idle,
    Rising,
    Ready,
  }

  const ShrineState = defineComponent(world, { value: Type.Number }, { id: "ShrineState" });
  let shrineSprite: Phaser.GameObjects.Sprite | undefined;

  function isCrownTaken(entity: Entity) {
    const currentStamina = getCurrentStamina(entity);
    const staminaCap = getComponentValueStrict(StamRegenCap, entity);

    return staminaCap.totalRegenerated >= staminaCap.cap && currentStamina == 0;
  }

  function drawShrineBackground(position: Coord, depth: number) {
    const foregroundSprite = phaserScene.add.sprite(0, 0, "");
    const foregroundTexture = config.sprites[Sprites.EmberCrownShrineRisingForeground as 1];
    foregroundSprite.setTexture(foregroundTexture.assetKey, foregroundTexture.frame);
    foregroundSprite.setPosition(position.x, position.y);
    foregroundSprite.setDepth(depth + 5);
    setOriginCenter(foregroundSprite);

    const backgroundSprite = phaserScene.add.sprite(0, 0, "");
    const backgroundTexture = config.sprites[Sprites.EmberCrownShrineRisingBackground as 1];
    backgroundSprite.setTexture(backgroundTexture.assetKey, backgroundTexture.frame);
    backgroundSprite.setPosition(position.x, position.y);
    backgroundSprite.setDepth(depth - 5);
    setOriginCenter(backgroundSprite);

    return {
      foregroundSprite,
      backgroundSprite,
    };
  }

  defineSyncSystem(
    world,
    [HasValue(StructureType, { value: StructureTypes.EmberCrownShrine })],
    () => ShrineState,
    () => ({ value: ShrineStates.Idle })
  );

  // Beging the rising animation when the shrine is captured
  defineSystem(
    world,
    [
      HasValue(ShrineState, { value: ShrineStates.Idle }),
      Has(LocalPosition),
      Has(OwnedBy),
      Has(Stamina),
      Has(StamRegenCap),
      Has(LocalStamina),
    ],
    (update) => {
      const { entity, type } = update;

      if (isCrownTaken(entity)) return;
      if (type !== UpdateType.Enter) return;

      const pixelCoord = getEntityPixelCoord(entity);
      const sprite = phaserScene.add.sprite(pixelCoord.x, pixelCoord.y, "");
      shrineSprite = sprite;

      setTimeout(() => {
        if (!shrineSprite) return;

        objectPool.remove(entity);
        shrineSprite.play(Animations.EmberCrownShrineStart);
        setOriginCenter(shrineSprite);
      }, 1000);

      shrineSprite.on(`animationcomplete-${Animations.EmberCrownShrineStart}`, () => {
        setComponent(ShrineState, entity, { value: ShrineStates.Rising });
        removeComponent(Combat, entity);
      });
    }
  );

  // Calculate how much time is needed before the crown is ready and set that as the duration of the animation
  defineSystem(world, [HasValue(ShrineState, { value: ShrineStates.Rising })], ({ entity, type }) => {
    if (type !== UpdateType.Enter) return;

    const sprite = shrineSprite;
    if (!sprite) return;

    sprite.play(Animations.EmberCrownShrineRising);
    sprite.setDepth(RenderDepth.Background2);

    const { backgroundSprite } = drawShrineBackground(sprite, sprite.depth);

    // Uh so this works, not sure why Phaser is complaining
    const mask = new Phaser.Display.Masks.GeometryMask(
      phaserScene,
      backgroundSprite as unknown as Phaser.GameObjects.Graphics
    );
    const originalY = sprite.y;
    sprite.setPosition(sprite.x, sprite.y + sprite.height / 2);
    sprite.setMask(mask);

    const gameConfig = getCurrentMatchConfig();
    if (!gameConfig) return;
    const turnLength = BigNumber.from(gameConfig.turnLength).toNumber();
    const staminaNeeded = 1_000 - getCurrentStamina(entity);
    const regenRate = getComponentValueStrict(Stamina, entity).regeneration;
    const timeUntilCrownIsReady = (staminaNeeded / regenRate) * turnLength * 1000;

    phaserScene.tweens.add({
      targets: sprite,
      y: originalY,
      duration: timeUntilCrownIsReady,
      ease: "Linear",
    });
  });

  // When the shrine has enough stamina to generate the crown, play the final animation and present the crown
  defineSystem(
    world,
    [HasValue(ShrineState, { value: ShrineStates.Rising }), Has(LocalStamina)],
    ({ entity, type }) => {
      if (type === UpdateType.Exit) return;

      const sprite = shrineSprite;
      if (!sprite) return;

      const currentStamina = getCurrentStamina(entity);
      if (currentStamina === 1_000) {
        setComponent(ShrineState, entity, { value: ShrineStates.Ready });

        const moveCrownToPositionAndPlayFinalAnimation = () => {
          const pixelCoord = getEntityPixelCoord(entity);
          phaserScene.tweens.killTweensOf(sprite);
          phaserScene.add.tween({
            targets: sprite,
            y: pixelCoord.y,
            duration: 500,
            ease: "Linear",
            onComplete: () => {
              sprite.play(Animations.EmberCrownShrineEnd);
              sprite.setDepth(RenderDepth.Foreground1);
              sprite.on(`animationcomplete-${Animations.EmberCrownShrineEnd}`, () => {
                const crownTexture = config.sprites[Sprites.EmberCrown as 1];
                sprite.setTexture(crownTexture.assetKey, crownTexture.frame);
                setOriginCenter(sprite);
                phaserScene.tweens.add({
                  targets: sprite,
                  y: sprite.y - 10,
                  duration: 1_000,
                  yoyo: true,
                  repeat: -1,
                  ease: Phaser.Math.Easing.Quadratic.InOut,
                });
              });
            },
          });
        };

        moveCrownToPositionAndPlayFinalAnimation();
      }
    }
  );

  // When a player takes the crown, remove it from view
  defineSystem(
    world,
    [HasValue(ShrineState, { value: ShrineStates.Ready }), Has(Stamina), Has(StamRegenCap)],
    ({ entity }) => {
      if (isCrownTaken(entity)) shrineSprite?.destroy();
    }
  );

  // Fallback system for when the final state is loaded via snapshot
  defineEnterSystem(
    world,
    [
      HasValue(ShrineState, { value: ShrineStates.Idle }),
      Has(OwnedBy),
      Has(Stamina),
      Has(LocalStamina),
      Has(StamRegenCap),
    ],
    ({ entity }) => {
      if (!isCrownTaken(entity)) return;

      const pixelCoord = getEntityPixelCoord(entity);
      drawShrineBackground(pixelCoord, RenderDepth.Background2);

      objectPool.remove(entity);
    }
  );

  // Draw number countdown
  const shrineStateQuery = defineQuery([Has(ShrineState), Has(LocalStamina), Has(StamRegenCap)]);
  const countdown$ = merge(turn$, shrineStateQuery.update$);
  const countdownText = phaserScene.add.text(0, 0, "");
  countdownText.setVisible(false);

  phaserScene.add.tween({
    targets: countdownText,
    alpha: 0.4,
    duration: 1_000,
    ease: "Linear",
    yoyo: true,
    repeat: -1,
  });

  defineRxSystem(world, countdown$, () => {
    const entity = [...shrineStateQuery.matching][0];
    if (!entity) return;

    const sprite = shrineSprite;
    if (!sprite) return;

    const currentStamina = getCurrentStamina(entity);
    const staminaNeeded = 1_000 - currentStamina;
    const regenRate = getComponentValueStrict(Stamina, entity).regeneration;
    const turnsUntilCrownReady = staminaNeeded / regenRate;

    if (turnsUntilCrownReady <= 0 || isCrownTaken(entity)) {
      countdownText.setVisible(false);
      return;
    }

    countdownText.setText(`${turnsUntilCrownReady}`);
    countdownText.setDepth(RenderDepth.UI1);
    countdownText.setVisible(true);
    countdownText.setStyle({ fontSize: "20px", color: "#ffffff", textAlign: "center" });

    const position = getComponentValueStrict(LocalPosition, entity);
    const pixelCoord = tileCoordToPixelCoord(position, tileWidth, tileHeight);
    countdownText.setPosition(pixelCoord.x + countdownText.width / 2 + 3, pixelCoord.y - 30);
  });
}
