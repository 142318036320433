import { useComponentValue, useEntityQuery } from "@latticexyz/react";
import { useMUD } from "../../../store";
import { Has, HasValue } from "@latticexyz/recs";

export const useMatchInfo = () => {
  const {
    networkLayer: {
      components: { MatchConfig, Match },
      network: { match },
    },
    localLayer: {
      components: { MatchStarted },
    },
  } = useMUD();

  const matchEntity = useEntityQuery([Has(MatchConfig), HasValue(Match, { value: match })])[0];
  const matchConfig = useComponentValue(MatchConfig, matchEntity);
  const matchStarted = useComponentValue(MatchStarted, matchEntity);

  return {
    matchEntity,
    matchConfig: matchConfig,
    matchStarted: matchStarted?.value,
  };
};
