import { useEntityQuery } from "@latticexyz/react";
import { useAnalytics } from "../../analyticsStore";
import { Card } from "../ui/Theme/SkyStrife/Card";
import { Heading } from "../ui/Theme/SkyStrife/Typography";
import { Has, HasValue } from "@latticexyz/recs";

export function MatchSummary({ matchId }: { matchId: number }) {
  const {
    networkLayer: {
      components: { Match, MatchFinished },
    },
    analyticsLayer: {
      components: { UnitBuy, UnitKill, UnitDeath, StructureCapture, StructureKill, UnitSnapshot, StructureSnapshot },
    },
  } = useAnalytics();

  const unitBuyCount = useEntityQuery([HasValue(UnitBuy, { match: matchId })]).length;
  const unitKillCount = useEntityQuery([HasValue(UnitKill, { match: matchId })]).length;
  const unitDeathCount = useEntityQuery([HasValue(UnitDeath, { match: matchId })]).length;
  const structureCaptureCount = useEntityQuery([HasValue(StructureCapture, { match: matchId })]).length;
  const structureKillCount = useEntityQuery([HasValue(StructureKill, { match: matchId })]).length;
  const unitSnapshotCount = useEntityQuery([HasValue(UnitSnapshot, { match: matchId })]).length;
  const structureSnapshotCount = useEntityQuery([HasValue(StructureSnapshot, { match: matchId })]).length;

  const matchOver = useEntityQuery([HasValue(Match, { value: matchId }), Has(MatchFinished)]).length > 0;

  return (
    <Card>
      <Heading>Match Summary</Heading>
      <div className="h-2" />
      <div>Match ID: {matchId}</div>

      <div className="h-4" />

      <div>Unit Buys: {unitBuyCount}</div>
      <div>Unit Kills: {unitKillCount}</div>
      <div>Unit Deaths: {unitDeathCount}</div>
      <div>Structure Captures: {structureCaptureCount}</div>
      <div>Structure Kills: {structureKillCount}</div>
      <div>Unit Snapshots: {unitSnapshotCount}</div>
      <div>Structure Snapshots: {structureSnapshotCount}</div>

      <div className="h-4" />

      <div>Match Over: {matchOver ? "Yes" : "No"}</div>
    </Card>
  );
}
