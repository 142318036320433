import { Link, Caption, OverlineSmall } from "../ui/Theme/SkyStrife/Typography";
import { useAmalgema } from "../../store";
import { useEntityQuery } from "@latticexyz/react";
import { Has, HasValue, Not, getComponentValue, runQuery } from "@latticexyz/recs";
import { OpenOrPendingMatch } from "./OpenOrPendingMatch";
import { useSummonIslandModal } from "./SummonIsland";

export function OpenOrPendingMatches() {
  const {
    components: { MatchConfig, Match, Player, SpawnPoint, MatchReady },
  } = useAmalgema();

  const { setModalOpen, modal } = useSummonIslandModal();

  const openMatches = useEntityQuery([Has(Match), Has(MatchConfig), Has(MatchReady)]);
  const pendingMatches = useEntityQuery([Has(Match), Has(MatchConfig), Not(MatchReady)]);
  const allMatches = openMatches.concat(pendingMatches);
  const allPlayers = useEntityQuery([Has(Player), Has(Match)]);

  const openOrPendingMatches = allMatches.filter((i) => {
    const matchId = getComponentValue(Match, i)?.value;
    const playersInMatch = allPlayers.filter((i) => {
      const match = getComponentValue(Match, i);
      return match?.value === matchId;
    });
    const spawns = runQuery([HasValue(Match, { value: matchId }), Has(SpawnPoint)]);

    return playersInMatch.length < spawns.size || spawns.size === 0;
  });

  return (
    <div>
      {modal}
      <OverlineSmall className="mb-4 uppercase">Summoning Islands ({openOrPendingMatches.length})</OverlineSmall>
      <div className="flex flex-row flex-wrap">
        {openOrPendingMatches.length === 0 && (
          <div className="w-full h-[200px] border border-ss-stroke rounded flex flex-col justify-around bg-ss-bg-0">
            <Caption className="text-center">
              No open islands.
              <br></br>
              <div
                onClick={() => {
                  setModalOpen(true);
                }}
              >
                <Link>Summon an island</Link>
              </div>
            </Caption>
          </div>
        )}

        {openOrPendingMatches.map((i) => {
          return (
            <div key={`open-match-${i}`} className="mr-6 mb-6">
              <OpenOrPendingMatch matchEntity={i} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
