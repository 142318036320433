import { tileCoordToPixelCoord } from "@latticexyz/phaserx";
import {
  defineSystem,
  getComponentValue,
  getComponentValueStrict,
  Has,
  removeComponent,
  UpdateType,
} from "@latticexyz/recs";

import { PhaserLayer, RenderDepth } from "../../types";
import { FAST_MOVE_SPEED, UNIT_OFFSET } from "../../../../Local/constants";

export function createDrawNextPositionSystem(layer: PhaserLayer) {
  const {
    world,
    components: { SpriteAnimation },
    parentLayers: {
      headless: {
        components: { NextPosition },
      },
      local: {
        api: { getOwnerColor },
        components: { LocalPosition },
      },
    },
    api: { tintObject },
    scenes: {
      Main: {
        objectPool,

        maps: {
          Main: { tileWidth, tileHeight },
        },
      },
    },
  } = layer;

  defineSystem(world, [Has(NextPosition)], ({ entity, type }) => {
    const spriteId = `${entity}-nextPosition`;
    if (type === UpdateType.Exit) return objectPool.remove(spriteId);

    const nextPosition = getComponentValueStrict(NextPosition, entity);

    const object = objectPool.get(spriteId, "Sprite");
    const animation = getComponentValue(SpriteAnimation, entity);
    if (!animation) return;
    const color = getOwnerColor(entity);
    object.setComponent({
      id: `next-position`,
      once: async (attackSprite) => {
        const pixelCoord = tileCoordToPixelCoord(nextPosition, tileWidth, tileHeight);
        attackSprite.play(animation.value);
        attackSprite.setPosition(pixelCoord.x, pixelCoord.y - UNIT_OFFSET);
        attackSprite.setDepth(RenderDepth.UI5);
        attackSprite.setAlpha(0.65);

        tintObject(attackSprite, color);
      },
    });
  });

  /**
   * Remove the NextPosition component when the entity has reached its destination.
   */
  defineSystem(world, [Has(NextPosition), Has(LocalPosition)], ({ entity }) => {
    const nextPosition = getComponentValue(NextPosition, entity);
    const localPosition = getComponentValue(LocalPosition, entity);

    if (nextPosition?.x === localPosition?.x && nextPosition?.y === localPosition?.y) {
      setTimeout(() => {
        removeComponent(NextPosition, entity);
      }, FAST_MOVE_SPEED);
    }
  });
}
