import { useEffect } from "react";
import { NetworkLayer } from "../layers/Network";
import { useAnalyticsLayer } from "./useAnalyticsLayer";
import { useAnalyticsStore } from "../analyticsStore";

type Props = {
  networkLayer: NetworkLayer | null;
};

export const AnalyticsLayer = ({ networkLayer }: Props) => {
  const analyticsLayer = useAnalyticsLayer(networkLayer);

  useEffect(() => {
    if (analyticsLayer) {
      useAnalyticsStore.setState({ analyticsLayer });
    }
  }, [analyticsLayer]);

  return <></>;
};
