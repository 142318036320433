import { WorldCoord } from "@latticexyz/phaserx/src/types";
import { Entity, World, removeComponent, setComponent } from "@latticexyz/recs";
import { ActionSystem } from "@latticexyz/std-client";
import { NetworkLayer } from "../../Network";
import { Hex } from "viem";

export async function buildAt(
  context: {
    network: NetworkLayer;
    actions: ActionSystem;
    world: World;
  },
  builderId: Entity,
  prototypeId: string,
  position: WorldCoord
) {
  const {
    network: {
      components: { Transaction },
      network: { worldContract, match, waitForTransaction },
    },
  } = context;

  setComponent(Transaction, builderId, { value: true });

  try {
    const tx = await worldContract.write.build([
      builderId as Hex,
      prototypeId as Hex,
      { x: position.x, y: position.y, z: match },
    ]);
    await waitForTransaction(tx);
  } catch (e) {
    console.error(e);
  }

  removeComponent(Transaction, builderId);
}
