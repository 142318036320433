import { useAmalgema } from "../../store";
import useLocalStorageState from "use-local-storage-state";
import { formatEther } from "ethers/lib/utils";
import { useEffect, useState } from "react";
import { Card } from "../ui/Theme/SkyStrife/Card";
import { Button } from "../ui/Theme/SkyStrife/Button";
import { Heading, Caption, OverlineLarge, OverlineSmall, Link } from "../ui/Theme/SkyStrife/Typography";
import { EXPLORER_URL } from "../links";
import { Hex } from "viem";

const formatAddress = (address: Hex) => address?.slice(0, 6) + "..." + address?.slice(-4);

const NameModal = ({ setVisible }: { setVisible: any }) => {
  const {
    network: { walletClient },
  } = useAmalgema();

  const [name, setName] = useLocalStorageState<string>("skystrife:name", {
    defaultValue: "",
  });

  return (
    <div
      style={{
        background: "rgba(24, 23, 16, 0.65)",
        zIndex: 100,
      }}
      className="fixed top-0 left-0 w-screen h-screen flex flex-col justify-around"
    >
      <Card primary className="bg-ss-bg-1 flex flex-col justify-center mx-auto p-8 w-fit w-[624px]">
        <div className="flex justify-between items-center">
          <OverlineLarge>Account</OverlineLarge>

          <Button buttonType={"tertiary"} onClick={() => setVisible(false)} className="h-fit py-1">
            Close
          </Button>
        </div>

        <div className="h-8"></div>

        <div>
          <OverlineSmall>Wallet address</OverlineSmall>
          <Link href={`${EXPLORER_URL}/address/${walletClient.account.address}`}>
            {walletClient.account.address}
          </Link>
        </div>

        <div className="h-6"></div>

        <div>
          <OverlineSmall>Username</OverlineSmall>
          <input
            className="bg-ss-bg-0 rounded border border-ss-stroke w-full px-3 py-2 shadow-ss-small"
            placeholder="Enter a username"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </Card>
    </div>
  );
};

export function Profile() {
  const {
    network: { publicClient, walletClient },
  } = useAmalgema();

  const [name] = useLocalStorageState<string>("skystrife:name", {
    defaultValue: "",
  });

  const [balance, setBalance] = useState(0n);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    publicClient.getBalance({ address: walletClient.account.address }).then(setBalance);
  }, []);

  return (
    <div>
      <div className="flex justify-between items-center">
        <div>
          <Heading className="text-lg">{name ? name : formatAddress(walletClient.account.address)}</Heading>
          <Caption className="text-sm text-ss-text-x-light">Balance: {formatEther(balance)} ETH</Caption>
        </div>

        <Button buttonType={"tertiary"} onClick={() => setVisible(true)} className="h-fit">
          Settings
        </Button>
        {visible && <NameModal setVisible={setVisible} />}
      </div>
    </div>
  );
}
