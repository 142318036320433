import { Browser } from "ecs-browser";
import { useMUD } from "../../../store";
import { ClickWrapper } from "../Theme/ClickWrapper";

export const ComponentBrowser = () => {
  const layers = useMUD();

  const {
    networkLayer: {
      network: { world },
    },
    phaserLayer: {
      components: { DevHighlight },
    },
  } = layers;

  return (
    <ClickWrapper>
      <Browser
        world={world}
        layers={{
          phaser: layers.phaserLayer,
          network: layers.networkLayer.network,
          local: layers.localLayer,
          headless: layers.headlessLayer,
        }}
        devHighlightComponent={DevHighlight}
      />
    </ClickWrapper>
  );
};
