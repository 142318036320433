import { Entity, getComponentValue, getComponentValueStrict, removeComponent, setComponent } from "@latticexyz/recs";
import { PhaserLayer } from "../..";
import { UnitTypeAnimations, UnitTypeAttackAnimations, UnitTypeDeathAnimations } from "../../phaserConstants";
import { SyncStep } from "@latticexyz/store-sync/recs";

export function createCombatSystem(layer: PhaserLayer) {
  const {
    parentLayers: {
      network: {
        components: { UnitType, StructureType, SyncProgress },
        network: {
          singletonEntity,
        },
      },
      local: {
        components: { LocalHealth, LocalPosition, Capturer },
        api: {
          systemDecoders: { onCombat },
        },
      },
      headless: {
        api: {
          combat: { canRetaliate },
        },
      },
    },
    scenes: {
      Main: { objectPool },
    },
    animations: { triggerBloodSplatter },
  } = layer;

  function playAttackAnimation(
    entity: Entity,
    {
      onStart,
      onContact,
      onComplete,
    }: {
      onStart?: (sprite?: Phaser.GameObjects.Sprite) => void;
      onContact?: (sprite?: Phaser.GameObjects.Sprite) => void;
      onComplete?: (sprite?: Phaser.GameObjects.Sprite) => void;
    }
  ) {
    const unitType = getComponentValue(UnitType, entity)?.value;
    if (!unitType) {
      if (onStart) onStart();
      if (onContact) onContact();
      if (onComplete) onComplete();
      return;
    }
    const attackAnimation = UnitTypeAttackAnimations[unitType];

    const idleAnimation = UnitTypeAnimations[unitType];

    const embodiedObject = objectPool.get(entity, "Sprite");
    embodiedObject.setComponent({
      id: "attack-animation",
      now: (sprite) => {
        if (!attackAnimation) {
          if (onComplete) onComplete(sprite);
          return;
        }

        sprite.play(attackAnimation);

        let started = false;
        const onAttackUpdate = (anim: Phaser.Animations.Animation, frame: Phaser.Animations.AnimationFrame) => {
          if (anim.key !== attackAnimation) return;

          if (!started && onStart) {
            onStart(sprite);
            started = true;
          }

          if (frame.progress >= 1) sprite.play(idleAnimation);
          if (onContact && frame.index === 5) onContact(sprite);
          if (onComplete && frame.progress >= 1) {
            onComplete(sprite);
            sprite.removeListener("animationupdate", onAttackUpdate);
          }
        };

        sprite.on(`animationupdate`, onAttackUpdate);
      },
    });
  }

  function playDeathAnimation(entity: Entity, onDeath: () => void) {
    const unitType = getComponentValue(UnitType, entity)?.value;
    if (!unitType) {
      onDeath();
      return;
    }

    const deathAnimation = UnitTypeDeathAnimations[unitType];

    const embodiedObject = objectPool.get(entity, "Sprite");
    embodiedObject.setComponent({
      id: "death-animation",
      now: (sprite) => {
        sprite.play(deathAnimation);
        sprite.on(`animationcomplete-${deathAnimation}`, () => {
          onDeath();
        });
      },
    });
  }

  onCombat(
    ({
      attacker,
      attackerDied,
      attackerDamageReceived,
      defenderDamageReceived,
      attackerHealth,
      defenderHealth,
      defender,
      defenderDied,
      ranged,
      defenderCaptured,
    }) => {
      const syncStatus = getComponentValueStrict(SyncProgress, singletonEntity);
      if (syncStatus.step !== SyncStep.LIVE) return;

      const attackerPosition = getComponentValueStrict(LocalPosition, attacker);
      const defenderPosition = getComponentValueStrict(LocalPosition, defender);

      const defenderIsStructure = getComponentValue(StructureType, defender);
      const flipAttacker = defenderPosition.x < attackerPosition.x;
      const flipDefender = attackerPosition.x < defenderPosition.x;

      const attackerTookDamage = attackerDamageReceived > 0;
      const defenderTookDamage = defenderDamageReceived > 0;

      playAttackAnimation(attacker, {
        onStart: (sprite) => {
          if (sprite) sprite.flipX = flipAttacker;
        },
        onContact: () => {
          if (attackerDied) {
            setComponent(LocalHealth, attacker, { value: 0 });
          } else if (attackerTookDamage) {
            setComponent(LocalHealth, attacker, { value: attackerHealth });
          }

          if (attackerDied || attackerTookDamage) {
            triggerBloodSplatter(attackerPosition);
          }

          if (defenderDied) {
            setComponent(LocalHealth, defender, { value: 0 });
          } else if (defenderTookDamage) {
            setComponent(LocalHealth, defender, { value: defenderHealth });
          }

          if (defenderDied || defenderTookDamage) {
            if (!defenderIsStructure) {
              triggerBloodSplatter(defenderPosition);
            }
          }

          if (defenderCaptured) {
            setComponent(Capturer, defender, { value: attacker });
          }
        },
        onComplete: (sprite) => {
          if (sprite && flipAttacker) sprite.flipX = false;

          if (attackerDied) {
            playDeathAnimation(attacker, () => {
              removeComponent(LocalHealth, attacker);
              removeComponent(LocalPosition, attacker);
            });
          }

          if (defenderDied) {
            playDeathAnimation(defender, () => {
              removeComponent(LocalHealth, defender);
              removeComponent(LocalPosition, defender);
            });
          }
        },
      });
      if (!ranged && canRetaliate(layer.parentLayers.network, defender))
        playAttackAnimation(defender, {
          onStart: (sprite) => {
            if (sprite) sprite.flipX = flipDefender;
          },
          onComplete: (sprite) => {
            if (sprite && flipDefender) sprite.flipX = false;
          },
        });
    }
  );
}
