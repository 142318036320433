import React from "react";
import { useMUD } from "../../../store";
import { Button } from "../Theme/SkyStrife/Button";
import { Hex } from "viem";

type Props = {
  className?: string;
  buttonType: "primary" | "secondary" | "tertiary";
  size?: "lg" | "md";
  children: React.ReactNode;
  disabled?: boolean;
  sendTx: () => Promise<Hex>;
};

export function useSendTxButton(props: Props) {
  const {
    networkLayer: {
      network: { waitForTransaction },
    },
  } = useMUD();

  const [pending, setPending] = React.useState(false);

  const { size, buttonType, sendTx } = props;

  const onClick = async () => {
    if (pending) return;

    setPending(true);

    try {
      const tx = await sendTx();
      await waitForTransaction(tx);
    } catch (e) {
      console.error(e);
    } finally {
      setPending(false);
    }
  };

  return (
    <Button
      disabled={props.disabled || pending}
      buttonType={buttonType}
      size={size}
      className={props.className}
      onClick={onClick}
    >
      {pending ? "Pending..." : props.children}
    </Button>
  );
}
