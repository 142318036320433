import { tileCoordToPixelCoord } from "@latticexyz/phaserx";
import { Has, getComponentValueStrict, UpdateType, defineSystem } from "@latticexyz/recs";
import { Animations } from "../../phaserConstants";
import { PhaserLayer, RenderDepth } from "../../types";

export function createDrawHighlightCoordSystem(layer: PhaserLayer) {
  const {
    world,
    parentLayers: {
      local: { singletonEntity },
    },
    scenes: {
      Main: {
        objectPool,
        maps: {
          Main: { tileWidth, tileHeight },
        },
      },
    },
    components: { HoverHighlight },
  } = layer;

  defineSystem(world, [Has(HoverHighlight)], ({ entity, type }) => {
    if (type === UpdateType.Exit) {
      objectPool.remove(`${entity}-hover-highlight`);
      return;
    }

    const hoverHighlght = getComponentValueStrict(HoverHighlight, singletonEntity);
    const highlight = objectPool.get(`${entity}-hover-highlight`, "Sprite");
    if (hoverHighlght.x === undefined || hoverHighlght.y === undefined) return;
    const position = { x: hoverHighlght.x, y: hoverHighlght.y };

    highlight.setComponent({
      id: `highlight`,
      once: (box) => {
        const pixelCoord = tileCoordToPixelCoord(position, tileWidth, tileHeight);

        box.play(Animations.TileSelect);
        box.setPosition(pixelCoord.x, pixelCoord.y);
        box.setDepth(RenderDepth.Background2);
      },
    });
  });
}
