import { useState } from "react";
import { useEntityQuery } from "@latticexyz/react";
import { getComponentValue, Has, hasComponent, HasValue, Not } from "@latticexyz/recs";
import { useMUD } from "../../../store";
import { useCurrentPlayer } from "../hooks/useCurrentPlayer";
import { Button } from "../Theme/SkyStrife/Button";
import { getBurnerWallet } from "../../../getBurnerWallet";
import { Card } from "../Theme/SkyStrife/Card";
import { OverlineSmall } from "../Theme/SkyStrife/Typography";
import useLocalStorageState from "use-local-storage-state";
import { Hex, encodeAbiParameters } from "viem";
import { privateKeyToAccount } from "viem/accounts";

export const JoinGame = () => {
  const {
    headlessLayer: {
      actions: { Action },
    },
    networkLayer: {
      network: { worldContract, match,
        waitForTransaction,
        components: { Player, Name, SpawnPoint, Position, SpawnReservedBy, Match },
      },
    },
    localLayer: {
      components: { Spectator },
      singletonEntity,
    },
  } = useMUD();

  const [playerName, setPlayerName] = useLocalStorageState<string>("skystrife:name", {
    defaultValue: "",
  });
  const [spectate] = useState(hasComponent(Spectator, singletonEntity));
  const [pendingTx, setPendingTx] = useState(false);

  const currentPlayer = useCurrentPlayer();

  const allPlayersInMatch = useEntityQuery([Has(Player), HasValue(Match, { value: match })]);
  const spawnAction = useEntityQuery([Has(Action)]).find((i) => i.includes("spawn"));

  const otherPlayerNames = allPlayersInMatch.map((player) => getComponentValue(Name, player)?.value);
  const nameTaken = otherPlayerNames.includes(playerName);
  const registerDisabled = playerName.length === 0 || playerName.length > 32 || nameTaken;

  const availableSpawns = useEntityQuery([
    Has(SpawnPoint),
    Has(Position),
    Not(SpawnReservedBy),
    HasValue(Match, { value: match }),
  ]);

  const visible =
    !spectate &&
    !(currentPlayer.player && allPlayersInMatch.includes(currentPlayer.player)) &&
    availableSpawns.length > 0;

  const register = async () => {
    if (registerDisabled) return;

    // Used to later notify player of match start
    Notification.requestPermission();

    // Fetch the "main" wallet at -1
    const privateKey = getBurnerWallet(-1);
    const { address } = privateKeyToAccount(privateKey as Hex);

    const txPromise = worldContract.write.register([
      availableSpawns[0] as Hex,
      match,
      playerName,
      encodeAbiParameters([{ type: "address" }], [address]),
    ]);
    setPendingTx(true);

    try {
      const tx = await txPromise;
      await waitForTransaction(tx);
    } catch (e) {
      console.error(e);
    } finally {
      setPendingTx(false);
    }
  };

  let disabledMessage = "";
  if (playerName.length === 0) disabledMessage = "Register";
  if (playerName.length > 32) disabledMessage = "Name too long";
  if (nameTaken) disabledMessage = "Name taken";

  let joinButtonText = "Join";
  if (registerDisabled) joinButtonText = disabledMessage;
  if (pendingTx) joinButtonText = "Joining...";

  return (
    <>
      {visible && (
        <div className="">
          <div className="h-fit">
            <Card primary className="w-[500px] p-8 pt-4">
              <OverlineSmall>register</OverlineSmall>

              <div className="h-3"></div>

              <div className="flex w-full justify-between">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    register();
                  }}
                  className="grow mr-3  my-0"
                >
                  <input
                    type={"text"}
                    className="bg-ss-bg-0 rounded border border-ss-stroke w-full px-3 py-2 shadow-ss-small"
                    placeholder="Enter a name"
                    value={playerName}
                    onChange={(e) => {
                      setPlayerName(e.target.value);
                      return false;
                    }}
                  />
                </form>

                <div className="flex flex-col items-center">
                  {!spawnAction ? (
                    <Button
                      disabled={registerDisabled || pendingTx}
                      buttonType="secondary"
                      className="w-full"
                      onClick={() => register()}
                    >
                      {joinButtonText}
                    </Button>
                  ) : (
                    <Button disabled buttonType="secondary" className="text-2xl">
                      Joining...
                    </Button>
                  )}
                </div>
              </div>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};
