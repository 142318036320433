import { useEffect, useMemo } from "react";
import { usePromiseValue } from "../usePromiseValue";
import { NetworkLayer } from "../layers/Network";
import { createAnalyticsLayer } from "../layers/Analytics";

export const useAnalyticsLayer = (networkLayer: NetworkLayer | null) => {
  const analyticsLayerPromise = useMemo(() => {
    if (!networkLayer) return null;

    return createAnalyticsLayer(networkLayer);
  }, [networkLayer]);

  useEffect(() => {
    return () => {
      analyticsLayerPromise?.then((analyticsLayer) => analyticsLayer.world.dispose());
    };
  }, [analyticsLayerPromise]);

  return usePromiseValue(analyticsLayerPromise);
};
