import { Has, HasValue } from "@latticexyz/recs";
import { useAmalgema } from "../../store";
import { Button } from "../ui/Theme/SkyStrife/Button";
import { Card } from "../ui/Theme/SkyStrife/Card";
import { Heading, Caption } from "../ui/Theme/SkyStrife/Typography";
import { useEntityQuery } from "@latticexyz/react";

export function LiveMatch({ matchId }: { matchId: number }) {
  const {
    components: { Match, Player },
  } = useAmalgema();

  const playersInMatch = useEntityQuery([HasValue(Match, { value: matchId }), Has(Player)]);

  return (
    <Card primary={false}>
      <div className="flex flex-row justify-between items-center">
        <Heading>Deathmatch ({matchId})</Heading>
        <Caption>{playersInMatch.length} players</Caption>
      </div>

      <div className="mb-4"></div>

      <a href={`/match?match=${matchId}&spectate=true`} target="_blank">
        <Button buttonType={"tertiary"} className="w-full">
          Spectate
        </Button>
      </a>
    </Card>
  );
}
