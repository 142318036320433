import { tileCoordToPixelCoord } from "@latticexyz/phaserx";
import { defineSystem, getComponentValueStrict, Has, Not, UpdateType } from "@latticexyz/recs";
import { Animations } from "../../phaserConstants";
import { PhaserLayer, RenderDepth } from "../../types";

export function createSelectionSystem(layer: PhaserLayer) {
  const {
    world,
    scenes: {
      Main: { input, maps, objectPool },
    },
    parentLayers: {
      local: {
        components: { LocalPosition, Path, Selected },
        api: { resetSelection },
      },
    },
    api: { tintObject },
  } = layer;

  // Reset selected area on ESC click
  input.onKeyPress(
    (keys) => keys.has("ESC"),
    () => {
      resetSelection();
    }
  );

  defineSystem(world, [Has(Selected), Has(LocalPosition), Not(Path)], ({ entity, type }) => {
    if (type === UpdateType.Exit) {
      return objectPool.remove(`${entity}-select`);
    }

    const selection = objectPool.get(`${entity}-select`, "Sprite");
    const position = getComponentValueStrict(LocalPosition, entity);

    selection.setComponent({
      id: `selection`,
      once: async (box) => {
        const pixelCoord = tileCoordToPixelCoord(position, maps.Main.tileWidth, maps.Main.tileHeight);

        box.play(Animations.TileHighlight);
        box.setSize(maps.Main.tileWidth, maps.Main.tileHeight);
        box.setPosition(pixelCoord.x, pixelCoord.y);
        box.setDepth(RenderDepth.Background1);
        box.setOrigin(0);
        tintObject(box, 0xfff973);
      },
    });
  });
}
